export const ductDimensions = [0, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30];

export const ductDepths = [1, 1.75, 2, 3, 4.375, 5, 5.5];

export const ratings = [
    "",
    "merv 6",
    "merv 8",
    "merv 10",
    "merv 11",
    "merv 12",
    "merv 13",
    "merv 15",
    "merv 16",
    "mpr 300",
    "mpr 600",
    "mpr 1000",
    "mpr 1200",
    "mpr 1500",
    "mpr 1900",
    "mpr 2200",
    "mpr 2800",
];

export const types = [
    "",
    "disposable fibreglass",
    "disposable pleated ",
    "disposable electrostatic ",
    "permanent electrostatic ",
    "electronic filter",
    "hepa",
];
