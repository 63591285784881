import { HStack, RangeSlider, RangeSliderFilledTrack, RangeSliderThumb, RangeSliderTrack, Text, Tooltip, VStack } from "@chakra-ui/react"
import Emphasis from "components/emphasis"
import Muted from "components/muted"
import { displayUnit, parseMeasurement, useDisabled } from "pages/util"
import { useState } from "react"
import { AutomationRangeInput, Measurement, SaveAutomation } from "types"
import { useSkipFirstRender } from "./dwelling-automations"
import EmptyState from "./empty-state"
import useIsDisabled from "./useIsDisabled"

const min = (measurement: Measurement, unit?: 'celsius' | 'fahrenheit') => {
    switch (measurement) {
        case 'humidity': return 0
        case 'dew_point': return unit === 'celsius' ? 0 : 32
    }
}

const max = (measurement: Measurement, unit?: 'celsius' | 'fahrenheit') => {
    switch (measurement) {
        case 'humidity': return 100
        case 'dew_point': return unit === 'celsius' ? 25 : 72
    }
}

// input control
// get ride of override
const RangeInput = ({ input, automationId, loading, set }: {
    input?: AutomationRangeInput,
    automationId: number
    loading: boolean,
    set: any
    // save: SaveAutomation,
}) => {
    const startPoint = input?.low_end_start_set_point || 52
    const endPoint = input?.high_end_start_set_point || 52
    const [sliderValue, setSliderValue] = useState([startPoint, endPoint])
    const [showTooltip, setShowTooltip] = useState(false)
    const disabled = useIsDisabled(automationId)
    const disabledColor = useDisabled()

    const setter = (inputs) => {
        if (!input) return inputs
        return inputs.map(_input => {
            if (input.id === _input.id) {

                return {
                    ..._input,
                    low_end_start_set_point: sliderValue[0],
                    high_end_start_set_point: sliderValue[1]

                }
            }
            return _input
        })
    }

    // const saveStartPoint = async (start_set_point: number) => {
    //     if (!input) return
    //     try {
    //         await save(prev => ({
    //             inputs: prev.inputs.map(_input => {
    //                 if (input.id === _input.id) {
    //                     return {
    //                         ..._input,
    //                         start_set_point,
    //                     }
    //                 }
    //                 return _input
    //             })
    //         }))
    //     } catch (e) {
    //         console.error(e)
    //     }
    // }

    // const debounceSave = useRef(debounce(saveStartPoint, 1000)).current

    useSkipFirstRender(() => {
        if ((sliderValue[0] !== 0 || sliderValue[1] !== 0) && !sliderValue) return // slider is falsey but not zero. zero could be valid
        if (sliderValue[0] === input?.low_end_start_set_point && sliderValue[1] === input?.high_end_start_set_point) return // there has been no change
        set(setter)
        // debounceSave(sliderValue)
    }, [sliderValue])

    if (!input) return (
        <EmptyState title='Humidity' />
    )

    const measurement = parseMeasurement(input.measurement)


    return (
        <VStack
            spacing={8}
            alignItems='flex-start'
            w='100%'>
            <Muted>Activate equipment when <Emphasis>{measurement}</Emphasis> is <Emphasis>below {sliderValue[0]}{displayUnit(input.measurement, input?.set_point_unit)}</Emphasis> or <Emphasis>above {sliderValue[1]}{displayUnit(input.measurement, input?.set_point_unit)}</Emphasis></Muted>
            <HStack
                w='100%'
                justifyContent='space-between'
                alignItems='center'>
                <Text
                    {...(disabled ? { color: disabledColor } : {})}
                    textAlign='right'
                    width='64px'>{sliderValue[0]}{displayUnit(input.measurement, input?.set_point_unit)}</Text>
                <RangeSlider
                    isDisabled={loading || disabled}
                    defaultValue={[startPoint, endPoint]}
                    min={min(input.measurement, input?.set_point_unit)}
                    max={max(input.measurement, input?.set_point_unit)}
                    onChange={setSliderValue}
                    onMouseEnter={() => setShowTooltip(true)}
                    onMouseLeave={() => setShowTooltip(false)}

                >

                    <RangeSliderTrack >
                        <RangeSliderFilledTrack />
                    </RangeSliderTrack>
                    <Tooltip
                        hasArrow
                        bg='teal.500'
                        color='white'
                        placement='top'
                        isOpen={showTooltip}
                        label={`${sliderValue[0]}${displayUnit(input.measurement, input?.set_point_unit)}`}
                    ><RangeSliderThumb index={0} /></Tooltip>
                    <Tooltip
                        hasArrow
                        bg='teal.500'
                        color='white'
                        placement='top'
                        isOpen={showTooltip}
                        label={`${sliderValue[1]}${displayUnit(input.measurement, input?.set_point_unit)}`}
                    ><RangeSliderThumb index={1} /></Tooltip>
                </RangeSlider>
                <Text
                    {...(disabled ? { color: disabledColor } : {})}
                    textAlign='right'
                    width='64px'>{sliderValue[1]}{displayUnit(input.measurement, input?.set_point_unit)}</Text>
            </HStack>
        </VStack>
    )
}

export default RangeInput
