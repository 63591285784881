import "ag-grid-community/dist/styles/ag-grid.css";

import { Table, TableCaption, TableContainer, Tbody, Td, Thead, Tr, useColorMode } from "@chakra-ui/react";

import { fieldToLabel } from "utils/text-transform";

/**
 * This element renders a list of entities in a form of a table. Used inside details to render things like list of associated devices, filters, equipment, etc.
 */
export default function EntitiesList({ list, columns, openUrl = '' }) {

    // Open details in a new tab
    function onClick(id) {
        if (!openUrl) return;
        window.open(`/fleet/${openUrl}/${id}`, "_blank")?.focus();
    }

    const renderElement = element => {
        if (openUrl) return (
            <Tr cursor="pointer" key={element.id} onClick={() => onClick(element.id)}>
                {columns.map(column => (
                    <Td key={column}>{element[column]}</Td>
                ))}
            </Tr>
        )
        return (
            <Tr key={element.id}>
                {columns.map(column => (
                    <Td key={column}>{element[column]}</Td>
                ))}
            </Tr>
        )
    }

    return (
        <TableContainer maxH="30rem" overflowY="auto">
            <Table variant="simple">
                {list.length === 0 && <TableCaption>No data</TableCaption>}
                <Thead>
                    <Tr>
                        {columns.map(column => (
                            <Td key={column} fontWeight={600}>
                                {fieldToLabel(column)}
                            </Td>
                        ))}
                    </Tr>
                </Thead>
                <Tbody>
                    {list.map(renderElement)}
                </Tbody>
            </Table>
        </TableContainer>
    );
}
