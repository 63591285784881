import { DateTimeInput, SelectInput, StringInput } from "components/inputs";
import { FormGroup, UpdateAction } from "components/form";
import React, { useState } from "react";
import { ductDepths, ductDimensions, ratings, types } from "utils/duct-options";

import { useForm } from "react-hook-form";
import { usePost } from "api/api-hooks";

export function FilterDetails(props) {
    const [details, setDetails] = useState(props.details);
    const { call } = usePost(`filter/${details.id}`);

    const form = useForm({
        defaultValues: { ...details, install_timestamp: new Date(details.install_timestamp) },
    });
    const { handleSubmit, reset } = form;

    function handleReset() {
        reset({ ...details });
    }

    function onSubmit(data) {
        data.height = +data.height;
        data.width = +data.width;
        data.depth = +data.depth;
        call(data, () => {
            setDetails(data);
        });
    }

    return (
        <>
            <FormGroup>
                <StringInput form={form} field="id" disabled={true}></StringInput>
                <DateTimeInput form={form} field="install_timestamp" required={true}></DateTimeInput>
                <SelectInput form={form} field="width" options={ductDimensions}></SelectInput>
                <SelectInput form={form} field="height" options={ductDimensions}></SelectInput>
                <SelectInput form={form} field="depth" options={ductDepths}></SelectInput>
                <SelectInput form={form} field="rating" options={ratings}></SelectInput>
                <SelectInput form={form} field="type" options={types}></SelectInput>
            </FormGroup>

            <UpdateAction onReset={handleReset} onUpdate={handleSubmit(onSubmit)}></UpdateAction>
        </>
    );
}
