import { HStack } from "@chakra-ui/react";
import { HavenGreenButton } from "components/buttons";
import { SimulateTelemetry } from "./device-simulation";
import { usePost } from "api/api-hooks";

export function DeviceEmail(props) {
    const { call } = usePost(`email/homeowner_digest`);

    function onClick() {
        const data = {
            device_id: props.details.id,
        };
        call(data, () => { });
    }

    return (
        <HStack>
            <SimulateTelemetry deviceId={props.details.id}></SimulateTelemetry>
            <HavenGreenButton label={"Send email"} onClick={onClick}></HavenGreenButton>
        </HStack>
    );
}
