import { Grid, useMediaQuery } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import AssociatedEntity from "../../../components/associated-entity";
import Card from "../../../components/card";
import { DwellingSearch } from "components/entity-search";
import EntitiesTable from "../../../components/entities-list";
import { EquipmentDetails } from "./equipment-details";
import { getDwelling } from "../../../api/api";

export default function Equipment({ equipment }) {
    const { details, dwelling, filters } = equipment;

    const [isLargeScreen] = useMediaQuery("(min-width: 1480px)");
    const [isSmallScreen] = useMediaQuery("(max-width: 850px)");
    const [columnCount, setColumnCount] = useState(3);

    useEffect(() => {
        if (isSmallScreen) {
            setColumnCount(1);
        } else if (isLargeScreen) {
            setColumnCount(3);
        } else {
            setColumnCount(2);
        }
    }, [columnCount, isLargeScreen, isSmallScreen]);

    return (
        <Grid templateColumns={`repeat(${columnCount}, 1fr)`} gap={4} width="100%">
            <DetailsCard details={details} />
            <DwellingCard dwelling={dwelling} details={details} />
            <FiltersCard filters={filters} />
        </Grid>
    );
}

function DetailsCard({ details }) {
    return (
        <Card header="Details" error={details.error} loading={details.loading}>
            {details.data && <EquipmentDetails details={details.data}></EquipmentDetails>}
        </Card>
    );
}
function DwellingCard({ dwelling, details }) {
    return (
        <Card
            header="Dwelling"
            error={dwelling.error}
            loading={dwelling.loading}
            gotoUrl={`/fleet/dwellings/${dwelling?.data?.id}`}
            iconUrl="/assets/dwelling.png"
        >
            {dwelling.data && details.data && (
                <AssociatedEntity
                    entity={dwelling.data}
                    updateUrl={`equipment/${details?.data?.id}`}
                    entityName={"Dwelling"}
                    getEntityCall={getDwelling}
                    fieldName={"dwelling_id"}
                >
                    <DwellingSearch></DwellingSearch>
                </AssociatedEntity>
            )}
        </Card>
    );
}

function FiltersCard({ filters }) {
    return (
        <Card header="Filters" error={filters.error} loading={filters.loading}>
            {filters.data && <EntitiesTable list={filters.data} openUrl="filters" columns={["id", "type", "rating"]}></EntitiesTable>}
        </Card>
    );
}
