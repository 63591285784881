import TextLink from "components/text-link"
import { overrideKeys } from "pages/util"
import { useEffect, useRef, useState } from "react"
import { Automation } from "types"
import OverrideModal from "./override-modal"
import useAutomationsStore from "./automationsStore"
import differenceInHours from "date-fns/differenceInHours"
import differenceInMinutes from "date-fns/differenceInMinutes"
import differenceInSeconds from "date-fns/differenceInSeconds"
import differenceInDays from "date-fns/differenceInDays"
import { HStack } from "@chakra-ui/react"
import Disabled from "components/disabled"
import useIsDisabled from "./useIsDisabled"

const select = id => state => state[id] as Automation

const aboveZero = x => Math.round(Math.max(0, x))

const format = (end, start) => {
    const days = differenceInDays(end, start);
    const hours = aboveZero(differenceInHours(end, start) % 24);
    const minutes = aboveZero(differenceInMinutes(end, start) % 60);
    const seconds = aboveZero(differenceInSeconds(end, start) % 60);

    return `${days ? `${days}d ` : ''}${hours ? `${hours}h ` : ''}${minutes ? `${minutes}m ` : ''}${seconds ? `${seconds}s ` : ''}`
}

const useTime = (time, cancel) => {
    const [duration, setDuration] = useState<string | null>(null)

    useEffect(() => {
        if (!time) return

        const interval = setInterval(() => {
            if (time - Date.now() <= 0) {
                clearInterval(interval)
                cancel()
                return setDuration(null)
            }
            setDuration(format(time, Date.now()))
        }, 1000)

        return () => clearInterval(interval)
    }, [time])

    return duration
}

const Override = ({
    automationId,
    loading,
    save,
    pillar,
    refetch,
}) => {
    const automation = useAutomationsStore(select(automationId))
    const [isOpen, setIsOpen] = useState(false)
    const { stateKey, timeoutKey } = overrideKeys(pillar)
    const disabled = useIsDisabled(automationId)
    const flagRefetch = useRef(false)

    const cancel = async () => {
        try {
            await save(prev => ({
                [timeoutKey]: null,
            }))
        } catch (e) {
            console.error(e)
        }
    }

    const _save = async (data) => {
        try {
            await save(prev => ({
                ...data,
            }))
            flagRefetch.current = true
        } catch (e) {
            console.error(e)
        }
    }

    useEffect(() => {
        if (!flagRefetch.current) return

        const cb = () => {
            flagRefetch.current = false
            refetch()
        }

        const to = setTimeout(cb, 3000)

        return () => clearTimeout(to)
    }, [flagRefetch.current])

    const remaining = useTime(new Date(automation[timeoutKey]).getTime(), cancel)
    const on = Boolean(automation[timeoutKey])

    if (on) return (
        <HStack>
            <Disabled lineHeight='normal'>{remaining}</Disabled>
            <TextLink
                colorScheme='red'
                isDisabled={loading || disabled}
                onClick={cancel}
            >Cancel</TextLink>
        </HStack>
    )

    return (
        <>
            <TextLink
                isDisabled={loading || disabled}
                onClick={() => setIsOpen(true)}>Override</TextLink>
            <OverrideModal
                title={`Override ${pillar}`}
                state={automation[stateKey]}
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                timeoutKey={timeoutKey}
                stateKey={stateKey}
                save={_save}
            />
        </>
    )
}

export default Override
