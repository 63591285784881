import { HStack, Text, VStack } from "@chakra-ui/react";

import AssociatedEntity from "components/associated-entity";
import Card from "../../../components/card";
import EntitiesList from "components/entities-list";
import { ControllerHierarchy } from "./controller-hierarchy";
import { ControllerTelemetry } from "./controller-telemetry";
import { DeviceCalibrationReport } from "./device-calibration-report";
import { DeviceDetails } from "./device-details";
import { DeviceTwin } from "./device-twin";
import { GrafanaLinkController } from "components/grafana-links";
import { getDevice } from "../../../api/api";

export default function Controller({ controller }) {
    const { details, dwelling, twin, channels, monitor, hardwareState, currentTelemetry } = controller;
    return (
        <VStack flex={1} align="stretch" spacing={10}>
            <HStack align="flex-start" spacing={10} w={"100%"}>
                <DetailsCard details={details} />
                <VStack flex={1} align="stretch" spacing={10}>
                    <TelemetryCard currentTelemetry={currentTelemetry} monitor={monitor} details={details} />
                    <TechnicalDetailsCard twin={twin} controllerId={details.data.id} hardwareState={hardwareState} />
                </VStack>
            </HStack>
        </VStack>
    );
}

function DetailsCard({ details }) {
    return (
        <Card flex={1} header="Details" error={details.error} loading={details.loading}>
            {details.data && <DeviceDetails details={details.data}></DeviceDetails>}
        </Card>
    );
}



function TelemetryCard({ currentTelemetry, monitor, details }) {
    return (
        <Card
            flex={1}
            header="Telemetry"
            error={monitor.error}
            loading={monitor.loading || currentTelemetry.loading}
            customLink={<GrafanaLinkController pcb={details?.data?.pcb_serial_number} />}
        >
            {monitor.data && <ControllerTelemetry currentTelemetry={currentTelemetry?.data}></ControllerTelemetry>}
            {!monitor.data && <Text>Telemetry not available, pairing to monitor not found</Text>}
        </Card>
    );
}

function TechnicalDetailsCard({ twin, controllerId, hardwareState }) {
    return (
        <Card flex={1} header="Technical details" error={twin.error} loading={twin.loading}>
            {twin.data && <DeviceTwin id={controllerId} twin={twin.data} hardwareState={hardwareState?.data}></DeviceTwin>}
        </Card>
    );
}

