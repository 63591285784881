import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import "ag-grid-community/dist/styles/ag-theme-balham-dark.css";

import { CompanyPage, DevicePage, DwellingPage, EquipmentPage, FilterPage, UserPage } from "./entity-page";
import { Box, HStack, Link, Stack, Heading, Card, CardHeader, CardBody, Grid, GridItem } from "@chakra-ui/react";
import { NavLink, Navigate, Route, Routes } from "react-router-dom";

import { CreateCompany } from "components/create/create-company";
import { CreateDwelling } from "components/create/create-dwelling";
import { CreateEquipment } from "components/create/create-equipment";
import EntitiesTable from "components/entities-table";

// import "ag-grid-enterprise";

/**
 * Fleet view contains lists of entities in the fleet as well as routing definitions to get to the individual details.
 */
export default function Fleet() {
    return (
        <HStack
            alignItems='flex-start'
            height='100%'
        >
            <Stack direction="column" spacing={4} m={4}>
                <Link as={NavLink} _activeLink={{ fontWeight: "bold" }} to="devices">
                    Devices
                </Link>
                <Link as={NavLink} _activeLink={{ fontWeight: "bold" }} to="users">
                    Users
                </Link>
                <Link as={NavLink} _activeLink={{ fontWeight: "bold" }} to="dwellings">
                    Dwellings
                </Link>
                <Link as={NavLink} _activeLink={{ fontWeight: "bold" }} to="companies">
                    Companies
                </Link>
                <Link as={NavLink} _activeLink={{ fontWeight: "bold" }} to="equipments">
                    Equipment
                </Link>
            </Stack>
            <Box
                flex={1}
                height='100%'
            >
                <Routes>
                    {/* DETAILS */}
                    <Route path="devices/:id" element={<DevicePage />}></Route>
                    <Route path="users/:id" element={<UserPage />}></Route>
                    <Route path="dwellings/:id" element={<DwellingPage />}></Route>
                    <Route path="companies/:id" element={<CompanyPage />}></Route>
                    <Route path="equipments/:id" element={<EquipmentPage />}></Route>
                    <Route path="filters/:id" element={<FilterPage />}></Route>

                    {/* LISTS */}
                    <Route path="devices" element={<Devices />}></Route>
                    <Route path="users" element={<Users></Users>}></Route>
                    <Route path="dwellings" element={<Dwellings></Dwellings>}></Route>
                    <Route path="companies" element={<Companies></Companies>}></Route>
                    <Route path="equipments" element={<Equipments></Equipments>}></Route>
                    <Route path="*" element={<Navigate to="/fleet/devices" />}></Route>
                </Routes>
            </Box>
        </HStack>
    );
}

function Users() {




    return (<>
        <Grid templateColumns='repeat(1,1fr)' templateRows='repeat(1,1fr)' gap={4} height='100%' width="100%">
            <Card style={{ width: '100%' }}>
                <CardHeader>
                    <Heading size="sm" >
                        <img src='assets/list.png' style={{ height: 20, width: 20, float: 'left' }} />
                        <span>HAVEN Users</span>
                    </Heading>
                </CardHeader>
                <CardBody >
                    <EntitiesTable url={"users"} getUrl={"users"} columns={["email", "first_name", "last_name"]}></EntitiesTable>
                </CardBody>
            </Card>
        </Grid>
    </>
    );


}

function Devices() {
    return (<>
        <Grid templateColumns='repeat(1,1fr)' templateRows='repeat(1,1fr)' gap={4} height='100%' width="100%">
            <Card style={{ width: '100%' }}>
                <CardHeader>
                    <Heading size="sm" >
                        <img src='assets/list.png' style={{ height: 20, width: 20, float: 'left' }} />
                        <span>HAVEN Devices</span>
                    </Heading>
                </CardHeader>
                <CardBody >
                    <EntitiesTable
                        url={"devices"}
                        getUrl={"devices"}
                        columns={["name", "pcb_serial_number", "plastic_serial_number", "install_timestamp", "telemetry_received"]}
                    />
                </CardBody>
            </Card>
            {/*  <Card style={{ width: '100%' }}>
                <CardHeader>
                    <Heading size="sm" >
                        <img src='assets/list.png' style={{ height: 24, width: 24, float: "left" }} />
                        <span>Ecosense Devices</span>
                    </Heading>
                </CardHeader>
                <CardBody >
                    <EntitiesTable
                        url={"ecosense/devices"}
                        getUrl={"ecosense/devices"}
                        columns={["name", "serial_number", "dwelling_id", "zone"]}
                    />
                </CardBody>
    </Card>*/}
        </Grid>

    </>
    );
}

function Dwellings() {
    return (
        <>
            <Grid templateColumns='repeat(1,1fr)' templateRows='repeat(1,1fr)' gap={4} height='100%' width="100%">
                <Card style={{ width: '100%' }}>
                    <CardHeader>
                        <Heading size="sm" >
                            <img src='assets/list.png' style={{ height: 20, width: 20, float: 'left' }} />
                            <span>HAVEN Dwellings</span>
                        </Heading>
                    </CardHeader>
                    <CardBody >
                        <EntitiesTable url={"dwellings"} getUrl={"dwellings"} columns={["name", "address"]}></EntitiesTable>
                    </CardBody>
                </Card>
            </Grid>
        </>

    );
}

function Companies() {
    return (
        <>
            <Grid templateColumns='repeat(1,1fr)' templateRows='repeat(1,1fr)' gap={4} height='100%' width="100%">
                <Card style={{ width: '100%' }}>
                    <CardHeader>
                        <Heading size="sm" >
                            <img src='assets/list.png' style={{ height: 20, width: 20, float: 'left' }} />
                            <span>HAVEN Companies</span>
                        </Heading>
                    </CardHeader>
                    <CardBody >
                        <EntitiesTable url={"companies"} getUrl={"service_companies"} columns={["name", "create_timestamp", "address"]}></EntitiesTable>
                    </CardBody>
                </Card>
            </Grid>

        </>
    );
}

function Equipments() {

    return (
        <>
            <Grid templateColumns='repeat(1,1fr)' templateRows='repeat(1,1fr)' gap={4} height='100%' width="100%">
                <Card style={{ width: '100%' }}>
                    <CardHeader>
                        <Heading size="sm" >
                            <img src='assets/list.png' style={{ height: 20, width: 20, float: 'left' }} />
                            <span>HAVEN Equipment</span>
                        </Heading>
                    </CardHeader>
                    <CardBody >
                        <EntitiesTable
                            url={"equipments"}
                            getUrl={"equipments"}
                            columns={["model", "brand", "type", "install_timestamp"]}
                        ></EntitiesTable>
                    </CardBody>
                </Card>
            </Grid>

        </>
    );
}
