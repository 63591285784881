import { useColorModeValue } from "@chakra-ui/react";
import { Measurement } from "types";
import { capitalize } from "utils/text-transform";

function debounce(inner, ms = 0) {
    let timer
    let resolves = []

    return function (...args) {
        // Run the function after a certain amount of time
        clearTimeout(timer);
        timer = setTimeout(() => {
            // Get the result of the inner function, then apply it to the resolve function of
            // each promise that has been created since the last time the inner function was run
            let result = inner(...args);
            // @ts-ignore
            resolves.forEach(r => r(result));
            resolves = [];
        }, ms);

        // @ts-ignore
        return new Promise(r => resolves.push(r));
    };
}

const parseMeasurement = (measurement: Measurement) => {
    switch (measurement) {
        case 'pm25': return 'PM2.5'
        case 'voc': return 'VOC'
        case 'dew_point': return 'dew point'
        case 'epa_aqi': return 'EPA AQI'
        default: return measurement
    }
}

const useDisabled = () => {
    return useColorModeValue('gray.400', 'gray.600')
}

const useMuted = () => {
    return useColorModeValue('gray.500', 'gray.400')
}

const overrideKeys = (pillar) => ({
    stateKey: `${pillar}_override_on`,
    timeoutKey: `${pillar}_override_timeout`,
})

const humanize = x => x ? capitalize(x.split('_').join(' ')) : ''

const displayUnit = (measurement_type: Measurement, unit?: 'celsius' | 'fahrenheit') => {
    switch (measurement_type) {

        case 'pm25':
            return 'µg/m³'
        case 'voc':
            return 'ppb'
        case 'temperature': {
            if (unit === 'celsius') return '°C'
            if (unit === 'fahrenheit') return '°F'
        } break;

        case 'humidity':
            return '%RH'

        case 'dew_point': {
            if (unit === 'celsius') return '°C'
            if (unit === 'fahrenheit') return '°F'
        } break;
        case 'epa_aqi':
            return ''

    }
}


export {
    displayUnit,
    useDisabled,
    useMuted,
    debounce,
    parseMeasurement,
    overrideKeys,
    humanize,
}
