import { AutomationInput, AutomationRangeInput, AutomationSchedule } from "types"
import Humidity from "./humidity"
import Temperature from "./temperature"
import OutdoorAir from "./outdoor-air"
import RangeInput from "./range-input"
import { GenericSetter, InputSetter, ScheduleSetter } from "./rule"
// import Schedule from "./schedule"
import Ventilation from "./ventilation"

const InputControl = ({ input, schedule, loading, automationId, set }: {
    input?: AutomationInput | AutomationRangeInput
    schedule?: AutomationSchedule
    automationId: number
    loading: boolean
    set: GenericSetter
}) => {

    if (input) {

        if (input.type === 'cam_range') {
            return (
                <RangeInput
                    automationId={automationId}
                    input={input}
                    loading={loading}
                    set={set} />
            )
        }

        switch (input.measurement) {
            case 'pm25':
            case 'voc': {
                return (
                    <Ventilation
                        automationId={automationId}
                        input={input}
                        loading={loading}
                        set={set}
                    />
                )
            }
            case 'humidity':
            case 'dew_point': {
                return (
                    <Humidity
                        automationId={automationId}
                        input={input}
                        loading={loading}
                        set={set}
                    />
                )
            }
            case 'temperature': {
                return (
                    <Temperature
                        automationId={automationId}
                        input={input}
                        loading={loading}
                        set={set} />
                )
            }
            case 'epa_aqi': {
                return (
                    <OutdoorAir
                        automationId={automationId}
                        input={input}
                        loading={loading}
                        set={set}
                    />
                )
            }
            default: return null
        }
    }
    // if (schedule) {
    //     return (
    //         <Schedule
    //             automationId={automationId}
    //             schedule={schedule}
    //             loading={loading}
    //             set={set}
    //         />
    //     )
    // }
    return null
}

export default InputControl
