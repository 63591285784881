import {
    Alert,
    AlertIcon,
    Box,
    Button,
    FormLabel,
    HStack,
    Input,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Select,
    Stack,
    Switch,
    Text,
} from "@chakra-ui/react"
import { usePost } from "api/api-hooks"
import { log } from "console"
import { hu } from "date-fns/locale"
import { useState } from "react"
import { useForm } from "react-hook-form"
import { capitalize } from "utils/text-transform"

const Field = props => <Stack spacing={1} w='100%' {...props} />
const sanitize = (str: string) => str ?
    str.toLowerCase().replace('-', '').replace(' ', '') : ''

function SimulateData({ postalCode }: { postalCode: string }) {
    const [loading, setLoading] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [humidity, setHumidity] = useState(55)
    const [temperature, setTemperature] = useState(25)
    const [dewPoint, setDewPoint] = useState(15.33)

    const { register, handleSubmit, formState: { errors } } = useForm({ mode: 'onChange' })

    const { call } = usePost(postalCode && `/outdoor_air/${sanitize(postalCode)}/simulation`)
    const hasError = Boolean(Object.keys(errors).length)

    const onSubmit = async (data) => {
        if (hasError) return
        setLoading(true)
        if (data.local_time) {
            //convert to UTC
            data.start_time = new Date(data.start_time).toISOString().slice(0, -8);
            data.stop_time = new Date(data.stop_time).toISOString().slice(0, -8);

        }
        delete data.local_time
        try {
            await call(data)
            setIsOpen(false)
        } catch (e) {
            console.error(e)
        }

        setLoading(false)
    }

    function updateDewPoint(temp, rh) {
        if (rh < 1) rh = 1
        if (rh > 100) rh = 100
        const beta = 17.62
        const lamb = 243.12
        const log_humidity = Math.log(rh / 100)
        const inner_term = log_humidity + ((beta * temp) / (lamb + temp))
        const dew_point = (lamb * inner_term) / (beta - inner_term)
        setDewPoint(Math.round(dew_point * 100) / 100)
        setTemperature(temp)
        setHumidity(rh)
    }


    function renderContent() {
        return (
            <form onSubmit={handleSubmit(onSubmit)}>
                <ModalHeader>
                    Simulate outdoor weather data
                </ModalHeader>
                <ModalBody>
                    <Stack spacing={4}>
                        <Field>
                            <FormLabel>Start</FormLabel>
                            <Input {...register('start_time', { required: true })} type='datetime-local' />
                        </Field>
                        <Field>
                            <FormLabel>End</FormLabel>
                            <Input {...register('stop_time', { required: true })} type='datetime-local' />
                        </Field>
                        <Field>
                            <FormLabel>Local Time</FormLabel>
                            <Switch {...register('local_time')} defaultChecked />
                        </Field>
                        <Field>
                            <FormLabel>Air quality index</FormLabel>
                            <Select
                                isInvalid={Boolean(errors['aqi'])}
                                {...register('aqi', { required: true })}
                            >
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                            </Select>
                        </Field>
                        <Field>
                            <FormLabel>Outdoor temperature (°C)</FormLabel>
                            <Input
                                {...register('temperature', { required: true, min: -99, max: 99 })}
                                placeholder='Outdoor temperature...'
                                defaultValue={temperature}
                                type='number'
                                onChange={e => {
                                    updateDewPoint(Number(e.target.value), humidity)
                                }} />
                        </Field>

                        <Field>
                            <FormLabel>Outdoor relative humidity</FormLabel>
                            <Input
                                {...register('humidity', { required: true, min: 1, max: 100 })}
                                placeholder='Outdoor relative humidity...'
                                defaultValue={humidity}
                                type='number'
                                onChange={e => { updateDewPoint(temperature, Number(e.target.value)) }} />
                        </Field>
                        <Field>
                            <FormLabel size='lg'>Outdoor dew point (°C): {dewPoint}</FormLabel>

                        </Field>
                        <Alert borderRadius='md' status='warning'>
                            <AlertIcon />
                            Existing outdoor weather data within the selected timeframe will be overwritten and cannot be recovered
                        </Alert>
                    </Stack>
                </ModalBody>
                <ModalFooter>
                    <HStack>
                        {Boolean(Object.keys(errors).length) && (
                            <Text color='red'>{capitalize(Object.keys(errors)[0])} is required</Text>
                        )}
                        <Button
                            size='sm'
                            onClick={() => setIsOpen(false)}
                            variant='ghost'
                            disabled={loading}
                        >
                            Cancel
                        </Button>
                        <Button
                            size='sm'
                            colorScheme='blue'
                            type='submit'
                            isLoading={loading}
                            disabled={Boolean(Object.keys(errors).length)}
                        >
                            Simulate
                        </Button>
                    </HStack>
                </ModalFooter>
            </form>
        )
    }

    return (
        <>
            <Button onClick={() => setIsOpen(true)}>Outdoor data</Button>
            <Modal
                onClose={() => setIsOpen(false)}
                isOpen={isOpen}
            >
                <ModalOverlay />
                <ModalContent>
                    {renderContent()}
                </ModalContent>
            </Modal>
        </>
    )
}

export default SimulateData
