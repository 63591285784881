import { Box, HStack, Heading, Image, Select, Text, VStack } from "@chakra-ui/react";
import { HavenSlider, Status, ValueStatus } from "components/inputs";
import React, { useEffect, useState } from "react";

import date from "date-and-time";
import { fieldToLabel } from "utils/text-transform";

export function MonitorTelemetry({ currentTelemetry, havenScore }) {
    return (
        <>
            {currentTelemetry && (
                <>
                    {currentTelemetry && <CurrentTelemetry data={currentTelemetry}></CurrentTelemetry>}
                    {!currentTelemetry && <Text>Current Telemetry could not load</Text>}
                    <br></br>
                    {havenScore && <HavenScore data={havenScore}></HavenScore>}
                    {!havenScore && <Text>Haven Score could not load</Text>}
                </>
            )}
            {!currentTelemetry && (
                <HStack>
                    <Image src="/assets/cross.png" height="1.4rem" />
                    <Text color="#F96C6C">No telemetry found</Text>
                </HStack>
            )}
        </>
    );
}

function CurrentTelemetry({ data }) {
    const [telemetryStatus, setTelemetryStatus] = useState(null);
    const [telemetryStatusText, setTelemetryStatusText] = useState(null);
    const [telemetryTsLocal, setTelemetryTsLocal] = useState(null);
    const [telemetryTsUtc, setTelemetryTsUtc] = useState(null);

    useEffect(() => {
        if (data?.timestamp) {
            let ts = new Date(data?.timestamp);
            let now = new Date();
            const localTs = date.format(ts, "YYYY-MM-DD HH:mm:ss", false);
            const utcTs = date.format(ts, "YYYY-MM-DD HH:mm:ss", true);
            setTelemetryTsLocal(localTs);
            setTelemetryTsUtc(utcTs);

            let hoursDiff = (now.getTime() - ts.getTime()) / 1000 / 60 / 60;
            if (hoursDiff < 24) {
                setTelemetryStatus(Status.GOOD);
                setTelemetryStatusText("Today");
            } else if (hoursDiff < 24 * 14) {
                setTelemetryStatus(Status.WARNING);
                setTelemetryStatusText("Last 2 weeks");
            } else {
                setTelemetryStatus(Status.BAD);
                setTelemetryStatusText("No data in 2 weeks");
            }
        } else {
            setTelemetryStatus(Status.BAD);
            setTelemetryStatusText("No data in 2 weeks");
        }
    }, [data]);

    function getAQStatus(value) {
        if (value === "good") {
            return Status.GOOD;
        } else if (value === "fair") {
            return Status.WARNING;
        } else if (value === "poor") {
            return Status.BAD;
        }
    }

    return (
        <VStack align="stretch">
            <ValueStatus
                label="Last active (Local)"
                value={telemetryStatusText}
                secondaryValue={telemetryTsLocal}
                status={telemetryStatus}
            ></ValueStatus>
            <ValueStatus
                label="Last active (UTC)"
                value={telemetryStatusText}
                secondaryValue={telemetryTsUtc}
                status={telemetryStatus}
            ></ValueStatus>

            <ValueStatus label="VOC Status" value={fieldToLabel(data?.voc_status)} status={getAQStatus(data?.voc_status)}></ValueStatus>
            <ValueStatus label="PM Status" value={fieldToLabel(data?.pm_status)} status={getAQStatus(data?.pm_status)}></ValueStatus>
            <ValueStatus
                label="Humidity Status"
                value={fieldToLabel(data?.humidity_status)}
                status={getAQStatus(data?.humidity_status)}
            ></ValueStatus>
            <ValueStatus
                label="Combined Status"
                value={fieldToLabel(data?.combined_status)}
                status={getAQStatus(data?.combined_status)}
            ></ValueStatus>
        </VStack>
    );
}

function HavenScore({ data }) {
    const [currentInterval, setCurrentInterval] = useState("current_hour");

    return (
        <VStack align="stretch">
            <Heading size="md">HAVEN Score</Heading>
            <Select onChange={e => setCurrentInterval(e.target.value)}>
                <option value="current_hour">Current hour</option>
                <option value="current_day">Current day</option>
                <option value="last_day">Last day</option>
                <option value="current_week">Current week</option>
                <option value="last_week">Last week</option>
                <option value="current_month">Current month</option>
                <option value="last_month">Last month</option>
            </Select>
            <Box></Box>
            <HavenSlider label="Total" percentage={data[currentInterval]?.total_score}></HavenSlider>
            <HavenSlider label="Air movement" percentage={data[currentInterval]?.air_movement_score}></HavenSlider>
            <HavenSlider label="Filtration" percentage={data[currentInterval]?.filtration_score}></HavenSlider>
            <HavenSlider label="Ventilation" percentage={data[currentInterval]?.ventilation_score}></HavenSlider>
            <HavenSlider label="Humidity" percentage={data[currentInterval]?.humidity_score}></HavenSlider>
        </VStack>
    );
}
