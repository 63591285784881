/**
 * Change the date returned from the server into a more readable format.
 * For example, 2020-01-01T12:34:12.314122 -> 2020-01-01 12:34:12
 */
export function formatServerDate(value) {
    if (value) {
        return new Date(value).toISOString().replace("T", " ").split(".")[0];
    } else {
        return "n/a";
    }
}
