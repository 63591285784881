import { usePost } from "api/api-hooks";
import { FormGroup, UpdateAction } from "components/form";
import { StringInput, PhoneInput, BooleanInput, SelectInput, DateTimeInput } from "components/inputs";
import React, { useState } from "react";
import { useForm } from "react-hook-form";

export function UserDetails(props) {
    const [details, setDetails] = useState(props.details);
    const { call } = usePost(`user/${details.id}`);

    let form = useForm({ defaultValues: { ...details } });

    let { register, handleSubmit, reset, control } = form;

    function handleReset() {
        reset({ ...details });
    }

    function onSubmit(data) {
        data.beta = data.beta === "true";
        data.tos_accepted = data.tos_accepted === "true";
        data.pro_portal_demo_enabled = data.pro_portal_demo_enabled === "true";
        call(data, () => {
            setDetails(data);
        });
    }

    return (
        <>
            <FormGroup>
                <StringInput form={form} field="id" disabled={true}></StringInput>
                <StringInput form={form} field="login_method" disabled={true}></StringInput>
                <StringInput form={form} field="email" disabled={true}></StringInput>
                <StringInput form={form} field="first_name"></StringInput>
                <StringInput form={form} field="last_name"></StringInput>
                <PhoneInput form={form} required={false}></PhoneInput>
                <SelectInput
                    form={form}
                    field="type"
                    options={["non_professional", "service_professional", "other_professional", "test", "staff", "demo", "typo", "unknown"]}
                ></SelectInput>
                <BooleanInput form={form} field="tos_accepted"></BooleanInput>
                <StringInput form={form} field="tos_version" disabled={true}></StringInput>
                <DateTimeInput form={form} field="tos_timestamp"></DateTimeInput>
                <BooleanInput form={form} field="beta"></BooleanInput>
                <SelectInput form={form} field="pm_unit" options={["pm_mc", "pm_count"]}></SelectInput>
                <SelectInput form={form} field="voc_unit" options={["voc_mc", "voc_count"]}></SelectInput>
                <StringInput form={form} field="temperature_unit" disabled={true}></StringInput>
                <StringInput form={form} field="distance_unit" disabled={true}></StringInput>
                <BooleanInput form={form} field="pro_portal_demo_enabled"></BooleanInput>
            </FormGroup>

            <UpdateAction onReset={handleReset} onUpdate={handleSubmit(onSubmit)}></UpdateAction>
        </>
    );
}
