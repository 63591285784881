import { AutomationInput, AutomationOutput, AutomationSchedule, AutomationTrigger, Equipment } from "types";
import { create } from "zustand";

export interface AutomationRule {
    trigger: AutomationTrigger
    inputs: AutomationInput[]
    schedules: AutomationSchedule[]
    equipment: Equipment[]
    outputs: AutomationOutput[]
}

export interface RulesStore {
    [key: number]: AutomationRule[]
}

const useRulesStore = create<RulesStore>(set => ({
}))

const setRules = (x: Partial<RulesStore>) => useRulesStore.setState(x)

export {
    setRules,
}

export default useRulesStore
