import { Heading } from "@chakra-ui/react";
import { StringInput, SelectInput, DateTimeInput } from "components/inputs";
import { FormGroup } from "components/form";
import EntitiesList from "components/entities-list";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { usePost } from "api/api-hooks";

export function CompanyJourney({ companyId, journey, journeyLog }) {
    const form = useForm({ defaultValues: { ...journey } });
    const { first_customer_install_service_log_id } = journey
    const [loading, setLoading] = useState(false)

    const { call } = usePost(`service_company/${companyId}/journey`)

    const onChange = async event => {
        setLoading(true)
        const { target: { value } } = event

        try {
            await call({
                state: value,
                service_log_id: first_customer_install_service_log_id,
            })
        } catch (e) {
            console.error(e) // send this to sentry or something?
        }
        setLoading(false)
    }

    const disabled = loading

    return (
        <>
            <FormGroup>
                <SelectInput
                    form={form}
                    field="state"
                    disabled={disabled}
                    onChange={onChange}
                    options={[
                        "created",
                        "sign_up_stuck",
                        "pup_purchased",
                        "pup_received",
                        "pup_stuck",
                        "pup_install",
                        "customer_install_stuck",
                        "customer_install",
                        "repeat_install_stuck",
                        "repeat_install"
                    ]}
                ></SelectInput>
                <DateTimeInput form={form} field="pup_purchased_timestamp" disabled={true}></DateTimeInput>
                <DateTimeInput form={form} field="pup_received_timestamp" disabled={true}></DateTimeInput>
                <StringInput form={form} field="pup_install_service_log_id" disabled={true}></StringInput>
                <StringInput form={form} field="first_customer_install_service_log_id" disabled={true}></StringInput>
                <StringInput form={form} field="repeat_customer_install_service_log_id" disabled={true}></StringInput>
            </FormGroup>
            <br />
            <Heading size="md">History</Heading>
            {journeyLog.logs && (
                <EntitiesList
                    list={journeyLog.logs}
                    columns={["timestamp", "state"]}
                ></EntitiesList>
            )}

        </>
    );
}
