import {
    GrafanaLinkController,
    GrafanaLinkCuttlefish,
    GrafanaLinkEdges,
    GrafanaLinkHavoc,
    GrafanaLinkMonitor,
} from "components/grafana-links";
import { HStack, Text, VStack } from "@chakra-ui/react";

/**
 * Telemetry view contains links to Grafana telemetry charts.
 */
export default function Telemetry() {
    return (
        <VStack mt={4}>
            <Text>Telemetry visualization is moving to Grafana! Check out the following links to find the latest telemetry charts:</Text>
            <HStack>
                <GrafanaLinkController />
                <GrafanaLinkMonitor />
                <GrafanaLinkCuttlefish />
                <GrafanaLinkEdges />
                <GrafanaLinkHavoc />
            </HStack>
        </VStack>
    );
}
