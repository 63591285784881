import { FormLabel as ChFormLabel, useColorModeValue } from "@chakra-ui/react";

const useFormLabelColor = () => useColorModeValue('gray.600', 'gray.500')

const FormLabel: typeof ChFormLabel = props => {
    const color = useFormLabelColor()
    return <ChFormLabel fontWeight={400} color={color} {...props} />
}

export default FormLabel
