import { Box, HStack, Heading, Image, Spinner, VStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
    addCompanyTag,
    addDeviceTag,
    addDwellingTag,
    addEquipmentTag,
    addFilterTag,
    addUserTag,
    getCompanyTags,
    getDeviceTags,
    getDwellingTags,
    getEquipmentTags,
    getFilterTags,
    getUserTags,
    removeCompanyTag,
    removeDeviceTag,
    removeDwellingTag,
    removeEquipmentTag,
    removeFilterTag,
    removeUserTag,
} from "api/api";
import { useCompany, useDevice, useDwelling, useEquipment, useFilter, useUser } from "api/api-hooks";

import Company from "pages/entities/company/company";
import { Device } from "./entities/device/device";
import Dwelling from "pages/entities/dwelling/dwelling";
import Equipment from "pages/entities/equipment/equipment";
import Filter from "pages/entities/filter/filter";
import Tagging from "components/tagging";
import User from "pages/entities/user/user";
import { useParams } from "react-router-dom";

/**
 * Entity page component that implements the view of entity details, its tags, corresponding data, relations, etc.
 */
function EntityPage({ header, details, tagging }) {

    return (
        <VStack alignItems={"flex-start"} h="100%" ml={4} mt={4}>
            <HStack alignItems='center' w='100%' justifyContent='space-between'>
                <Heading
                    mt={2}
                // fontSize={"1.5rem"}
                >
                    {header}
                </Heading>
                {tagging}
            </HStack>
            <Box
                display="flex"
                flexWrap="wrap"
                pr={3}
                pt={0}
                pb={5}
                w="100%">
                {details}
            </Box>
        </VStack>
    );
}

export function UserPage() {
    let { id } = useParams();
    const user = useUser(+id);

    return (
        <EntityPage
            header="User"
            details={<User user={user} />}
            tagging={<Tagging id={id} getEntityTags={getUserTags} removeEntityTag={removeUserTag} addEntityTag={addUserTag}></Tagging>}
        ></EntityPage>
    );
}

export function DwellingPage() {
    let { id } = useParams();
    const dwelling = useDwelling(+id);

    return (
        <EntityPage
            header="Dwelling"
            details={<Dwelling dwelling={dwelling} />}
            tagging={
                <Tagging
                    id={id}
                    getEntityTags={getDwellingTags}
                    removeEntityTag={removeDwellingTag}
                    addEntityTag={addDwellingTag}
                ></Tagging>
            }
        ></EntityPage>
    );
}

export function CompanyPage() {
    let { id } = useParams();
    const company = useCompany(+id);

    return (
        <EntityPage
            header="Company"
            details={<Company company={company} />}
            tagging={
                <Tagging id={id} getEntityTags={getCompanyTags} removeEntityTag={removeCompanyTag} addEntityTag={addCompanyTag}></Tagging>
            }
        ></EntityPage>
    );
}

export function EquipmentPage() {
    let { id } = useParams();
    const equipment = useEquipment(+id);

    return (
        <EntityPage
            header="Equipment"
            details={<Equipment equipment={equipment} />}
            tagging={
                <Tagging
                    id={id}
                    getEntityTags={getEquipmentTags}
                    removeEntityTag={removeEquipmentTag}
                    addEntityTag={addEquipmentTag}
                ></Tagging>
            }
        ></EntityPage>
    );
}

export function FilterPage() {
    let { id } = useParams();
    const filter = useFilter(+id);

    return (
        <EntityPage
            header="Filter"
            details={<Filter filter={filter} />}
            tagging={
                <Tagging id={id} getEntityTags={getFilterTags} removeEntityTag={removeFilterTag} addEntityTag={addFilterTag}></Tagging>
            }
        ></EntityPage>
    );
}

/**
 * DevicePage is not using <EntityPage> because it has a custom style header that changes based on device type.
 */
export function DevicePage() {
    let { id } = useParams();
    const device = useDevice(+id);

    return <Device device={device}></Device>;
}
