import { Button, Image } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import { getGrafanaEnvironmentName } from "utils/env-detect";

export function GrafanaLinkEdges({ pcb = null }) {
    const [url, setUrl] = useState("");

    useEffect(() => {
        let url = `https://grafana.tzoa.com/d/SXuglH_nk/edge-detection-periods?orgId=1&var-env=Haven%20DB%20-%20${getGrafanaEnvironmentName()}`;

        setUrl(url);
    }, []);

    return (
        <Button
            fontSize="14px"
            border="1px"
            h={9}
            borderColor="#9BB3BF"
            variant="outline"
            color="#9BB3BF"
            rightIcon={<Image src="/assets/grafana.png" height="1.25rem" />}
            onClick={() => window.open(url, "_blank").focus()}
        >
            EDGE DETECTION CHARTS
        </Button>
    );
}

export function GrafanaLinkHavoc({ pcb = null }) {
    const [url, setUrl] = useState("");

    useEffect(() => {
        let url = `https://grafana.tzoa.com/d/F6FKLfQnk/havoc-telemetry?orgId=1`;

        setUrl(url);
    }, []);

    return (
        <Button
            fontSize="14px"
            border="1px"
            h={9}
            borderColor="#9BB3BF"
            variant="outline"
            color="#9BB3BF"
            rightIcon={<Image src="/assets/grafana.png" height="1.25rem" />}
            onClick={() => window.open(url, "_blank").focus()}
        >
            HAVOC CHARTS
        </Button>
    );
}

export function GrafanaLinkCuttlefish({ pcb = null }) {
    const [url, setUrl] = useState("");

    useEffect(() => {
        let url = `https://grafana.tzoa.com/d/TdTffR97k/cuttlefish-telemetry?orgId=1&var-env=Haven%20DB%20-%20${getGrafanaEnvironmentName()}`;

        setUrl(url);
    }, []);

    return (
        <Button
            fontSize="14px"
            border="1px"
            h={9}
            borderColor="#9BB3BF"
            variant="outline"
            color="#9BB3BF"
            rightIcon={<Image src="/assets/grafana.png" height="1.25rem" />}
            onClick={() => window.open(url, "_blank").focus()}
        >
            CUTTLEFISH CHARTS
        </Button>
    );
}

export function GrafanaLinkMonitor({ pcb = null }) {
    const [url, setUrl] = useState("");

    useEffect(() => {
        let url = `https://grafana.tzoa.com/d/rawzffu7k/monitor-telemetry?orgId=1&var-env=Haven%20DB%20-%20${getGrafanaEnvironmentName()}`;

        if (pcb) {
            url += `&var-monitor=${pcb}`;
        }

        setUrl(url);
    }, []);

    return (
        <Button
            fontSize="14px"
            border="1px"
            h={9}
            borderColor="#9BB3BF"
            variant="outline"
            color="#9BB3BF"
            rightIcon={<Image src="/assets/grafana.png" height="1.25rem" />}
            onClick={() => window.open(url, "_blank").focus()}
        >
            MONITOR CHARTS
        </Button>
    );
}

export function GrafanaLinkController({ pcb = null }) {
    const [url, setUrl] = useState("");

    useEffect(() => {
        let url = `https://grafana.tzoa.com/d/uJu6td_nz/controller-telemetry?orgId=1&var-haven_env=HAVEN%20Business%20DB%20-%20${getGrafanaEnvironmentName()}&var-telemetry_env=Haven%20DB%20-%20${getGrafanaEnvironmentName()}`;
        if (pcb) {
            url += `&var-controller=${pcb}`;
        }
        setUrl(url);
    }, []);

    return (
        <Button
            fontSize="14px"
            border="1px"
            h={9}
            borderColor="#9BB3BF"
            variant="outline"
            color="#9BB3BF"
            rightIcon={<Image src="/assets/grafana.png" height="1.25rem" />}
            onClick={() => window.open(url, "_blank").focus()}
        >
            CONTROLLER CHARTS
        </Button>
    );
}

export function GrafanaLinkAggregate() {
    return (
        <Button
            fontSize="14px"
            border="1px"
            h={9}
            borderColor="#9BB3BF"
            variant="outline"
            color="#9BB3BF"
            rightIcon={<Image src="/assets/grafana.png" height="1.25rem" />}
            onClick={() => window.open("https://grafana.tzoa.com/d/EdzlG2r7k/fm-dashboard-prod?orgId=1", "_blank").focus()}
        >
            AGGREGATE CHARTS
        </Button>
    );
}

export function GrafanaLinkDwelling({ dwelling = null }) {

    const [url, setUrl] = useState("");

    useEffect(() => {
        let url = `https://grafana.tzoa.com/d/sbmwDBhSk/dwelling-dashboard?orgId=1&var-env=Haven%20DB%20-%20${getGrafanaEnvironmentName()}`;
        if (dwelling) {
            url += `&var-dwelling=${dwelling}`;
        }
        setUrl(url);
    }, []);

    return (
        <Button
            fontSize="14px"
            border="1px"
            h={9}
            borderColor="#9BB3BF"
            variant="outline"
            color="#9BB3BF"
            rightIcon={<Image src="/assets/grafana.png" height="1.25rem" />}
            onClick={() => window.open(url, "_blank").focus()}
        >
            DWELLING DASHBOARD
        </Button>
    );
}
