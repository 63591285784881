import {
    Box,
    VStack,
    HStack,
    Select,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionIcon,
    AccordionPanel,
    Checkbox,
    Text,
} from "@chakra-ui/react";
import React from "react";

export function UserProfile({ profile }) {
    const concerns = [
        "carbon_monoxide",
        "carbon_dioxide",
        "mold",
        "viruses",
        "odors",
        "pet_dander",
        "pollen",
        "dust_mites",
        "radon",
        "formaldehyde",
        "unknown",
    ];

    const problems = ["infant", "children", "chronic_health", "feel_sick", "allergies", "elderly", "unknown"];

    const handling = [
        "open_windows",
        "ceiling_fan",
        "portable_fan",
        "in_room_humidifier",
        "in_room_dehumidifier",
        "in_room_purifier",
        "central_air_fan",
        "indoor_plants",
        "air_fresheners",
        "professional",
        "unknown",
    ];

    if (profile.code === 5) {
        return <Text>{profile.message}</Text>;
    }

    return (
        <Box>
            <VStack spacing={2} alignItems="flex-start">
                <HStack>
                    <Text>Have Central Air Handler?</Text>
                    <Text fontWeight={"bold"}>{profile.have_central_air ? "YES" : "NO"}</Text>
                </HStack>
                <HStack>
                    <Text>Filter change frequency</Text>
                    <Select value={profile.filter_change_frequency} isReadOnly={true} size="sm" w="12rem">
                        <option value="monthly">Once a month</option>
                        <option value="three_months">Once every 3 months</option>
                        <option value="six_months">Once every 6 months</option>
                        <option value="yearly">Once a year</option>
                        <option value="smart_thermostat">When my smart thermostat tells me</option>
                        <option value="professional">I don't, a professional changes it for me</option>
                        <option value="never">Never</option>
                        <option value="unknown">Unknown</option>
                    </Select>
                </HStack>

                <Accordion allowToggle w="100%">
                    <AccordionItem>
                        <AccordionButton w="100%">
                            <Box flex="1" textAlign="left">
                                <Text>Air quality concerns</Text>
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4}>
                            <VStack spacing={1} alignItems="flex-start">
                                {concerns.map(concern => (
                                    <Checkbox key={concern} pl={0} isChecked={profile.air_quality_concerns?.includes(concern)}>
                                        {concern}
                                    </Checkbox>
                                ))}
                            </VStack>
                        </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem>
                        <AccordionButton w="100%">
                            <Box flex="1" textAlign="left">
                                <Text>Air quality problems</Text>
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4}>
                            <VStack spacing={1} alignItems="flex-start">
                                {problems.map(problem => (
                                    <Checkbox key={problem} pl={0} isChecked={profile.air_quality_problems?.includes(problem)}>
                                        {problem}
                                    </Checkbox>
                                ))}
                            </VStack>
                        </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem>
                        <AccordionButton w="100%">
                            <Box flex="1" textAlign="left">
                                <Text>Air quality current handling</Text>
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4}>
                            <VStack spacing={1} alignItems="flex-start">
                                {handling.map(h => (
                                    <Checkbox key={h} pl={0} isChecked={profile.air_quality_current_handling?.includes(h)}>
                                        {h}
                                    </Checkbox>
                                ))}
                            </VStack>
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>
            </VStack>
        </Box>
    );
}
