import { HStack } from "@chakra-ui/react"
import Disabled from "components/disabled"
import { AutomationOutput } from "types"
import useAutomationsStore, { AutomationsStore } from "./automationsStore"
import Dot from "./dot"

const isNonZeroFalsey = value => (value === null || value === undefined)

const selectOutput = (equipmentId: number) => (state: AutomationsStore) => {
    let output: AutomationOutput | undefined
    for (const automation in state) {
        output = state[automation].outputs.find(x => x.equipment_id === equipmentId)
        return output
    }
}

function OutputDot({ equipmentId }) {
    const output = useAutomationsStore(selectOutput(equipmentId))
    if (!output) return null
    return (
        <HStack>
            {!isNonZeroFalsey(output.terminal) ? (
                <Disabled>(Cac {output.id}, Terminal: {output.terminal})</Disabled>
            ) : (
                null
            )}
            <Dot triggered={output.currently_triggered} />
        </HStack>
    )
}

export default OutputDot
