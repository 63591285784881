import { RelationshipFlow } from "./relationship-flow"
import { ReactFlowProvider } from 'reactflow'
import { Card, Box } from '@chakra-ui/react'


export function RelationshipCard({ automationId, showDisabled = false, devices, equipment }) {

    return (
        <Box w='100%' h={'75vh'} p={3} >
            <ReactFlowProvider>
                <RelationshipFlow automationId={automationId} showDisabled={showDisabled} devices={devices} equipment={equipment} />
            </ReactFlowProvider>
        </Box >)

}