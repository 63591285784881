import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

import { Box, useColorMode, useToast } from "@chakra-ui/react";
import React, { useRef } from "react";

import { AgGridReact } from "ag-grid-react";
import { CopyIcon } from "@chakra-ui/icons";
import { formatServerDate } from "utils/date-format";

/**
 * AgGridReact wrapper that handles common functionality such as light/dark theme styling, responsivness, custom cell rendering, and so on.
 */
export default function AgGridStyled(props) {
    const gridRef = useRef();
    const { colorMode } = useColorMode();

    /**
     * When grid is ready, fit columns to width by default. If autoSize is true, auto size columns instead.
     */
    function onGridReady() {
        if (props.autoSize === true) {
            autoSizeAll();
        } else {
            sizeToFit();
        }
    }

    /**
     * Fit columns into table's width, horizontal scrolling is disabled.
     */
    function sizeToFit() {
        gridRef.current.api.sizeColumnsToFit();
    }

    /**
     * Auto size columns, horizontal scrolling is enabled.
     */
    function autoSizeAll() {
        const skipHeader = false;
        const allColumnIds = [];
        gridRef.current.columnApi.getAllColumns().forEach(column => {
            allColumnIds.push(column.getId());
        });
        gridRef.current.columnApi.autoSizeColumns(allColumnIds, skipHeader);
    }

    /**
     * Cell renderer with an button that copies cell value into the clipboard and creates a visual "success" feedback.
     */
    function CopyValueCellRenderer(props) {
        const toast = useToast();

        function copyValue() {
            // copy value to clipboard
            navigator.clipboard.writeText(props.value);
            // create a toast feedback
            toast({
                title: "Copied",
                status: "success",
                duration: 800,
            });
        }

        return (
            <span>
                {props.value} <CopyIcon cursor="pointer" onClick={copyValue}></CopyIcon>
            </span>
        );
    }

    /**
     * Cell renderer that formats default datetime string by converting it to Date and back to ISO string, replacing T with whitespace and removing trailing milliseconds.
     */
    function TimestampCellRenderer(props) {
        return <span>{formatServerDate(props.value)}</span>;
    }

    return (
        <Box
            className={colorMode === "light" ?
                "ag-theme-alpine" :
                "ag-theme-alpine-dark"}
            w="100%"
            h="100%"
            minH="26rem">
            <AgGridReact
                frameworkComponents={{ timestampCellRenderer: TimestampCellRenderer, copyValueCellRenderer: CopyValueCellRenderer }}
                ref={gridRef}
                className={props.className}
                {...props.gridParams}
                rowSelection="single"
                onGridReady={onGridReady}
            >
                {props.children}
            </AgGridReact>
        </Box>
    );
}
