import { Switch } from "@chakra-ui/react";
import { EmailInput, NumberInput, SelectInput } from "components/inputs";
import { useState } from "react";
import { FormGroup } from "components/form";
import { HavenGreenButton } from "components/buttons";
import { useForm } from "react-hook-form";
import { usePost } from "api/api-hooks";



export function DeviceAlertForm({ devices, dwellingId, profiles }) {
    const { call } = usePost(`automation`);
    const [alertType, setAlertType] = useState("range");
    const [measurement, setMeasurement] = useState("");
    const [unitLabel, setUnitLabel] = useState("fahrenheit");
    const zones = profiles.map(profile => profile.zone);



    const [serialNumbers, setSerialNumbers] = useState(["", ...devices.data.filter(device => (device.zone === zones[0] && device.type === "cam")).map(device => device.plastic_serial_number)])
    const form = useForm({
        defaultValues: {
            zone: zones[0],
            serial_number: serialNumbers[0],
            measurement: "",
            alert_type: "range",
            low_end_start_set_point: 0,
            low_end_stop_set_point: 1,
            high_end_start_set_point: 100,
            high_end_stop_set_point: 99,
            start_set_point: 0,
            stop_set_point: 100,
            detection_duration: 3600
        },
    });

    //post a new offset and on success get the list of most recent offsets from the server
    function onAddAlert() {
        let new_values = form.watch()
        const type = new_values.alert_type === "range" ? "cam_range" : "cam_threshold";
        const device_id = devices.data.filter(device => device.plastic_serial_number === new_values.serial_number)[0].id;
        new_values.units = null;
        const input = {
            "device_id": device_id,
            "type": type,
            "measurement": new_values.measurement,
            "set_point_unit": new_values.measurement == "temperature" ? unitLabel : null,
            "detection_duration": new_values.detection_duration,
            "low_end_start_set_point": new_values.alert_type === "range" ? new_values.low_end_start_set_point : null,
            "low_end_stop_set_point": new_values.alert_type === "range" ? new_values.low_end_stop_set_point : null,
            "high_end_start_set_point": new_values.alert_type === "range" ? new_values.high_end_start_set_point : null,
            "high_end_stop_set_point": new_values.alert_type === "range" ? new_values.high_end_start_set_point : null,
            "start_set_point": new_values.alert_type === "threshold" ? new_values.start_set_point : null,
            "stop_set_point": new_values.alert_type === "threshold" ? new_values.stop_set_point : null,
            "rising": new_values.alert_type === "threshold" ? (new_values.rising === "true" ? true : false) : null
        }
        Object.keys(input).forEach(key => input[key] === null && delete input[key]);
        const output = {
            "type": "email",
            "email": new_values.email
        }
        const rule = { "filtration": false, "ventilation": false, "humidity": false, "outputs": [0], "inputs": [0] }
        const automation = {
            "dwelling_id": dwellingId,
            "zone": new_values.zone,
            "inputs": [input],
            "outputs": [output],
            "rules": [rule]

        }
        call(automation, result => {
            console.log("result:", result);
        });



    }

    function onZoneSelect(e) {

        setSerialNumbers(["", ...devices.data.filter(device => (device.zone === e.target.value && device.type === "cam")).map(device => device.plastic_serial_number)]);

    }

    function onTypeSelect(e) {
        setAlertType(e.target.value);
    }

    function onMeasurementSelect(e) {
        console.log(e.target.value);
        setMeasurement(e.target.value);
    }
    function onUnitChange(e) {
        if (e.target.checked) {
            setUnitLabel("fahrenheit");
        } else {
            setUnitLabel("celsius");
        }
    }
    const MeasurementValues = [
        'pm25',
        'voc',
        'temperature',
        'humidity',
        'airflow'
    ];
    return (
        <>

            <FormGroup>
                <SelectInput form={form} field="zone" onChange={onZoneSelect} options={zones} ></SelectInput>
                <SelectInput form={form} field="serial_number" options={serialNumbers} ></SelectInput>
                <SelectInput form={form} field="measurement" onChange={onMeasurementSelect} options={MeasurementValues}></SelectInput>
                {measurement === "temperature" && <Switch id="units" colorScheme="green" size="lg" onChange={onUnitChange} defaultChecked="true">{unitLabel}</Switch>}
                <SelectInput form={form} field="alert_type" onChange={onTypeSelect} options={["range", "threshold"]}></SelectInput>
                {alertType === "threshold" && <NumberInput form={form} field="start_set_point" min={0} ></NumberInput>}
                {alertType === "threshold" && (measurement === 'pm25' || measurement === 'voc') && <NumberInput form={form} field="stop_set_point" min={0} ></NumberInput>}
                {alertType === "threshold" && <SelectInput form={form} field="rising" options={["true", "false"]} ></SelectInput>}
                {alertType === "range" && <NumberInput form={form} field="low_end_start_set_point" min={0} ></NumberInput>}
                {alertType === "range" && (measurement === 'pm25' || measurement === 'voc') && <NumberInput form={form} field="low_end_stop_set_point" min={0} ></NumberInput>}
                {alertType === "range" && <NumberInput form={form} field="high_end_start_set_point" min={0}></NumberInput>}
                {alertType === "range" && (measurement === 'pm25' || measurement === 'voc') && <NumberInput form={form} field="high_end_stop_set_point" min={0}></NumberInput>}
                <EmailInput form={form} field="email"></EmailInput>
                <NumberInput form={form} field="detection_duration" min={1} max={86400} step={1}></NumberInput>
                <HavenGreenButton label="Add Alert" onClick={onAddAlert}></HavenGreenButton>
            </FormGroup >
        </>
    );
}
//<NumberInput form={form} field="low_threshold" min={-100} step={5}></NumberInput>
//
//