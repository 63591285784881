import { ExternalLinkIcon, InfoIcon } from "@chakra-ui/icons";
import { Link, Popover, PopoverBody, PopoverContent, PopoverTrigger, Portal, Text, VStack, chakra } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Status, ValueStatus } from "components/inputs";

/**
 * Popup element that takes custom content as input
 */
export default function InfoPopup(props) {
    return (
        <Popover trigger="hover" placement="right">
            <PopoverTrigger>
                <InfoIcon cursor="pointer"></InfoIcon>
            </PopoverTrigger>
            <Portal>
                <PopoverContent>
                    <PopoverBody>{props.children}</PopoverBody>
                </PopoverContent>
            </Portal>
        </Popover>
    );
}

/**
 * Popup content describing roles
 */
export function RolesDescription() {
    return (
        <InfoPopup>
            <VStack>
                <Text>
                    <chakra.span fontWeight={600}>Manager</chakra.span> - 1 per company. Can edit company details, add new members, update
                    members' roles.
                </Text>
                <Text>
                    <chakra.span fontWeight={600}>Technician</chakra.span> - Can provision devices, create dwellings, users, equipment and
                    filters.
                </Text>
                <Text>
                    <chakra.span fontWeight={600}>Analyst</chakra.span> - Read-only access to the data about the dwellings the technicians
                    have setup.
                </Text>
                <Link href="https://teamtzoa.atlassian.net/wiki/spaces/HA/pages/385843383/Permissions" isExternal>
                    Documentation <ExternalLinkIcon mx="2px" />
                </Link>
            </VStack>
        </InfoPopup>
    );
}

/**
 * Popup content describing permissions
 */
export function PermissionsDescription() {
    return (
        <InfoPopup>
            <VStack>
                <Text>
                    <chakra.span fontWeight={600}>Admin</chakra.span> - 1 per dwelling, usually homeowner. Can read and write dwelling data,
                    control other users' permissions.
                </Text>
                <Text>
                    <chakra.span fontWeight={600}>Collaborator</chakra.span> - Full read-only access to dwelling's data. Can view: Dashboard
                    (Live telemetry data), Explore (Historical telemetry data), System (filter details, HVAC and HAVEN equipment,
                    Automations, Settings).
                </Text>
                <Text>
                    <chakra.span fontWeight={600}>Viewer</chakra.span> - Limited read-only access to dwelling's data. Can view Dashboard
                    (Live telemetry data). Cannot see historical telemetry.
                </Text>
                <Text>
                    <chakra.span fontWeight={600}>Analyst</chakra.span> - This allows the dedicated service company to have access to view
                    the dwelling's data. Their access to the dwelling is identical to collaborator's.
                </Text>
                <Link href="https://teamtzoa.atlassian.net/wiki/spaces/HA/pages/385843383/Permissions" isExternal>
                    Documentation <ExternalLinkIcon mx="2px" />
                </Link>
            </VStack>
        </InfoPopup>
    );
}

/**
 * Popup content describing error codes from the device
 */
export function ErrorCodeField({ errorCode }) {
    const [errorMessages, setErrorMessages] = useState("");
    const errors = [
        { code: 0x00000001, description: "SENSOR_STATUS_CCS811 - an error has been detected in the CCS811 VOC sensor" },
        {
            code: 0x00000002,
            description: "SENSOR_STATUS_BME280 - an error has been detected in the BME280 temperature / pressure / humidity sensor",
        },
        { code: 0x00000004, description: "SENSOR_STATUS_SCD30 - an error has been detected in the SCD30 CO2 sensor" },
        { code: 0x00000008, description: "SENSOR_STATUS_T6713 - an error has been detected in the T6713 VOC sensor" },
        {
            code: 0x00000010,
            description:
                "SENSOR_STATUS_OPC - an error has been detected in Optical Particle sensor.This represents an unspecified error, but usually represents a failure in the OPC microcontroller itself.",
        },
        {
            code: 0x00000020,
            description:
                "SENSOR_STATUS_OPC_HALL - not necessarily an error condition, this code shows that the laser sensor interlock is engaged, usually a result of the Haven not being mounted correctly in the face plate(the magnet is not aligned with our hall sensor).If the interlock is engaged, the laser will not be enabled and no particles are detected.",
        },
        { code: 0x00000040, description: "SENSOR_STATUS_OPC_LASER - a failure has been detected in the laser output" },
        {
            code: 0x00000080,
            description:
                "SENSOR_STATUS_OPC_FLOW_SENSOR_ERROR - an error in reading values from the flow sensor.If this persists, this may lead to an RMA.",
        },
        {
            code: 0x00000100,
            description:
                "SENSOR_STATUS_OPC_SAMPLING_OVERRUN - an internal microcontroller error indicating that the laser duty cycle is too high.Used in debug.Never seen in a fielded unit.",
        },
        {
            code: 0x00000200,
            description:
                "SENSOR_STATUS_OPC_PARTICLE_DETECTION_SATURATION - usually a temporary condition indicating an extremely high concentration of particles(for example a very large bolus of dust or powder) which overwhelms our counting routine.Usually resolves itself as air is filtered.",
        },
        {
            code: 0x00000400,
            description:
                "SENSOR_STATUS_OPC_FLOW_SENSOR_INTERLOCK - the detected flow is currently below the cam minimum threshold(0.2 meters / sec) and the flow interlock is engaged.If the interlock is engaged, the laser will not be enabled and no particles are detected.",
        },
        {
            code: 0x00000800,
            description:
                "SENSOR_STATUS_OPC_DUTY_CYCLE_TOO_HIGH - similar to the sampling overrun, this is an explicit indication that the Laser Duty Cycle is too high for the given particle load.Not seen in fielded units.",
        },
        {
            code: 0x00001000,
            description:
                "SENSOR_STATUS_OPC_DMA_ERROR - a DMA Error occurred while sampling ADC data.If persistant, this is indicative of a general hardware failure",
        },
        {
            code: 0x00002000,
            description:
                "SENSOR_STATUS_RM_SPIFFS_ERROR - an error occurred while accessing the internal flash file system.Additional testing may be required to ensure that offline telemetry is operating correctly.",
        },
        { code: 0x00004000, description: "SENSOR_STATUS_SPS30 - an error has been detected in the SPS30 particle sensor" },
        { code: 0x00008000, description: "SENSOR_STATUS_BMA423 - an error has been detected in the BMA423 accelerometer" },
        { code: 0x00010000, description: "SENSOR_STATUS_ZMOD4410 - an error has been detected in the ZMOD4410 particle sensor" },
    ];

    function translate(codeString) {
        let translation = "";
        let code = parseInt(codeString, 16);
        if (code === 0) {
            setErrorMessages("No errors detected");
        }

        errors.forEach(errCode => {
            if (code & errCode.code) {
                translation += errCode.description + "\n\n";
                setErrorMessages(translation);
            }
        });
    }

    useEffect(() => {
        translate(errorCode);
    });

    return (
        <>
            <ValueStatus
                label="Error code"
                value={errorCode}
                status={errorCode === "0x00000000" ? Status.GOOD : Status.BAD}
                customComponent={
                    <InfoPopup>
                        <Text>{errorMessages}</Text>
                    </InfoPopup>
                }
            ></ValueStatus>
        </>
    );
}


/**
 * Popup content describing roles
 */
export function MonitorOffsetInstructions() {
    return (
        <InfoPopup>
            <VStack>
                <Text>
                    <chakra.span fontWeight={600}>Relative Offsets</chakra.span> - Enter the most recent monitor readings for temperature and humidity into the Monitor Readings section.
                    Then enter the most recent reference readings for temperature and humidity into the Reference Readings section.
                    Click Adjust Current Offset and the server will calculate the new offset values.
                </Text>
                <Text>
                    <chakra.span fontWeight={600}>Absolute Offsets</chakra.span> - Enter the new offset values you want to set into the Monitor Readings section.
                    These will be applied as entered and will not be adjusted by the server.  Once entered, click on Set New Offset.
                </Text>
            </VStack>
        </InfoPopup>
    );
}
