import "./index.css";

import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, Button, Center, Spinner } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Route, Routes } from "react-router";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";

import Dashboard from "./pages/dashboard";
import Fleet from "./pages/fleet";
import Navbar from "./components/navbar";
import { Navigate } from "react-router-dom";
import Profile from "pages/profile";
import Telemetry from "./pages/telemetry";
import { getUserSelf } from "./api/api";
import { useLogout } from "auth/logout";

/**
 * Bootstrap for the rest of the application. This component handles initial loading, authentication, renders the navigation bar, and sets up the routing urls.
 */
function App() {
    const { isAuthenticated, isLoading, loginWithRedirect, getAccessTokenSilently } = useAuth0();
    const { fullLogout } = useLogout();

    const [authError, setAuthError] = useState(null);
    const [checkingAdmin, setCheckingAdmin] = useState(true);

    useEffect(() => {
        // Only when user is authenticated is it possible to check their admin rights
        if (!isLoading && isAuthenticated) {
            adminCheck();
        }

        // If user id is still missing after 5 seconds, refresh the page and attempt authorization again
        setTimeout(() => {
            if (sessionStorage.getItem("userId") === null) {
                window.location.reload();
            }
        }, 5000);

        // This function is used to make sure the authenticated user has role 0 (global admin) in the Haven system. This can only be done once auth0 flow is complete. User ID is then stored in the session storage to use with API calls later when needed.
        async function adminCheck() {
            // Make sure there is token in the local storage, can't get user info without it
            if (localStorage.getItem("token") === null) {
                await getAccessTokenSilently()
                    .then(token => localStorage.setItem("token", token))
                    .catch(() => loginWithRedirect());
            }

            // Get user details and check their role
            getUserSelf()
                .then(user => {
                    if (user.role === 0) {
                        sessionStorage.setItem("userId", user.id);
                    } else {
                        setAuthError(`User ${user.email} is not an admin. Please contact developers.`);
                    }
                    setCheckingAdmin(false);
                })
                .catch(error => {
                    setAuthError(error.message);
                });
        }
    }, [isLoading, isAuthenticated, getAccessTokenSilently, loginWithRedirect]);

    // Display the loading overlay if auth0 is still loading credentials
    if (isLoading) {
        return (
            <Center h="100vh" w="100vw">
                <Spinner />
            </Center>
        );
    }

    // Once auth0 is loaded, make sure the latest token in local storage is updated
    if (isAuthenticated) {
        getAccessTokenSilently()
            .then(token => localStorage.setItem("token", token))
            .catch(() => loginWithRedirect());
    }

    // After auth0 authentication is complete, next step is to check if the logged in user is the admin (role=0) in the Haven system, display another loading overaly while checking
    if (checkingAdmin) {
        return (
            <Center h="100vh" w="100vw" flexDir={"column"}>
                <Spinner />
                <Button mt={3} onClick={fullLogout}>
                    Log Out
                </Button>
            </Center>
        );
    }

    // In case of any authentication errors, display error details and an option to log out
    if (authError != null) {
        return (
            <Center h="100vh" w="100vw" flexDir={"column"}>
                <Alert status="error" w={"32rem"}>
                    <AlertIcon />
                    <AlertTitle mr={2}>Authentication error:</AlertTitle>
                    <AlertDescription>{authError}</AlertDescription>
                </Alert>
                <Button mt={3} onClick={fullLogout}>
                    Log Out
                </Button>
            </Center>
        );
    }

    return (
        <Box
            display="flex"
            flexDir="column"
            h="100vh"
            minH="100vh"
            maxH="100vh"
        >
            <Navbar></Navbar>

            <Routes>
                <Route path="/*">
                    <Route path="fleet/*" element={<Fleet />}></Route>
                    <Route path="dashboard" element={<Dashboard />}></Route>
                    <Route path="telemetry" element={<Telemetry />}></Route>
                    <Route path="profile" element={<Profile />}></Route>
                    <Route path="" element={<Navigate to="/fleet" />}></Route>
                    <Route path="*" element={<div>Invalid URL</div>} />
                </Route>
            </Routes>
        </Box>
    );
}

export default withAuthenticationRequired(App, {
    onRedirecting: () => (
        <Center h="100vh" w="100vw" flexDir={"column"} bg="transparent">
            <Spinner />
        </Center>
    ),
});
