import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Checkbox,
    HStack,
    ListItem,
    Select,
    Text,
    UnorderedList,
    VStack,
} from "@chakra-ui/react";

import React from "react";

export const companyServices = [
    "equipment_installation",
    "service",
    "maintenance_plans",
    "iaq_consultation",
    "energy_audits",
    "smart_homes",
    "high_end_homes",
    "new_construction",
    "unknown",
];

export function CompanyProfile({ profile }) {
    const focuses = [
        "single_family_residential",
        "multi_family_residential",
        "light_commercial",
        "heavy_commercial",
        "industrial",
        "unknown",
    ];

    if (profile.code === 5) {
        return <Text>{profile.message}</Text>;
    }

    return (
        <Box>
            <VStack spacing={2} alignItems="flex-start">
                <HStack>
                    <Text>Employee count</Text>
                    <Select value={profile.employee_count} size="sm" w="12rem">
                        <option value="1-10">1-10</option>
                        <option value="11-50">11-50</option>
                        <option value="51-100">51-100</option>
                        <option value="100+">100+</option>
                        <option value="unknown">Unknown</option>
                    </Select>
                </HStack>
                <HStack>
                    <Text>Have sales team?</Text>
                    <Text fontWeight={"bold"}>{profile.have_sales_team ? "YES" : "NO"}</Text>
                </HStack>
                <HStack>
                    <Text>Want demo?</Text>
                    <Text fontWeight={"bold"}>{profile.want_demo ? "YES" : "NO"}</Text>
                </HStack>
                <HStack>
                    <Text>Comment: </Text>
                    <Text>"{profile.comment}"</Text>
                </HStack>

                <Accordion allowToggle w="100%">
                    <AccordionItem>
                        <AccordionButton w="100%">
                            <Box flex="1" textAlign="left">
                                <Text>Services</Text>
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4}>
                            <VStack spacing={1} alignItems="flex-start">
                                {companyServices.map(service => (
                                    <Checkbox pl={0} isChecked={profile.services?.includes(service)}>
                                        {service}
                                    </Checkbox>
                                ))}
                            </VStack>
                        </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem>
                        <AccordionButton w="100%">
                            <Box flex="1" textAlign="left">
                                <Text>Focuses</Text>
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4}>
                            <VStack spacing={1} alignItems="flex-start">
                                {focuses.map(focus => (
                                    <Checkbox pl={0} isChecked={profile.focuses?.includes(focus)}>
                                        {focus}
                                    </Checkbox>
                                ))}
                            </VStack>
                        </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem>
                        <AccordionButton w="100%">
                            <Box flex="1" textAlign="left">
                                <Text>Distribution companies</Text>
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4}>
                            <VStack spacing={1} alignItems="flex-start">
                                <UnorderedList>
                                    {profile.distribution_companies.map(company => (
                                        <ListItem>{company}</ListItem>
                                    ))}
                                </UnorderedList>
                            </VStack>
                        </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem>
                        <AccordionButton w="100%">
                            <Box flex="1" textAlign="left">
                                <Text>Hear about us</Text>
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4}>
                            <VStack spacing={1} alignItems="flex-start">
                                <UnorderedList>
                                    {profile.hear_about_us.map(h => (
                                        <ListItem>{h}</ListItem>
                                    ))}
                                </UnorderedList>
                            </VStack>
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>
            </VStack>
        </Box>
    );
}
