import { Grid, useMediaQuery, Alert, AlertIcon } from "@chakra-ui/react";
import { useEffect, useState } from "react";

import Card from "../../../components/card";
import { CompanyDetails } from "./company-details";
import { CompanyInvites } from "./company-invites";
import { CompanyProfile } from "./company-profile";
import { CompanyJourney } from "./company-journey";
import { CompanyQuestionnaire } from "./company-questionnaire";
import DevicesList from "../../../components/devices-list";
import EntitiesTable from "components/entities-list";
import Roles from "../../../components/roles";
import { RolesDescription } from "../../../components/info-popup";

export default function Company({ company }) {
    const { details, profile, dwellings, roles, devices, invites, journey, journeyLog, onboardingQuestionnaire, ecosense } = company;

    const [isLargeScreen] = useMediaQuery("(min-width: 1480px)");
    const [isSmallScreen] = useMediaQuery("(max-width: 850px)");
    const [columnCount, setColumnCount] = useState(3);

    useEffect(() => {
        if (isSmallScreen) {
            setColumnCount(1);
        } else if (isLargeScreen) {
            setColumnCount(3);
        } else {
            setColumnCount(2);
        }
    }, [columnCount, isLargeScreen, isSmallScreen]);

    return (
        <Grid templateColumns={`repeat(${columnCount}, 1fr)`} gap={4} width="100%">
            <DetailsCard details={details} />
            <ProfileCard profile={profile} />
            <DevicesCard devices={devices} ecosense={ecosense?.data} />
            {details?.data?.id && <RolesCard roles={roles} companyId={+details?.data?.id} />}
            <DwellingsCard dwellings={dwellings} />
            <InvitesCard invites={invites} />
            <JourneyCard companyId={details?.data?.id} journey={journey} journeyLog={journeyLog} />
            {details?.data && <QuestionnaireCard details={details} questionnaireResponses={onboardingQuestionnaire} />}
        </Grid>
    );
}


function QuestionnaireCard({ details, questionnaireResponses }) {
    let error = ""
    if (questionnaireResponses?.error) {
        error = questionnaireResponses.error.toString().toLowerCase().charAt(0).toUpperCase() + questionnaireResponses.error.toString().toLowerCase().slice(1);
    }
    return (
        <Card header="Questionnaire" error={details.error} loading={details.loading}>
            {questionnaireResponses?.error && <Alert status="info"><AlertIcon />{error}</Alert>}
            {details?.data?.id && (questionnaireResponses?.data || questionnaireResponses?.error) && <CompanyQuestionnaire companyId={details.data.id} questionnaireResponses={questionnaireResponses.data || null} />}
        </Card>
    );
}


function DetailsCard({ details }) {
    return (
        <Card header="Details" error={details.error} loading={details.loading}>
            {details.data && <CompanyDetails details={details.data}></CompanyDetails>}
        </Card>
    );
}

function ProfileCard({ profile }) {
    return (
        <Card header="Profile" error={profile.error} loading={profile.loading}>
            {profile.data && <CompanyProfile profile={profile.data}></CompanyProfile>}
        </Card>
    );
}

function DevicesCard({ devices, ecosense }) {
    return (
        <Card header="Devices" error={devices.error} loading={devices.loading}>
            {devices.data && <DevicesList devices={devices.data} ecosense={ecosense}></DevicesList>}
        </Card>
    );
}

function RolesCard({ roles, companyId }) {
    return (
        <Card header="Roles" error={roles.error} loading={roles.loading} descriptionPopup={<RolesDescription />}>
            {roles.data && <Roles roles={roles.data} id={companyId} parentEntity={"company"}></Roles>}
        </Card>
    );
}

function DwellingsCard({ dwellings }) {
    return (
        <Card header="Dwellings" error={dwellings.error} loading={dwellings.loading} iconUrl="/assets/dwelling.png">
            {dwellings.data && <EntitiesTable list={dwellings.data} openUrl="dwellings" columns={["id", "name"]}></EntitiesTable>}
        </Card>
    );
}

function InvitesCard({ invites }) {
    return (
        <Card header="Team Invites" error={invites.error} loading={invites.loading}>
            {invites.data && <CompanyInvites invites={invites.data}></CompanyInvites>}
        </Card>
    );
}

function JourneyCard({ companyId, journey, journeyLog }) {
    return (
        <Card header="Journey" error={journey.error || journeyLog.error} loading={journey.loading && journeyLog.loading}>
            {journey.data && journeyLog.data && <CompanyJourney companyId={companyId} journey={journey.data} journeyLog={journeyLog.data} />}
        </Card>
    );
}
