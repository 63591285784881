import { Text, VStack } from "@chakra-ui/react";

import { GrafanaLinkAggregate } from "components/grafana-links";
import React from "react";

export default function Dashboard() {
    return (
        <VStack mt={4}>
            <Text>Aggregate metrics are moving to Grafana. Check out the following links to find the latest aggregate metrics:</Text>
            <GrafanaLinkAggregate />
        </VStack>
    );
}
