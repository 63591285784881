import { HStack, Spinner, Text } from "@chakra-ui/react";
import React, { useState } from "react";

import { HavenGreenButton } from "components/buttons";
import { getDeviceCalibrationReportUrl } from "api/api";

export function DeviceCalibrationReport({ id }) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    function onClick() {
        setLoading(true);
        setError(false);
        getDeviceCalibrationReportUrl(id)
            .then(result => {
                const url = result["url"];
                download(url);
            })
            .catch(error => {
                setError(true);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    function download(url) {
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    }

    return (
        <HStack>
            <HavenGreenButton label={"Download Calibration Report"} onClick={onClick}></HavenGreenButton>
            {loading && <Spinner></Spinner>}
            {error && <Text>Report not found</Text>}
        </HStack>
    );
}
