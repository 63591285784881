import { Box, Button } from "@chakra-ui/react";
import { useAuth0 } from "@auth0/auth0-react";
import { useLogout } from "auth/logout";

export default function Profile() {
    const { user, isAuthenticated, isLoading } = useAuth0();
    const { fullLogout } = useLogout();

    if (isLoading) {
        return <div>Loading profile...</div>;
    }

    return (
        isAuthenticated && (
            <Box m={3}>
                <img src={user.picture} alt={user.name} />
                <h2>name: {user.name}</h2>
                <p>email: {user.email}</p>
                <Button onClick={fullLogout}>Log Out</Button>
            </Box>
        )
    );
}
