
import {
    StringInput,
    NumberInput,
    SelectInput,
    MultilineStringInput,
    DateTimeInput,

} from "components/inputs";
import { HavenGreenButton } from "components/buttons";
import InfoPopup from "components/info-popup";
import { FormGroup } from "components/form";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { usePost } from "api/api-hooks";
import InputLabel from "components/input-label";
import { FormLabel } from "@chakra-ui/react";



interface QuestionnaireFormValues {
    goal?: string,
    revenue_rank?: number,
    loyalty_rank?: number,
    differentiation_rank?: number,
    filtration_solutions_sold: number,
    filtration_solutions: string,
    ventilation_solutions_sold: number,
    ventilation_solutions: string,
    humidity_solutions_sold: number,
    humidity_solutions: string,
    total_installs: number,
    go_to_market?: string,
    go_to_market_strategy?: number,
    sales_commitment: number,
    sales_increase: number,
    follow_up_appointment: Date,
}



export function CompanyQuestionnaire({ companyId, questionnaireResponses }) {

    const GOALS = ["Increase Revenue", "Customer Loyalty", "Service Differentiation"]
    const GTM = ["Include in quote as part of all or a subset of HVAC replacement projects [i.e. in all better & best packages]. ",
        "Make HAVEN part of all or a subset of your service maintenance contracts",
        "Offer as an upsell accessory to customers that express interest in IAQ. This includes temporary assessments. "]

    let goalIndex = 0
    let gtmIndex = 0
    let followUp = new Date()
    if (questionnaireResponses) {
        if (questionnaireResponses.revenue_rank === 1) {
            goalIndex = 0
        }
        if (questionnaireResponses.loyalty_rank === 1) {
            goalIndex = 1
        }
        if (questionnaireResponses.differentiation_rank === 1) {
            goalIndex = 2
        }
        followUp = new Date(questionnaireResponses.follow_up_appointment)
        gtmIndex = questionnaireResponses.go_to_market_strategy
    }

    const defaultValues = {
        goal: GOALS[goalIndex],
        filtration_solutions_sold: questionnaireResponses?.filtration_solutions_sold || 0,
        filtration_solutions: questionnaireResponses?.filtration_solutions || "",
        ventilation_solutions_sold: questionnaireResponses?.ventilation_solutions_sold || 0,
        ventilation_solutions: questionnaireResponses?.ventilation_solutions || "",
        humidity_solutions_sold: questionnaireResponses?.humidity_solutions_sold || 0,
        humidity_solutions: questionnaireResponses?.humidity_solutions || "",
        total_installs: questionnaireResponses?.total_installs || 0,
        go_to_market: GTM[gtmIndex],
        sales_commitment: questionnaireResponses?.sales_commitment || 0,
        sales_increase: questionnaireResponses?.sales_increase || 0,
        follow_up_appointment: followUp,
    }


    const form = useForm({ defaultValues: { ...defaultValues } });
    const [loading, setLoading] = useState(false)
    const { call } = usePost(`service_company/${companyId}/sales_questionnaire`)
    function onSubmit() {
        let value: QuestionnaireFormValues = form.getValues()
        if (value.goal === GOALS[0]) {
            value["revenue_rank"] = 1
            value["differentiation_rank"] = 2
            value["loyalty_rank"] = 3
        }
        if (value.goal === GOALS[1]) {
            value["revenue_rank"] = 3
            value["differentiation_rank"] = 2
            value["loyalty_rank"] = 1
        }
        if (value.goal === GOALS[2]) {
            value["revenue_rank"] = 2
            value["differentiation_rank"] = 1
            value["loyalty_rank"] = 3
        }
        if (value.go_to_market === GTM[0]) {
            value["go_to_market_strategy"] = 0
        }
        if (value.go_to_market === GTM[1]) {
            value["go_to_market_strategy"] = 1
        }
        if (value.go_to_market === GTM[2]) {
            value["go_to_market_strategy"] = 2
        }
        delete value.goal
        delete value.go_to_market

        call(value)
    }

    return (
        <>
            <FormGroup>

                <SelectInput
                    form={form}
                    field="goal"
                    options={GOALS}
                ></SelectInput>

                <InputLabel>Filtration Solutions</InputLabel>
                <NumberInput form={form} field="filtration_solutions_sold" min={0} max={35000} step={1}></NumberInput>
                <MultilineStringInput form={form} field={"filtration_solutions"} disabled={false}></MultilineStringInput>
                <InputLabel>Ventilation Solutions</InputLabel>
                <NumberInput form={form} field="ventilation_solutions_sold" min={0} max={35000} step={1}></NumberInput>
                <MultilineStringInput form={form} field={"ventilation_solutions"} disabled={false}></MultilineStringInput>
                <InputLabel>Humidity Solutions</InputLabel>
                <NumberInput form={form} field="humidity_solutions_sold" min={0} max={35000} step={1}></NumberInput>
                <MultilineStringInput form={form} field={"humidity_solutions"} disabled={false}></MultilineStringInput>
                <NumberInput form={form} field="total_installations" min={0} max={35000} step={1}></NumberInput>
                <SelectInput form={form} field="go_to_market" options={GTM}></SelectInput>
                <NumberInput form={form} field="sales_commitment" min={0} max={35000} step={1}></NumberInput>
                <NumberInput form={form} field="sales_increase" min={0} max={35000} step={1}></NumberInput>
                <DateTimeInput form={form} field="follow_up_appointment" disabled={false}></DateTimeInput>
                <FormLabel>All sales/installation numbers are annual figures</FormLabel>
                <HavenGreenButton label="Submit" onClick={onSubmit}></HavenGreenButton>
            </FormGroup>


        </>
    );
}
