import { Auth0Provider } from "@auth0/auth0-react";
import React from "react";
import { useNavigate } from "react-router-dom";

/**
 * A wrapper for the Auth0 provider. This is just the boilerplate code provided by Auth0.
 */
export default function AuthProvider({ children }) {
    const navigate = useNavigate();

    const domain = process.env.REACT_APP_AUTH0_DOMAIN;
    const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
    const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

    function onRedirectCallback(appState) {
        navigate(appState?.returnTo || window.location.pathname);
    }

    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            redirectUri={window.location.origin}
            onRedirectCallback={onRedirectCallback}
            audience={audience}
            useRefreshTokens={true}
        >
            {children}
        </Auth0Provider>
    );
}
