import ReactFlow,
{
    Controls, Background,
    Handle, Position,
    NodeProps, Node,
    XYPosition, Edge,
    Panel,
    useOnSelectionChange,
    useNodesState,
    useEdgesState,
    useReactFlow,
    ReactFlowProvider,
    useNodesInitialized
} from 'reactflow';
import Dagre from '@dagrejs/dagre'
import { Card, Text, useColorModeValue, Box, VStack } from '@chakra-ui/react';
import { useMemo, useState, useCallback, useEffect } from 'react';
import { AutomationInput, AutomationOutput, AutomationSchedule, AutomationTrigger, AutomationInterlock } from "types"
import { fieldToLabel, capitalize } from "utils/text-transform"
import { useMuted } from "pages/util"

import 'reactflow/dist/style.css';


export type RelationshipItemData = {
    type: string,
    item: AutomationInput | AutomationSchedule | AutomationTrigger | AutomationOutput | AutomationInterlock,
    sources?: string[],
    targets?: string[],
    interlockSources?: string[],
    interlockTargets?: string[]

}


const color = type => {
    switch (type) {
        case 'input': return 'blue.500'
        case 'rule': return 'green.500'
        case 'output': return 'purple.500'
    }
}


const Detail = props => {
    const color = useMuted()
    return <Text fontSize='12px' color={color}  {...props} />
}

const Semibold = props => {
    return <Text as='span' fontWeight='semibold' {...props} />
}

const RelationshipLink = props => {
    return <Text as='u' fontWeight='semibold' {...props} />
}


export function RelationshipNode(props: NodeProps<RelationshipItemData>) {
    const { type, item, targets, sources, interlockTargets = [], interlockSources = [] } = props.data

    const inputBorderColor = useColorModeValue('blue.200', 'blue.700')
    const ruleBorderColor = useColorModeValue('green.200', 'green.700')
    const outputBorderColor = useColorModeValue('purple.200', 'purple.700')
    const interlockBorderColor = useColorModeValue('red.200', 'red.700')

    const inputHoverColor = useColorModeValue('blue.50', 'blue.800')
    const outputHoverColor = useColorModeValue('purple.50', 'purple.800')
    const ruleHoverColor = useColorModeValue('green.50', 'green.800')
    const interlockHoverColor = useColorModeValue('red.50', 'red.800')
    const hoverColor = type => {
        switch (type) {
            case 'input': return inputHoverColor
            case 'rule': return ruleHoverColor
            case 'output': return outputHoverColor
            case 'interlock': return interlockHoverColor
        }
    }

    const borderColor = type => {
        switch (type) {
            case 'input': return inputBorderColor
            case 'rule': return ruleBorderColor
            case 'output': return outputBorderColor
            case 'interlock': return interlockBorderColor
        }
    }
    const componentString = (component) => {
        switch (component) {
            case 0: return 'Compressor'
            case 1: return 'Fan'

        }
    }


    const ItemLink = (key) => {
        const link_type = key === 'device_id' ? 'devices' : 'equipments'
        return `/fleet/${link_type}/${item[key]}`

    }


    const mapDetails = (key) => {
        switch (key) {
            case 'equipment_component':
                if (item['equipment_type'] === 'ventilating_dehumidifier') {

                    return <Detail >{fieldToLabel(key)}: <Semibold>{`${componentString(item[key])}`}</Semibold></Detail>
                }
                return ""
            case 'equipment_id':
            case 'device_id':
                return (<a href={ItemLink(key)} target={'_blank'}>
                    <Detail _hover={{ backgroundColor: useMuted, color: borderColor(type) }}>
                        <RelationshipLink>{fieldToLabel(key)}: {fieldToLabel(`${item[key]}`)}</RelationshipLink>
                    </Detail >
                </a>)
            default:
                return <Detail>{fieldToLabel(key)}: <Semibold>{fieldToLabel(`${item[key]}`)}</Semibold></Detail>
        }

    }
    const renderDetail = () => {
        if (!item) return null
        return (

            <VStack
                textAlign='left'
                alignItems='flex-start'
                pb={2}
                spacing={0}
            >

                {
                    Object.keys(item)
                        .filter(x => x !== 'id')
                        .map(key => mapDetails(key))

                }
            </VStack>
        )
    }
    useEffect(() => {
    }, [item])
    return (

        <>
            {type !== 'input' && <Handle type="target" id={`${item.id}-target`} position={Position.Top} />}
            {type === 'interlock' && <Handle type="target" id={`${item.id}-target`} position={Position.Top} />}
            <Box
                data-id={item.id}
                px={2}
                width={'300px'}
                borderRadius='md'
                borderWidth='1px'
                borderColor={borderColor(type)}
                _hover={{ bg: props.selected ? hoverColor(type) : 'none', boxShadow: 'md' }}
                bg={props.selected ? color(type) : hoverColor(type)}

            >
                <Text
                    fontWeight={800}

                >{capitalize(type)} {item.id}</Text>
                {renderDetail()}

            </Box>
            {type !== 'output' && type !== 'interlock' && <Handle type="source" id={`${item.id}-source`} position={Position.Bottom} />}
            {type === 'output' && (interlockTargets?.length > 0 || interlockSources.length > 0) && <Handle type="source" id={`${item.id}-source`} position={Position.Bottom} />}

        </>


    );

}