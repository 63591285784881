import { Box, Card as CHCard, Button, Center, Heading, Spacer, Spinner, Text, useColorMode, useColorModeValue } from "@chakra-ui/react";

import { Image } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useDisabled } from "pages/util";

/**
 * This is a generic card element used inside every details page. This allows to make content inside the details page more modular with each card rendering specific information.
 */
export default function Card(props) {
    const { gotoUrl, header, loading, customLink, error, iconUrl = "/assets/list.png", descriptionPopup = null, ...rest } = props;

    const navigate = useNavigate();
    const borderColor = useColorModeValue('gray.100', 'gray.700')
    const disabled = useDisabled()

    return (
        <CHCard
            display="flex"
            flexDir="column"
            flex={props.flex}
            bg='transparent'
            boxShadow='none'
            borderWidth='1px'
            borderColor={borderColor}
            {...rest}
        >
            <Box
                w={"100%"}
                px={6}
                pt={5}
                display='flex'
                justifyContent='space-between'
                alignItems="center"
            >
                {/* <Image src={iconUrl} height="1.7rem" mr="1rem" /> */}
                <Heading size="xs" textTransform='uppercase' color={disabled}>
                    {header}
                </Heading>
                {customLink && <>{customLink}</>}
                {gotoUrl && (
                    <Button
                        fontSize="14px"
                        border="1px"
                        h={9}
                        borderColor="#9BB3BF"
                        variant="outline"
                        color="#9BB3BF"
                        rightIcon={<Image src="/assets/arrow-right.png" height="1.4rem" />}
                        onClick={() => navigate(gotoUrl)}
                    >
                        GO TO
                    </Button>
                )}
                {descriptionPopup && <>{descriptionPopup}</>}
            </Box>

            <Box p={6}>
                {loading && (
                    <Center>
                        <Spinner></Spinner>
                    </Center>
                )}
                {!loading && error === null && <>{props.children}</>}
                {!loading && error !== null && <Text>{error}</Text>}
            </Box>
        </CHCard>
    );
}
