import { Box, Button, Card, HStack, useColorModeValue, VStack } from "@chakra-ui/react"
import FormLabel from "components/label"
import TextLink from "components/text-link"
import { useDisabled, useMuted } from "pages/util"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { AutomationInput, AutomationTrigger, Equipment, SaveAutomation, AutomationSchedule } from "types"
import { capitalize } from "utils/text-transform"
import Header from "./header"
import InputControl from "./input-control"
import OutputDot from "./output-dot"
import Override from "./override"
import Schedule from "./schedule"
import Subtitle from "./subtitle"
import Supertitle from "./supertitle"
import useIsDisabled from "./useIsDisabled"

const tempId = () => -Math.floor(1000 + Math.random() * 9000)

const Pillars = ['filtration', 'ventilation', 'humidity', 'temperature']
const pillars = keys => keys.filter(x => Pillars.includes(x))

const findPillar = (trigger: AutomationTrigger) =>
    pillars(Object.keys(trigger)).filter(x => trigger[x] === true).join(' ')

interface RuleProps {
    automationId: number
    loading: boolean
    trigger: AutomationTrigger
    inputs: AutomationInput[]
    schedules: AutomationSchedule[]
    equipment: Equipment[]
    save: SaveAutomation
    refetch: any
}

export type GenericSetter = React.Dispatch<React.SetStateAction<any>>
export type InputSetter = React.Dispatch<React.SetStateAction<AutomationInput[]>>
export type ScheduleSetter = React.Dispatch<React.SetStateAction<AutomationSchedule[]>>

const ruleStyleProps = {
    flex: [
        '1 1 calc(100% - 16px)',
        '1 1 calc(100% - 16px)',
        '1 1 calc(50% - 16px)',
        '1 1 calc(33.333% - 16px)',
    ]
}

const Rule = ({
    automationId,
    loading,
    trigger,
    inputs,
    schedules,
    equipment,
    save,
    refetch,
}: RuleProps) => {
    const pillar = findPillar(trigger)
    const isSchedule = Boolean(schedules.length)
    const name = isSchedule ? 'Circulation schedule' : capitalize(pillar)

    const [inputUpdates, setInputUpdates] = useState(inputs)
    const [scheduleUpdates, setScheduleUpdates] = useState(schedules)
    const [touched, setTouched] = useState(false)

    useEffect(() => {
        if (!Object.values(inputs).length) return

        /**
         * diff the local state with the incoming state
         * that came from the network
         */
        setTouched(JSON.stringify(inputs) !== JSON.stringify(inputUpdates))
    }, [JSON.stringify(inputs), JSON.stringify(inputUpdates)])

    useEffect(() => {
        if (!Object.values(schedules).length) return

        const _schedules = schedules.map(x => Object.keys(x).reduce((prev, curr) => {
            if (curr === 'currently_triggered') return prev
            return {
                [curr]: x[curr],
                ...prev
            }
        }, {}))

        const _updates = scheduleUpdates.map(x => Object.keys(x).reduce((prev, curr) => {
            if (curr === 'currently_triggered') return prev
            return {
                [curr]: x[curr],
                ...prev
            }
        }, {}))

        setTouched(JSON.stringify(_schedules) !== JSON.stringify(_updates))
    }, [JSON.stringify(schedules), JSON.stringify(scheduleUpdates)])

    const muted = useMuted()
    const disabled = useIsDisabled(automationId)
    const disabledColor = useDisabled()
    const subtitleProps = { ...(disabled ? { color: disabledColor } : {}) }
    const disabledGreen = useColorModeValue('green.100', 'green.800')

    const _save = async () => save(prev => {
        let newInputId = tempId()

        const newInput = [...inputUpdates, ...scheduleUpdates].find((updated) => {
            const previous = prev.inputs.find(y => updated.id === y.id)
            return (JSON.stringify(updated) !== JSON.stringify(previous))
        })

        if (!newInput) return prev

        // create a new input by assigning it a negative ID
        const newInputs = [...prev.inputs, { ...newInput, id: newInputId }]

        const newRule = {
            ...trigger,
            id: tempId(),
            inputs: [
                ...(prev.rules.find(x => x.id === trigger.id)?.inputs?.filter(x => x !== newInput.id) || []),
                newInputId,
            ],
        }

        // create a new rule with new input
        // and disable the old rule
        const newRules = [
            ...prev.rules.map(x => {
                if (x.id === trigger.id) return { ...x, enabled: false }
                return x
            }),
            newRule,
        ]

        return {
            ...prev,
            inputs: newInputs,
            rules: newRules,
        }
    })

    return (
        <Card
            boxSizing='border-box'
            width={'100%'}
            {...ruleStyleProps}
        >
            <VStack
                spacing={8}
                alignItems='flex-start'
            >
                <HStack
                    px={6}
                    pt={6}
                    width='100%'
                    alignItems='flex-start'
                    justifyContent='space-between'>
                    <Header>
                        <Supertitle color={disabled ? disabledGreen : 'green.500'}>
                            Rule
                        </Supertitle>
                        <Subtitle {...subtitleProps}>
                            {trigger.id} <Box as='span' fontWeight='normal' fontSize='md' color={muted}>({name})</Box>
                        </Subtitle>
                    </Header>
                </HStack>
                {inputs.map(input => (
                    <Box
                        px={6}
                        w='100%'
                        key={input.id}
                    >
                        <InputControl
                            automationId={automationId}
                            input={input}
                            loading={loading}
                            set={setInputUpdates}
                        />
                    </Box>
                ))}
                {schedules.map(schedule => (
                    <Schedule
                        key={schedule.id}
                        automationId={automationId}
                        schedule={schedule}
                        loading={loading}
                        set={setScheduleUpdates}
                    />
                ))}
                <VStack
                    px={6}
                    spacing={2}
                    w='100%'
                    alignItems='flex-start'>
                    <HStack
                        w='100%'
                        alignItems='center'
                        justifyContent='space-between'
                    >
                        <FormLabel>Equipment</FormLabel>
                        {!isSchedule && (
                            <Override
                                automationId={automationId}
                                loading={loading}
                                save={save}
                                refetch={refetch}
                                pillar={pillar}
                            />
                        )}
                    </HStack>
                    {equipment.map(equipment =>
                        <VStack alignItems='flex-start' spacing={0} key={equipment.id}>
                            <TextLink
                                isDisabled={loading || disabled}
                                as={Link}
                                to={`/fleet/equipments/${equipment.id}`}>{equipment.name}</TextLink>
                            <OutputDot equipmentId={equipment.id} />
                        </VStack>
                    )}
                </VStack>
                <Button
                    mr={6}
                    mb={6}
                    isLoading={loading}
                    isDisabled={!touched}
                    colorScheme='blue'
                    onClick={_save}
                    size='sm'
                    alignSelf='flex-end'>
                    Save
                </Button>
            </VStack>
        </Card>
    )
}

export {
    ruleStyleProps,
}

export default Rule
