import { AddressInput, DateTimeInput, StringInput } from "components/inputs";
import { FormGroup, UpdateAction } from "components/form";
import React, { useState } from "react";

import { useForm } from "react-hook-form";
import { usePost } from "api/api-hooks";

export function DwellingDetails(props) {
    const [details, setDetails] = useState(props.details);
    const { call } = usePost(`dwelling/${details.id}`);

    let form = useForm({
        defaultValues: {
            ...details,
            create_timestamp: new Date(details.create_timestamp),
        },
    });
    let {
        register,
        handleSubmit,
        reset,
        control,
        formState: { errors },
    } = form;

    function handleReset() {
        reset({ ...details });
    }

    function onSubmit(data) {
        delete data.preferred_service_company_id;
        call(data, () => {
            setDetails(data);
        });
    }

    return (
        <>
            <FormGroup>
                <StringInput form={form} field="id" disabled={true}></StringInput>
                <StringInput form={form} field="name"></StringInput>
                <DateTimeInput form={form} field={"create_timestamp"} disabled={true}></DateTimeInput>
                <StringInput form={form} field="time_zone" disabled={true}></StringInput>
                <AddressInput form={form}></AddressInput>
            </FormGroup>

            <UpdateAction onReset={handleReset} onUpdate={handleSubmit(onSubmit)}></UpdateAction>
        </>
    );
}
