import { BooleanStatus, Status, ValueStatus } from "components/inputs";
import { HStack, Heading, Image, Text, VStack } from "@chakra-ui/react";

import React from "react";
import { fieldToLabel } from "utils/text-transform";

export function DeviceHardwareState({ data }) {
    function otaStatus(otaValue) {
        if (otaValue === "up_to_date") {
            return Status.GOOD;
        } else if (otaValue === "need_update") {
            return Status.WARNING;
        } else if (otaValue === "updating") {
            return Status.BAD;
        }
    }

    return (
        <VStack align="stretch">
            <Heading size="md">Hardware States</Heading>

            {data == null && (
                <HStack>
                    <Image src="/assets/cross.png" height="1.4rem" />
                    <Text color="#F96C6C">No data</Text>
                </HStack>
            )}
            {data != null && (
                <>
                    <ValueStatus label="OTA State" value={fieldToLabel(data?.ota_state)} status={otaStatus(data?.ota_state)}></ValueStatus>
                    <BooleanStatus label="Telemetry received" isOk={data?.telemetry_received}></BooleanStatus>
                    <BooleanStatus label="Humidity" isOk={data?.humidity_ok}></BooleanStatus>
                    <BooleanStatus label="Temperature" isOk={data?.temperature_ok}></BooleanStatus>
                    <BooleanStatus label="VOC" isOk={data?.voc_ok}></BooleanStatus>
                    <BooleanStatus label="BME" isOk={data?.bme_ok}></BooleanStatus>
                    <BooleanStatus label="Faceplate" isOk={data?.faceplate_ok}></BooleanStatus>
                    <BooleanStatus label="PM" isOk={data?.pm_ok}></BooleanStatus>
                    <BooleanStatus label="Airflow" isOk={data?.airflow_ok}></BooleanStatus>
                </>
            )}
        </VStack>
    );
}
