/**
 * Format the field from the server in the snake format device_type to more readable label format.
 * For example, device_type -> Device type
 */
export function fieldToLabel(field) {
    if (typeof field !== "string") {
        return field;
    }
    // Replace underscores with spaces
    field = field.replace(/_/g, " ");
    field = capitalize(field);
    return field;
}

/**
 * Capitilize the first letter in the string.
 */
export function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * Capitilize all letters in the string.
 */
export function capitalizeAll(string) {
    return string.toUpperCase();
}
