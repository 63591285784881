import { Box, Button, Center, HStack, Spinner, VStack } from "@chakra-ui/react";
import { DwellingSearch, UserSearch } from "./entity-search";
import React, { useEffect, useState } from "react";
import { getDwellingPermissions, getUserPermissions } from "api/api";
import { useDelete, usePost } from "api/api-hooks";

import AgGridStyled from "./ag-grid-styled";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { HavenGreenButton } from "components/buttons";

/**
 * UI for vieweing and editing permissions
 */
export default function Permissions(props) {
    const { id, parentEntity, refreshFlag } = props;
    const [permissions, setPermissions] = useState(props.permissions);

    useEffect(() => {
        setPermissions(props.permissions);
    }, [refreshFlag]);

    useEffect(() => {
        refresh();
    }, []);

    async function refresh() {
        if (parentEntity === "dwelling") {
            setPermissions(await getDwellingPermissions(id));
        } else if (parentEntity === "user") {
            setPermissions(await getUserPermissions(id));
        }
    }

    return (
        <VStack alignItems="flex-start" h={"100%"}>
            <AddNewPermission
                dwellingId={parentEntity === "dwelling" ? id : null}
                userId={parentEntity === "user" ? id : null}
                onAdd={refresh}
            ></AddNewPermission>
            <PermissionsTable
                permissions={permissions}
                dwellingId={parentEntity === "dwelling" ? id : null}
                userId={parentEntity === "user" ? id : null}
                onRemove={refresh}
            ></PermissionsTable>
        </VStack>
    );
}

function PermissionsTable({ permissions, userId, dwellingId, onRemove }) {
    const [rows, setRows] = useState([]);

    function handleCellClicked(event) {
        // clicking only works when user clicks explicitly on the name, otherwise when checkbox is missed the user is unexpectedly redirected
        if (event.column.colId !== "name") {
            return;
        }

        const id = event.data.id;
        if (userId) {
            window.open(`/fleet/dwellings/${id}`, "_blank").focus();
        } else if (dwellingId) {
            window.open(`/fleet/users/${id}`, "_blank").focus();
        }
    }

    function CheckboxRenderer(props) {
        const [value, setValue] = useState(props.value === true);
        const { call: addPermission, loading: adding } = usePost("dwelling_permission");
        const { call: removePermission, loading: removing } = useDelete("dwelling_permission");

        const handleClick = event => {
            const column = props.column.colId;
            setValue(event.target.checked);

            let permissionNumber;
            if (column === "admin") {
                permissionNumber = 0;
            } else if (column === "collaborator") {
                permissionNumber = 1;
            } else if (column === "viewer") {
                permissionNumber = 2;
            } else if (column === "analyst") {
                permissionNumber = 3;
            }

            let permission = {};
            if (userId) {
                permission = {
                    user_id: userId,
                    dwelling_id: props.data.id,
                    permission: permissionNumber,
                };
            }
            if (dwellingId) {
                permission = {
                    user_id: props.data.id,
                    dwelling_id: dwellingId,
                    permission: permissionNumber,
                };
            }

            // Add or remove permission depending on whether the checkbox is checked or not. If an error happens during the call revert the checkbox value back.
            if (event.target.checked) {
                addPermission(
                    permission,
                    () => setValue(true), // Update value in case of success
                    () => setValue(false) // Reverse the value in case of failure
                );
            } else {
                removePermission(
                    permission,
                    () => {
                        // Update value in case of success
                        setValue(false);
                        // When removed, call the parent function to refresh the data, this is needed to remove the empty rows with no roles
                        // TODO: figure out a more efficient way to do this not to call the server every time.
                        onRemove();
                    },
                    () => setValue(true) // Reverse the value in case of failure
                );
            }
        };

        return (
            <>
                {(adding || removing) && <Spinner size="sm" mt={3}></Spinner>}
                {!(adding || removing) && (
                    <Center h="100%">
                        <input type="checkbox" onChange={handleClick} checked={value === true} />
                    </Center>
                )}
            </>
        );
    }

    var columnDefs = [
        {
            headerName: userId ? "Dwelling name" : "User email",
            field: "name",
            suppressMenu: true,
            cellRenderer: "externalLinkRenderer",
        },
        {
            headerName: "Admin",
            field: "admin",
            headerTooltip: "Admin",
            cellRenderer: "checkboxRenderer",
            maxWidth: 65,
            suppressMenu: true,
        },
        {
            headerName: "Collaborator",
            field: "collaborator",
            headerTooltip: "Collaborator",
            cellRenderer: "checkboxRenderer",
            maxWidth: 65,
            suppressMenu: true,
        },
        {
            headerName: "Viewer",
            field: "viewer",
            headerTooltip: "Viewer",
            cellRenderer: "checkboxRenderer",
            maxWidth: 65,
            suppressMenu: true,
        },
        {
            headerName: "Analyst",
            field: "analyst",
            headerTooltip: "Analyst",
            cellRenderer: "checkboxRenderer",
            maxWidth: 65,
            suppressMenu: true,
        },
    ];

    useEffect(() => {
        if (userId) {
            setRows(userPermissionsToTableRows(permissions));
        } else if (dwellingId) {
            setRows(dwellingPermissionsToTableRows(permissions));
        }
    }, [permissions]);

    useEffect(() => {
        if (userId) {
            setRows(userPermissionsToTableRows(permissions));
        } else if (dwellingId) {
            setRows(dwellingPermissionsToTableRows(permissions));
        }
    }, []);

    /**
     * Add the external link icon and a pointer cursor to indicate that clicking this cell opens a new tab
     */
    function ExternalLinkRenderer(props) {
        return (
            <Box cursor={"pointer"}>
                {props.value}
                <ExternalLinkIcon mb={0.9} ml={1}></ExternalLinkIcon>
            </Box>
        );
    }

    return (
        <>
            {rows.length > 0 && (
                <AgGridStyled
                    gridParams={{
                        frameworkComponents: { checkboxRenderer: CheckboxRenderer, externalLinkRenderer: ExternalLinkRenderer },
                        rowData: rows,
                        columnDefs: columnDefs,
                        onCellClicked: handleCellClicked,
                    }}
                    className="ag-grid-roles"
                ></AgGridStyled>
            )}
        </>
    );
}

function userPermissionsToTableRows(permissions) {
    let rows = [];
    for (const { id, name, permission } of permissions) {
        if (!rows.some(row => row.id === id)) {
            // add row if it doesn't exist yet
            rows.push({ id, name, admin: false, collaborator: false, viewer: false, analyst: false });
        }

        rows = rows.map(row => {
            if (row.id === id) {
                if (permission === 0) return { ...row, admin: true };
                if (permission === 1) return { ...row, collaborator: true };
                if (permission === 2) return { ...row, viewer: true };
                if (permission === 3) return { ...row, analyst: true };
            }
            return row;
        });
    }

    return rows;
}

function dwellingPermissionsToTableRows(permissions) {
    let rows = [];
    for (const { user_id, email, permission } of permissions) {
        if (!rows.some(row => row.id === user_id)) {
            // add row if it doesn't exist yet
            rows.push({ id: user_id, name: email, admin: false, collaborator: false, viewer: false, analyst: false });
        }

        rows = rows.map(row => {
            if (row.id === user_id) {
                if (permission === 0) return { ...row, admin: true };
                if (permission === 1) return { ...row, collaborator: true };
                if (permission === 2) return { ...row, viewer: true };
                if (permission === 3) return { ...row, analyst: true };
            }
            return row;
        });
    }

    return rows;
}

function AddNewPermission(props) {
    const { dwellingId, userId, onAdd } = props;
    return (
        <>
            {userId && <AddDwelling onAdd={onAdd} userId={userId}></AddDwelling>}
            {dwellingId && <AddUser onAdd={onAdd} dwellingId={dwellingId}></AddUser>}
        </>
    );
}

function AddDwelling(props) {
    const { userId, onAdd } = props;
    const [selectedDwellingId, setSelectedDwellingId] = useState(null);
    const { call } = usePost("dwelling_permission");

    function onSelectDwelling(id) {
        setSelectedDwellingId(id);
    }

    async function onAddDwelling() {
        var body = {
            user_id: userId,
            dwelling_id: selectedDwellingId,
            permission: 3,
        };
        call(body, onAdd);
    }

    return (
        <HStack>
            <DwellingSearch onChange={onSelectDwelling}></DwellingSearch>
            <HavenGreenButton label="Add" onClick={onAddDwelling} disabled={selectedDwellingId == null}></HavenGreenButton>
        </HStack>
    );
}

function AddUser(props) {
    const { dwellingId, onAdd } = props;
    const [selectedUserId, setSelectedUserId] = useState(null);
    const { call } = usePost("dwelling_permission");

    function onSelectUser(id) {
        setSelectedUserId(id);
    }

    async function onAddUser() {
        var body = {
            user_id: selectedUserId,
            dwelling_id: dwellingId,
            permission: 3,
        };
        call(body, onAdd);
    }

    return (
        <HStack>
            <UserSearch onChange={onSelectUser}></UserSearch>
            <Button onClick={onAddUser} disabled={selectedUserId == null}>
                Add
            </Button>
        </HStack>
    );
}
