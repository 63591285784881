import { AddressInput, EmailInput, PhoneInput, SelectInput, StringInput } from "components/inputs";
import { FormGroup, UpdateAction } from "components/form";
import React, { useState } from "react";

import { useForm } from "react-hook-form";
import { usePost } from "api/api-hooks";
import { da } from "date-fns/locale";
import { set } from "date-fns";

export function CompanyDetails(props) {
    const [details, setDetails] = useState(props.details);
    const [updatedDetails, setUpdatedDetails] = useState([]);
    const { call } = usePost(`service_company/${details.id}`);

    const form = useForm({ defaultValues: { ...details } });
    const { handleSubmit, reset } = form;

    function handleReset() {
        reset({ ...details });
        setUpdatedDetails([]);
    }

    function changeField(data) {
        if (updatedDetails.includes(data.target.id)) {
            return
        }
        setUpdatedDetails(oldDetails => [...oldDetails, data.target.id]);

    }
    function onSubmit(data) {
        if (updatedDetails.length === 0) {
            return
        }
        // not udpating copper values until integration is working properly
        const filteredData =
            Object.keys(data).filter(key => updatedDetails.includes(key)).reduce((obj, key) => {

                return { ...obj, [key]: data[key] }
            }, {});


        call(filteredData, () => {
            setDetails(data);
        });
        setUpdatedDetails([]);
    }

    return (
        <>
            <FormGroup>
                <StringInput form={form} field="id" disabled={true} ></StringInput>
                <StringInput form={form} field="name" onChange={changeField}></StringInput>
                <EmailInput form={form} onChange={changeField}></EmailInput>
                <PhoneInput form={form} onChange={changeField}></PhoneInput>
                <StringInput form={form} field="website" onChange={changeField}></StringInput>
                <SelectInput
                    form={form}
                    field="type"
                    options={[
                        "hvac_service_company",
                        "non_hvac_service_company",
                        "test",
                        "duplicate",
                        "demo",
                        "distributor",
                        "manufacturer",
                        "educational",
                        "commericial_building_owner",
                        "other",
                        "unknown",
                    ]}
                    onChange={changeField}
                ></SelectInput>
                <SelectInput form={form} field="status" options={["active", "inactive", "invalid"]} onChange={changeField}></SelectInput>
                <AddressInput form={form} onChange={changeField}></AddressInput>
                <StringInput form={form} field="time_zone" disabled={true} onChange={changeField}></StringInput>
            </FormGroup>

            <UpdateAction onReset={handleReset} onUpdate={handleSubmit(onSubmit)}></UpdateAction>
        </>
    );
}
