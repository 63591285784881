import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import "ag-grid-community/dist/styles/ag-theme-balham-dark.css";

import { AgGridColumn, AgGridReact } from "ag-grid-react";

import React from "react";
import { fieldToLabel } from "utils/text-transform";
import { getList } from "api/api";
import { useColorMode } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

/**
 * Component used to render the tables of entities under the Fleet tab. Generic enough to handle pagination, filtering, ordering, etc.
 */
export default function EntitiesTable({ url, getUrl, columns }) {
    // const navigate = useNavigate();
    const { colorMode } = useColorMode();

    // Go to entity details when clicking the row
    function onRowClicked(event) {
        if (event?.data?.id) {
            window.open(`/fleet/${url}/${event.data.id}`);
        }
    }

    const onGridReady = params => {
        function updateData() {
            var dataSource = {
                rowCount: null,
                getRows: params => {
                    // apply pagination parameters (infinite scrolling model)
                    var offset = params.startRow;
                    var limit = params.endRow - params.startRow;

                    // apply filtering
                    const entries = Object.entries(params.filterModel);
                    let filter = "";
                    for (let e of entries) {
                        const field = e[0];
                        if (e[1].filterType === "date") {
                            const valueFrom = e[1].dateFrom;
                            const valueTo = e[1].dateTo;
                            filter += `&${field}_start=${valueFrom}&${field}_end=${valueTo}`;
                        } else {
                            const value = e[1].filter;
                            filter += `&${field}=${value}`;
                        }
                    }

                    // apply ordering
                    let orderString = "id"; // default is ordering by id, ascending
                    if (params.sortModel[0]) {
                        if (params.sortModel[0].sort === "desc") {
                            orderString = "~";
                        } else {
                            orderString = "";
                        }
                        orderString += params.sortModel[0].colId;
                    }

                    // fetch data
                    getList(getUrl, filter, limit, offset, orderString).then(data => {
                        // Add a new JSON field (address) for dwellings and companies objects
                        if ((url === "dwellings") || (url === "companies")) {
                            data.forEach(element => {

                                element["address"] = (element.street_1 || "") + (element.street_2 ? " " : "") +
                                    (element.street_2 || "") + ((element.street_1 || element.street_2) ? ", " : "") +
                                    (element.city || "") + (element.city ? ", " : "") +
                                    (element.province || "") + (element.province ? ", " : "") +
                                    (element.postal_code || "") + (element.postal_code ? ", " : "") +
                                    (element.country || "")
                            });

                        }

                        // if the amount of rows retrieved is fewer than requested, continous scrolling is finished, calculate the actual last row number
                        var lastRow = -1; // -1 means continous scrolling has not finished yet
                        if (data.length < limit) {
                            var expected = limit;
                            var actual = data.length;
                            lastRow = params.endRow - (expected - actual);
                        }
                        params.successCallback(data, lastRow);
                    });
                },
            };

            params.api.setDatasource(dataSource);
        }

        updateData();
    };

    const customFilterParams = {
        filterOptions: ["contains"],
        suppressAndOrCondition: true,
        floatingFilter: true,
    };

    const dateFilterParams = {
        filterOptions: ["inRange"],
        suppressAndOrCondition: true,
        floatingFilter: true,
    };

    const idFilterParams = {
        filterOptions: ["equals"],
        suppressAndOrCondition: true,
        floatingFilter: true,
        allowedCharPattern: "\\d\\\\", // this looks bad because of escaping '\' symbol, but it means allow numbers only
        numberParser: text => {
            return text == null ? null : parseFloat(text.replace(",", ".").replace("$", ""));
        },
    };

    return (
        <div
            style={{ width: "100%", height: "100%" }}
        >
            <div
                id="myGrid"
                style={{
                    height: "100%",
                    width: "100%",
                }}
                className={colorMode === "light" ? "ag-theme-balham" : "ag-theme-balham-dark"}
            >
                <AgGridReact
                    defaultColDef={{
                        flex: 1,
                        resizable: true,
                        minWidth: 100,
                        sortable: true,
                    }}
                    components={{
                        loadingRenderer: function (params) {
                            if (params.value !== undefined) {
                                return params.value;
                            } else {
                                return "...";
                            }
                        },
                    }}
                    rowBuffer={0}
                    rowSelection={"multiple"}
                    rowModelType={"infinite"}
                    cacheBlockSize={15}
                    cacheOverflowSize={2}
                    maxConcurrentDatasourceRequests={1}
                    infiniteInitialRowCount={100}
                    maxBlocksInCache={10}
                    onGridReady={onGridReady}
                    onRowClicked={onRowClicked}
                >
                    <AgGridColumn
                        field="id"
                        filter="agNumberColumnFilter"
                        floatingFilter={true}
                        filterParams={idFilterParams}
                        maxWidth={100}
                        cellRenderer="loadingRenderer"
                    />
                    {columns.map(column => {
                        return (
                            <AgGridColumn
                                key={column}
                                field={column}
                                headerName={fieldToLabel(column)}
                                filter={
                                    column === "install_timestamp" || column === "create_timestamp" ? "agDateColumnFilter" : "agTextColumnFilter"
                                }
                                floatingFilter={true}
                                filterParams={
                                    column === "install_timestamp" || column === "create_timestamp" ? dateFilterParams : customFilterParams
                                }
                                sortable={(((url === "dwellings") || (url === "companies")) && (column === "address")) ? false : true}
                            ></AgGridColumn>
                        );
                    })}
                </AgGridReact>
            </div>
        </div>
    );
}
