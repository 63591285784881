import { Box, HStack, Heading, Image, Spinner, VStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { addDeviceTag, getDeviceTags, removeDeviceTag } from "api/api";

import Controller from "./controller";
import Monitor from "./monitor";
import Tagging from "components/tagging";

export function Device({ device }) {
    const [deviceType, setDeviceType] = useState(null);

    // Device type will determine what we are rendering on the page
    useEffect(() => {
        if (device?.details?.data?.type === "cam") {
            setDeviceType("cam");
        } else if (device?.details?.data?.type === "cac") {
            setDeviceType("cac");
        }
    }, [device]);

    if (device.loading || !device.details.data) {
        return <Spinner m={5}></Spinner>;
    }

    return (
        <VStack alignItems="flex-start" h="100%" ml={3}>
            <HStack mt={2} align="flex-start">
                <Heading fontSize="1.5rem" color="#9BB3BF">
                    Device:
                </Heading>
                {deviceType === "cac" && <Heading fontSize="1.5rem">Central Air Controller</Heading>}

                {deviceType === "cam" && (
                    <>
                        <Heading fontSize="1.5rem">Central Air Monitor</Heading>
                        <Image pl={3} src="/assets/monitor.png" pt={1} w="3.5rem"></Image>
                    </>
                )}
            </HStack>

            <Tagging
                id={device.details.data.id}
                getEntityTags={getDeviceTags}
                removeEntityTag={removeDeviceTag}
                addEntityTag={addDeviceTag}
            ></Tagging>
            <Box
                display="flex"
                flexWrap="wrap"
                // overflow="scroll"
                // pr={3}
                // pt={0}
                // pb={5}
                w="100%">
                {deviceType === "cam" && <Monitor monitor={device}></Monitor>}
                {deviceType === "cac" && <Controller controller={device}></Controller>}
            </Box>
        </VStack>
    );
}
