import ReactFlow,
{
    Controls,
    Background,
    Node,
    Edge,
    Panel,
    useNodesState,
    useEdgesState,
    useReactFlow,
    useNodesInitialized,
    useOnSelectionChange,
    getOutgoers
} from 'reactflow';
import Dagre from '@dagrejs/dagre'
import { useColorModeValue, Box } from '@chakra-ui/react';
import { useMemo, useState, useCallback, useEffect } from 'react';
import { RelationshipNode, RelationshipItemData } from './relationship-node';
import useAutomationsStore, { selectAutomation } from "./automationsStore"
import { AutomationInput, AutomationOutput } from "types"
import { fieldToLabel, capitalize } from "utils/text-transform"
import 'reactflow/dist/style.css';


const dagreGraph = new Dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}))

const getLayoutedElements = (nodes, edges, options) => {
    try {
        dagreGraph.setGraph({ rankdir: options.direction, ranker: 'longest-path' });
        edges.forEach((edge) => dagreGraph.setEdge(edge.source, edge.target));
        nodes.forEach((node) => dagreGraph.setNode(node.id, node));

        Dagre.layout(dagreGraph);

        return {
            nodes: nodes.map((node) => {
                const { x, y } = dagreGraph.node(node.id);

                return { ...node, position: { x, y } };
            }),
            edges,
        };
    } catch (e) {
        console.log("getLayoutedElements:", e)
        return { nodes: [], edges: [] }
    }
}



export function RelationshipFlow({ automationId, devices, equipment, showDisabled = false }) {
    const automation = useAutomationsStore(selectAutomation(automationId))
    const borderColor = useColorModeValue('gray.200', 'gray.600')
    const [inputsInUse, setInputsInUse] = useState<Number[]>([])
    const [outputsInUse, setOutputsInUse] = useState<Number[]>([])

    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);


    useOnSelectionChange({
        onChange: ({ nodes: selected_nodes, edges: selected_edges }) => {
            if (selected_nodes.length === 1) {
                const node = nodes.find(x => x.id === selected_nodes[0].id)
                if (node?.type === 'relationshipItem') {
                    const item = node.data.item
                }
            }
        }
    })
    const generateNodes = useMemo(() => {
        try {
            const { inputs, rules, outputs, interlocks } = automation
            let nodes: Node<RelationshipItemData>[] = []
            nodes = inputs.filter(input => (showDisabled || inputsInUse.includes(input.id))).map((input): Node<RelationshipItemData> => {
                const device = devices?.data?.find(x => {
                    if (x.id === (input as AutomationInput).device_id) {
                        return x.id
                    }
                    return null
                })
                let input_params = { ...input }
                if (device != null) {
                    input_params = { ...input_params, ...{ hub_device_id: device.hub_device_id, plastic_serial_number: device.plastic_serial_number } }
                }

                return ({
                    id: `input-${input.id}-node`,
                    type: 'relationshipItem',
                    data: { type: 'input', item: input_params },
                    position: { x: 0, y: 0 },
                    draggable: false
                })
            })


            nodes = [...nodes, ...rules.filter((rule) => (rule.enabled === true || showDisabled)).map<Node<RelationshipItemData>>((rule): Node<RelationshipItemData> => {
                const sources = rule.inputs.map(input => `input-${input}-node`)
                const targets = rule.outputs.map(output => `output-${output}-node`)

                return ({
                    id: `rule-${rule.id}-node`,
                    type: 'relationshipItem',
                    data: { type: 'rule', item: rule, sources: sources, targets: targets },
                    position: { x: 0, y: 0 },
                    draggable: false
                })
            })]
            nodes = [...nodes, ...outputs.filter(output => (showDisabled || outputsInUse.includes(output.id))).map((output): Node<RelationshipItemData> => {
                const device = devices.data.find(x => {
                    if (x.id === (output as AutomationOutput).device_id) {
                        return x.id
                    }
                    return null
                })
                const equip = equipment.find(x => {
                    if (x.id === (output as AutomationOutput).equipment_id) {
                        return x.id
                    }
                    return null
                })
                let output_params = { ...output }
                let interlockTargets: string[] = []
                let interlockSources: string[] = []
                if (device != null) {
                    output_params = { ...output_params, ...{ hub_device_id: device.hub_device_id, plastic_serial_number: device.plastic_serial_number } }
                }
                if (equip != null) {
                    output_params = { ...output_params, ...{ equipment_type: fieldToLabel(equip.type), equipment_model: fieldToLabel(equip.model), equipment_brand: fieldToLabel(equip.brand) } }
                }
                if (interlocks) {
                    interlockTargets = interlocks.filter(interlock => interlock.primary_output === output.id).map(interlock => `output-${interlock.secondary_output}-node`)
                    interlockSources = interlocks.filter(interlock => interlock.secondary_output === output.id).map(interlock => `output-${interlock.primary_output}-node`)

                }
                return ({
                    id: `output-${output.id}-node`,
                    type: 'relationshipItem',
                    data: { type: 'output', item: output_params, interlockTargets: interlockTargets, interlockSources: interlockSources },
                    position: { x: 0, y: 0 },
                    draggable: false,
                })
            })]

            nodes = [...nodes, ...interlocks.filter(interlock => outputsInUse.includes(interlock.primary_output) && outputsInUse.includes(interlock.secondary_output)).map((interlock): Node<RelationshipItemData> => {
                const sources = ['output-' + interlock.primary_output + '-node', 'output-' + interlock.secondary_output + '-node']
                return ({
                    id: `interlock-${interlock.id}-node`,
                    type: 'relationshipItem',
                    data: { type: 'interlock', item: interlock, sources: sources, targets: [] },
                    position: { x: 0, y: 0 },
                    draggable: false
                })
            })]





            setNodes(nodes)
        }
        catch (e) {
            console.log("generateNodes:", e)
        }
    }, [automation, inputsInUse, outputsInUse, equipment, showDisabled])
    const generateEdges = useMemo(() => {
        try {

            let edges: Edge[] = []
            nodes.map(node => node.data).forEach(rule => {
                rule?.sources?.forEach(source => {
                    const target = rule.type === 'interlock' ? `interlock-${rule.item.id}-node` : `rule-${rule.item.id}-node`
                    edges = [...edges, { id: `edge-${source}-${rule.item.id}`, source: source, target: target, animated: rule.type === 'interlock' }]

                })
                rule?.targets?.forEach(target => {

                    edges = [...edges, { id: `edge-${rule.item.id}-${target}`, source: `rule-${rule.item.id}-node`, target: target }]

                })


            })

            setEdges(edges)
        }
        catch (e) {
            console.log("generateEdges:", e)
            return []
        }
    }, [nodes])

    const {
        inputs,
        rules,
        outputs,
        interlocks,
    } = automation

    rules.forEach(rule => {
        rule.inputs.forEach(input => {
            if (rule.enabled && !inputsInUse.includes(input)) {
                setInputsInUse([...inputsInUse, input])
            }
        })
        rule.outputs.forEach(output => {
            if (rule.enabled && !outputsInUse.includes(output)) {
                setOutputsInUse([...outputsInUse, output])
            }
        })

    })
    interlocks.forEach(interlock => {
        if (!outputsInUse.includes(interlock.primary_output)) {
            setOutputsInUse([...outputsInUse, interlock.primary_output])
        }
        if (!outputsInUse.includes(interlock.secondary_output)) {
            setOutputsInUse([...outputsInUse, interlock.secondary_output])
        }
    })

    useEffect(() => {
        onLayout('TB')
    }, [nodes.length, edges.length])


    const { getNodes, getEdges, fitView } = useReactFlow()
    const nodesInitialized = useNodesInitialized()

    useEffect(() => {
        if (nodesInitialized) {
            onLayout('TB')
        }
    }, [nodesInitialized])

    const onLayout = useCallback(
        (direction) => {
            const layouted = getLayoutedElements(getNodes(), getEdges(), { direction });

            setNodes([...layouted.nodes]);
            setEdges([...layouted.edges]);
            fitView();


        },
        [nodes, edges]
    );
    const nodeTypes = useMemo(() => ({ 'relationshipItem': RelationshipNode }), [])
    if (nodes.length === 0) return null

    return (
        <ReactFlow
            nodeTypes={nodeTypes}
            nodes={nodes}
            edges={edges}
            style={{ background: 'none' }}
            preventScrolling={true}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
        >
            <Panel position='top-right'><button onClick={() => { onLayout('TB') }}><Box bg='grey'>Reset</Box></button></Panel>
            <Background id={`automation-${automationId}`} />
            <Controls />
        </ReactFlow>

    );
}
