import { HStack, Image, Text, VStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Status, ValueStatus } from "components/inputs";

import date from "date-and-time";

export function ControllerTelemetry({ currentTelemetry }) {
    return (
        <>
            {currentTelemetry && (
                <>
                    <CurrentTelemetry data={currentTelemetry}></CurrentTelemetry>
                </>
            )}
            {!currentTelemetry && (
                // TODO: used in mutiple places, refactor
                <HStack>
                    <Image src="/assets/cross.png" height="1.4rem" />
                    <Text color="#F96C6C">No telemetry found</Text>
                </HStack>
            )}
        </>
    );
}

function CurrentTelemetry({ data }) {
    const [telemetryStatus, setTelemetryStatus] = useState(null);
    const [telemetryStatusText, setTelemetryStatusText] = useState(null);
    const [telemetryTsLocal, setTelemetryTsLocal] = useState(null);
    const [telemetryTsUtc, setTelemetryTsUtc] = useState(null);

    useEffect(() => {
        if (data?.timestamp) {
            let ts = new Date(data?.timestamp);
            let now = new Date();
            const localTs = date.format(ts, "YYYY-MM-DD HH:mm:ss", false);
            const utcTs = date.format(ts, "YYYY-MM-DD HH:mm:ss", true);
            setTelemetryTsLocal(localTs);
            setTelemetryTsUtc(utcTs);

            let hoursDiff = (now.getTime() - ts.getTime()) / 1000 / 60 / 60;
            if (hoursDiff < 24) {
                setTelemetryStatus(Status.GOOD);
                setTelemetryStatusText("Today");
            } else if (hoursDiff < 24 * 14) {
                setTelemetryStatus(Status.WARNING);
                setTelemetryStatusText("Last 2 weeks");
            } else {
                setTelemetryStatus(Status.BAD);
                setTelemetryStatusText("No data in 2 weeks");
            }
        } else {
            setTelemetryStatus(Status.BAD);
            setTelemetryStatusText("No data in 2 weeks");
        }
    }, [data]);

    return (
        <VStack align="stretch">
            <ValueStatus
                label="Last active (Local)"
                value={telemetryStatusText}
                secondaryValue={telemetryTsLocal}
                status={telemetryStatus}
            ></ValueStatus>
            <ValueStatus
                label="Last active (UTC)"
                value={telemetryStatusText}
                secondaryValue={telemetryTsUtc}
                status={telemetryStatus}
            ></ValueStatus>
        </VStack>
    );
}
