import { HStack, VStack } from "@chakra-ui/react";
import { HavenGreenButton, HavenRedButton } from "components/buttons";

import React from "react";

/**
 * Form input used to display details for each entity. Field name on the left followed by the input that displays the value and allows to update it.
 */
export function FormInput(props) {
    return (
        <div className="form-input">
            <h3>{props.label}</h3>
            {props.children}
        </div>
    );
}

/**
 * Reset and Update buttons commonly used under forms.
 */
export function UpdateAction(props) {
    const { onReset, onUpdate } = props;

    return (
        <HStack mt={2} w="100%" justifyContent="space-between" alignItems="stretch">
            <HavenRedButton flex={1} label={"Undo"} onClick={onReset}></HavenRedButton>
            <HavenGreenButton flex={1} label={"Update"} onClick={onUpdate}></HavenGreenButton>
        </HStack>
    );
}

/**
 * Grouped form elements together.
 */
export function FormGroup(props) {
    return <VStack align="stretch">{props.children}</VStack>;
}
