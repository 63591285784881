// const particleRanges = {
//     pm25: {
//         ugm3: {
//             // good: [0, 11.7],
//             fair: [11.8, 33.3],
//             poor: [33.4],
//         },
//         countm3: {
//             // good: [0, 20.4],
//             fair: [20.5, 58],
//             poor: [59],
//         },
//     },
//     voc: {
//         ppb: {
//             // good: [0, 211],
//             fair: [212, 849],
//             poor: [850],
//         },
//         ugm3: {
//             // good: [0, 499],
//             fair: [500, 2000],
//             poor: [2001],
//         },
//     },
// }

const start_set_point = {
    voc: {
        ppb: {
            'fair': 212,
            'poor': 850,
        },
    },
    pm25: {
        ugm3: {
            'fair': 11.7,
            'poor': 33.2,
        },
    },
}

const stop_set_point = {
    voc: {
        ppb: {
            'fair': 200,
            'poor': 800,
        },
    },
    pm25: {
        ugm3: {
            'fair': 10,
            'poor': 30,
        },
    },
}

const findFactor = ({ measurement, unit }) => startPoint => {
    const startPoints = start_set_point[measurement][unit]
    return Object.keys(startPoints).reduce(( prev, curr ) => {
        if (startPoint >= startPoints[curr]) return curr
        return prev
    }, 'fair')
}

export {
    findFactor,
    start_set_point,
    stop_set_point,
}