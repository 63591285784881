import { HStack, Text, VStack } from "@chakra-ui/react"
import { useDisabled } from "pages/util"
import Subtitle from "./subtitle"

function EmptyState({ title }) {
    const color = useDisabled()

    return (
        <VStack
            py={8}
            flex={1}
            spacing={8}
            alignItems='flex-start'
            color={color}
        >
            <HStack
                w='100%'

            >
                <Subtitle>{title}</Subtitle>
            </HStack>
            <Text>
                This automation has not been configured.
            </Text>
        </VStack>
    )
}

export default EmptyState
