/**
 * Given the start and end timestamps, return a list of periods between them each with length periodMs (1 day by default). Useful when calling the server takes too long and the request has to be split into multiple subrequests.
 * For example, start: 2022-01-02 12:34, end: 2022-01-05 09:00 -> [2022-01-02 12:34, 2022-01-03 12:34, 2022-01-04 12:34, 2022-01-05 09:00]
 *
 */
export default function splitInterval(start, end, periodMs = 24 * 60 * 60 * 1000) {
    const timestamps = [];
    let currentTime = start;
    while (currentTime.getTime() < end.getTime()) {
        timestamps.push(currentTime);
        currentTime = new Date(currentTime.getTime() + periodMs);
    }
    timestamps.push(end);
    return timestamps;
}
