import { Box, Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Stack, Switch } from "@chakra-ui/react"
import FormLabel from "components/label"
import { useState } from "react"

const hour = () => new Date(new Date().setHours(new Date().getHours() + 1)).toISOString()
const day = () => new Date(new Date().setHours(new Date().getHours() + 24)).toISOString()

const OverrideModal = ({
    isOpen,
    onClose,
    stateKey,
    timeoutKey,
    state,
    save,
    title,
}) => {
    const [loading, setLoading] = useState(false)

    const [override, setOverride] = useState({
        [stateKey]: state,
        [timeoutKey]: hour(),
    })

    const onChangeOverrideTimeout = (e) => {
        const { value } = e.target
        setOverride(prev => ({ ...prev, [timeoutKey]: value, }))
    }

    const onChangeTimeoutState = (e) => {
        const { checked } = e.target
        setOverride(prev => ({ ...prev, [stateKey]: checked, }))
    }

    const onSubmit = async () => {
        setLoading(true)
        try {
            await save(override)
            onClose()
        } catch (e) {
            console.error(e)
        }
        setLoading(false)
    }

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    {title}
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Stack spacing={8}>
                        <Box
                            mt={4}
                            display='flex'
                            alignItems='center'
                            justifyContent='space-between'
                        >
                            <FormLabel>State</FormLabel>
                            <Switch
                                defaultChecked={state}
                                onChange={onChangeTimeoutState}
                            />
                        </Box>
                        <Box
                            display='flex'
                            alignItems='center'
                        >
                            <FormLabel width='160px'>For how long?</FormLabel>
                            <Select
                                disabled={loading}
                                onChange={onChangeOverrideTimeout}
                            >
                                <option value={hour()}>1 hour</option>
                                <option value={day()}>24 hours</option>
                            </Select>
                        </Box>
                    </Stack>
                </ModalBody>
                <ModalFooter>
                    <Button
                        size='sm'
                        colorScheme='blue'
                        isLoading={loading}
                        onClick={onSubmit}
                    >Override</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default OverrideModal
