import { useAuth0 } from "@auth0/auth0-react";

/**
 * This logout uses both the auth0 logout functionality, as well as clears the local and session storage to remove the token and userId that are manually set when authorizing.
 */
export function useLogout() {
    const { logout } = useAuth0();

    function fullLogout() {
        localStorage.removeItem("token");
        sessionStorage.removeItem("userId");
        logout({ returnTo: window.location.origin });
    }

    return { fullLogout };
}
