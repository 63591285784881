import { HStack, Input, Select, Tab, TabList, TabPanel, TabPanels, Tabs, VStack } from "@chakra-ui/react"
import FormLabel from 'components/label'
import Emphasis from "components/emphasis"
import { humanize } from "pages/util"
import { AutomationSchedule, ScheduleIntervals } from "types"
import { useForm } from "react-hook-form"
import InputLabel from "components/input-label"
import { ScheduleSetter } from "./rule"
import { useEffect } from "react"
import useIsDisabled from "./useIsDisabled"

const InputRow = props => (
    <VStack
        alignItems='flex-start'
        w='100%'
        spacing={0}
        {...props} />
)

const formatTime = time => `${time}:00`

const Schedule = ({ schedule, automationId, loading, set }: {
    schedule: AutomationSchedule
    automationId: number
    loading: boolean
    set: ScheduleSetter
}) => {

    const { register, watch, getValues } = useForm()
    const isDisabled = useIsDisabled(automationId)

    useEffect(() => {
        const setter = formData => (prevSchedules: AutomationSchedule[]) => {
            return prevSchedules.map(input => {
                if (input.id === schedule.id) {
                    return {
                        ...input,
                        ...formData,
                    }
                }
                return input
            })
        }

        const subscription = watch((formData, { name, type }) => {
            if (!name) return
            const _data = {
                [name]: name?.includes('time')
                    ? formatTime(formData[name])
                    : formData[name]
            }
            set(setter(_data))
        })


        return () => subscription.unsubscribe()
    }, [watch])

    const {
        weekday_awake_interval,
        weekday_sleep_interval,
        weekday_start_time,
        weekday_stop_time,
        weekend_awake_interval,
        weekend_sleep_interval,
        weekend_start_time,
        weekend_stop_time,
    } = schedule

    return (
        <Tabs as='form' w='100%'>
            <TabList w='100%'>
                <Tab px={6}>Weekday</Tab>
                <Tab>Weekend</Tab>
            </TabList>
            <TabPanels>
                <TabPanel px={6}>
                    <VStack
                        spacing={8}
                        alignItems='flex-start'
                        w='100%'>
                        <VStack
                            w='100%'
                            alignItems='flex-start' >
                            <Emphasis>Daytime 🌞</Emphasis>
                            <InputRow>
                                <InputLabel htmlFor='weekday_awake_interval'>
                                    Interval
                                </InputLabel>
                                <Select
                                    {...register('weekday_awake_interval', { required: true })}
                                    defaultValue={weekday_awake_interval}
                                    isDisabled={loading || isDisabled}
                                    id='weekday_awake_interval'>
                                    {Object.keys(ScheduleIntervals).map(interval => (
                                        <option key={interval} value={interval}>
                                            {humanize(interval)}
                                        </option>
                                    ))}
                                </Select>
                            </InputRow>
                            <HStack w='100%'>
                                <InputRow>
                                    <InputLabel htmlFor='weekday_start_time'>
                                        Start time
                                    </InputLabel>
                                    <Input
                                        {...register('weekday_start_time')}
                                        id='weekday_start_time'
                                        isDisabled={loading || isDisabled}
                                        cursor='pointer'
                                        defaultValue={weekday_start_time}
                                        type='time' />
                                </InputRow>
                                <InputRow>
                                    <InputLabel htmlFor='weekday_stop_time'>
                                        Stop time
                                    </InputLabel>
                                    <Input
                                        {...register('weekday_stop_time')}
                                        id='weekday_stop_time'
                                        isDisabled={loading || isDisabled}
                                        cursor='pointer'
                                        defaultValue={weekday_stop_time}
                                        type='time' />
                                </InputRow>
                            </HStack>
                        </VStack>
                        <VStack w='100%' alignItems='flex-start'>
                            <Emphasis>Nightime 🌝</Emphasis>
                            <InputRow>
                                <InputLabel htmlFor='weekday_sleep_interval'>
                                    Interval
                                </InputLabel>
                                <Select {...register('weekday_sleep_interval')}
                                    defaultValue={weekday_sleep_interval}
                                    isDisabled={loading || isDisabled}
                                    id='weekday_sleep_interval'>
                                    {Object.keys(ScheduleIntervals).map(interval => (
                                        <option key={interval} value={interval}>
                                            {humanize(interval)}
                                        </option>
                                    ))}
                                </Select>
                            </InputRow>
                            <HStack w='100%'>
                                <InputRow>
                                    <FormLabel htmlFor='weekday_sleep_stop_time'>
                                        Start time
                                    </FormLabel>
                                    <Input
                                        id='weekday_sleep_stop_time'
                                        isDisabled
                                        readOnly
                                        defaultValue={weekday_stop_time}
                                        value={getValues('weekday_stop_time')}
                                        type='time' />
                                </InputRow>
                                <InputRow>
                                    <FormLabel htmlFor='weekday_sleep_start_time'>
                                        Stop time
                                    </FormLabel>
                                    <Input
                                        id='weekday_sleep_start_time'
                                        isDisabled
                                        readOnly
                                        defaultValue={weekday_start_time}
                                        value={getValues('weekday_start_time')}
                                        type='time' />
                                </InputRow>
                            </HStack>
                        </VStack>
                    </VStack>
                </TabPanel>
                <TabPanel px={6}>
                    <VStack
                        spacing={8}
                        alignItems='flex-start'
                        w='100%'>
                        <VStack
                            w='100%'
                            alignItems='flex-start' >
                            <Emphasis>Daytime 🌞</Emphasis>
                            <InputRow>
                                <InputLabel htmlFor='weekend_awake_interval'>
                                    Interval
                                </InputLabel>
                                <Select {...register('weekend_awake_interval')}
                                    defaultValue={weekend_awake_interval}
                                    isDisabled={loading || isDisabled}
                                    id='weekend_awake_interval'>
                                    {Object.keys(ScheduleIntervals).map(interval => (
                                        <option key={interval} value={interval}>
                                            {humanize(interval)}
                                        </option>
                                    ))}
                                </Select>
                            </InputRow>
                            <HStack w='100%'>
                                <InputRow>
                                    <InputLabel htmlFor='weekend_start_time'>
                                        Start time
                                    </InputLabel>
                                    <Input
                                        {...register('weekend_start_time')}
                                        id='weekend_start_time'
                                        isDisabled={loading || isDisabled}
                                        cursor='pointer'
                                        defaultValue={weekend_start_time}
                                        type='time' />
                                </InputRow>
                                <InputRow>
                                    <InputLabel htmlFor='weekend_stop_time'>
                                        Stop time
                                    </InputLabel>
                                    <Input
                                        {...register('weekend_stop_time')}
                                        id='weekend_stop_time'
                                        isDisabled={loading || isDisabled}
                                        cursor='pointer'
                                        defaultValue={weekend_stop_time}
                                        type='time' />
                                </InputRow>
                            </HStack>
                        </VStack>
                        <VStack w='100%' alignItems='flex-start'>
                            <Emphasis>Nightime 🌝</Emphasis>
                            <InputRow>
                                <InputLabel htmlFor='weekend_sleep_interval'>
                                    Interval
                                </InputLabel>
                                <Select {...register('weekend_sleep_interval')}
                                    defaultValue={weekend_sleep_interval}
                                    isDisabled={loading || isDisabled}
                                    id='weekend_sleep_interval'>
                                    {Object.keys(ScheduleIntervals).map(interval => (
                                        <option key={interval} value={interval}>{humanize(interval)}</option>
                                    ))}
                                </Select>
                            </InputRow>
                            <HStack w='100%'>
                                <InputRow>
                                    <FormLabel htmlFor='weekend_sleep_stop_time'>
                                        Start time
                                    </FormLabel>
                                    <Input
                                        id='weekend_sleep_stop_time'
                                        isDisabled
                                        readOnly
                                        defaultValue={weekend_stop_time}
                                        value={getValues('weekend_stop_time')}
                                        type='time' />
                                </InputRow>
                                <InputRow>
                                    <FormLabel htmlFor='weekend_sleep_start_time'>
                                        Stop time
                                    </FormLabel>
                                    <Input
                                        id='weekend_sleep_start_time'
                                        isDisabled
                                        readOnly
                                        defaultValue={weekend_start_time}
                                        value={weekend_start_time}
                                        type='time' />
                                </InputRow>
                            </HStack>
                        </VStack>
                    </VStack>
                </TabPanel >
            </TabPanels>
        </Tabs>
    )
}

export default Schedule
