import {
    Radio,
    RadioGroup,
    Text,
    VStack,
} from "@chakra-ui/react"
import Emphasis from "components/emphasis"
import Muted from "components/muted"
import { parseMeasurement } from "pages/util"
import { useRef, useState } from "react"
import { AutomationInput } from "types"
import { useSkipFirstRender } from "./dwelling-automations"
import EmptyState from "./empty-state"
import { InputSetter } from "./rule"
import useIsDisabled from "./useIsDisabled"
import { findFactor, start_set_point, stop_set_point } from "./util"

const parseUnit = unit => {
    switch (unit) {
        case 'ugm3': return 'μg/m3'
        default: return 'ppb'
    }
}

const findUnit = measurement =>
    measurement === 'voc' ? 'ppb' : 'ugm3'

const Ventilation = ({ input, automationId, loading, set }: {
    input?: AutomationInput
    automationId: number
    loading: boolean
    set: InputSetter
}) => {
    const measurement = input?.measurement || 'voc'
    const unit = findUnit(measurement)
    const _factor = input ? findFactor({ measurement, unit })(input.start_set_point) : null
    const [factor, setFactor] = useState<string | null>(_factor)
    const touched = useRef(false)
    const disabled = useIsDisabled(automationId)

    const parsedMeasurement = parseMeasurement(measurement || 'voc')
    const parsedUnit = parseUnit(unit)

    const _setFactor = (x) => {
        if (!touched.current) touched.current = true
        setFactor(x)
    }

    useSkipFirstRender(() => {
        if (!factor || !measurement || !unit) return
        if (!input) return
        if (!touched.current) return
        set(inputs => inputs.map(_input => {
            if (input.id === _input.id) {
                return {
                    ..._input,
                    start_set_point: start_set_point[measurement][unit][factor],
                    stop_set_point: stop_set_point[measurement][unit][factor],
                }
            }
            return _input
        }))
    }, [factor])

    if (!input) return (
        <EmptyState title='Ventilation' />
    )

    return (
        <VStack
            spacing={4}
            alignItems='flex-start'
            w='100%'>
            <Muted>Activate equipment when <Emphasis>{parsedMeasurement}</Emphasis> rises above</Muted>
            <RadioGroup isDisabled={loading || disabled} onChange={_setFactor} value={factor || 'fair'}>
                <VStack w='100%' alignItems='flex-start'>
                    <Radio value='fair'>
                        <Text as='span' fontWeight='semibold'>Fair </Text>
                        <Muted as='span' fontWeight='normal'>{start_set_point[measurement][unit]['fair']} {parsedUnit}</Muted>
                    </Radio>
                    <Radio value='poor'>
                        <Text as='span' fontWeight='semibold'>Poor </Text>
                        <Muted as='span' fontWeight='normal'>{start_set_point[measurement][unit]['poor']} {parsedUnit}</Muted>
                    </Radio>
                </VStack>
            </RadioGroup>
        </VStack>
    )
}

export default Ventilation
