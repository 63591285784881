import { DateTimeInput, SelectInput, StringInput } from "components/inputs";
import { FormGroup, UpdateAction } from "components/form";
import React, { useState } from "react";

import { ductDimensions } from "utils/duct-options";
import { useForm } from "react-hook-form";
import { usePost } from "api/api-hooks";

export function EquipmentDetails(props) {
    const [details, setDetails] = useState(props.details);
    const { call } = usePost(`equipment/${details.id}`);

    const form = useForm({
        defaultValues: {
            ...details,
            install_timestamp: new Date(details.install_timestamp || null),
        },
    });

    const { handleSubmit, reset } = form;

    function handleReset() {
        reset({ ...details });
    }

    function onSubmit(data) {
        data.duct_height = +data.duct_height;
        data.duct_width = +data.duct_width;
        call(data, () => {
            setDetails(data);
        });
    }

    return (
        <>
            <FormGroup>
                <StringInput form={form} field="id" disabled={true}></StringInput>
                <StringInput form={form} field="type" disabled={true}></StringInput>
                <StringInput form={form} field="model" disabled={true}></StringInput>
                <StringInput form={form} field="brand" disabled={true}></StringInput>
                <DateTimeInput form={form} field="install_timestamp" required={true}></DateTimeInput>
                <StringInput form={form} field="zone"></StringInput>
                <StringInput form={form} field="duct_type"></StringInput>
                <StringInput form={form} field="duct_shape"></StringInput>
                <StringInput form={form} field="duct_material"></StringInput>
                <SelectInput form={form} field="duct_width" options={ductDimensions}></SelectInput>
                <SelectInput form={form} field="duct_height" options={ductDimensions}></SelectInput>
            </FormGroup>

            <UpdateAction onReset={handleReset} onUpdate={handleSubmit(onSubmit)}></UpdateAction>
        </>
    );
}
