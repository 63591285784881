import '@fontsource-variable/inter'
import "./index.css";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { StrictMode } from "react";

import App from "./app";
import AuthProvider from "auth/auth-provider";
import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";

// Custom theme settings
const theme = extendTheme({
    fonts: {
        heading: "Rubik, sans-serif",
        body: "Inter Variable, sans-serif",
    },
    styles: {
        global: props => ({
            Link: {
                color: 'blue.500 !important',
            },
        }),
    },
    config: {
        useSystemColorMode: true,
        initialColorMode: "dark",
    },
    colors: {
        havenGrey: "#606F78",
        havenGreyDark: "#606F78",
        havenBlue: "#00C4C1",
    },
});

// Initialize the app
const container = document.getElementById("root");
const root = createRoot(container);
root.render(
    <StrictMode>
        <BrowserRouter>
            <AuthProvider>
                <ChakraProvider theme={theme}>
                    <App />
                </ChakraProvider>
            </AuthProvider>
        </BrowserRouter>
    </StrictMode>
);
