import { Button, Image } from "@chakra-ui/react";

import React from "react";
import { capitalizeAll } from "utils/text-transform";

/**
 * Custom styled Haven buttons.
 */
export function HavenButton({ label, onClick, bg, disabled, color, borderColor, rightIcon = null, flex = null }) {
    // Dynamic props are needed to be able to add rightIcon attribute dynamically to the button
    let inputProps = {};
    if (rightIcon) inputProps.rightIcon = rightIcon;

    return (
        <Button
            bg={bg}
            color={color}
            disabled={disabled}
            flex={flex}
            onClick={onClick}
            border={"1px"}
            borderColor={borderColor}
            {...inputProps}
        >
            {capitalizeAll(label)}
        </Button>
    );
}

export function HavenSolidGreenButton({ label, onClick, disabled = false, flex = null }) {
    return (
        <HavenButton
            flex={flex}
            label={label}
            onClick={onClick}
            disabled={disabled}
            color="white"
            bg="#00C4C1"
            borderColor="#00C4C1"
        ></HavenButton>
    );
}

export function HavenGreenButton({ label, onClick, disabled = false, flex = null }) {
    return (
        <HavenButton
            flex={flex ?? null}
            label={label}
            onClick={onClick}
            disabled={disabled}
            color={"#00C4C1"}
            bg="transparent"
            borderColor="#00C4C1"
        ></HavenButton>
    );
}

export function HavenRedButton({ label, onClick, disabled = false, flex = null }) {
    return (
        <HavenButton
            flex={flex ?? null}
            label={label}
            onClick={onClick}
            disabled={disabled}
            color={"#F96C6C"}
            rightIcon={<Image src="/assets/x.png" height="1.4rem" />}
            bg="transparent"
            borderColor="#F96C6C"
        ></HavenButton>
    );
}
