import { Box, Center, Input, Spinner, useColorMode } from "@chakra-ui/react";
import React, { useState } from "react";
import { getCompanies, getDwellings, getEquipments, getUsers } from "api/api";

import { useDebounce } from "throttle-hooks";

export function DwellingSearch(props) {
    const { onChange } = props;
    return <EntitySearch onChange={onChange} entityName={"Dwelling"} getEntities={getDwellings}></EntitySearch>;
}

export function UserSearch(props) {
    const { onChange } = props;
    return <EntitySearch onChange={onChange} entityName={"User"} getEntities={getUsers}></EntitySearch>;
}

export function EquipmentSearch(props) {
    const { onChange } = props;
    return <EntitySearch onChange={onChange} entityName={"Equipment"} getEntities={getEquipments}></EntitySearch>;
}

export function CompanySearch(props) {
    const { onChange } = props;
    return <EntitySearch onChange={onChange} entityName={"Company"} getEntities={getCompanies}></EntitySearch>;
}

export function EntitySearch(props) {
    // onChange is the react-hook-form parameter used to pass the id of the selected item to the parent form where this search component is used
    const { onChange } = props;
    const { entityName, getEntities } = props;
    const [entities, setEntities] = useState([]);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [currentInput, setCurrentInput] = useState("");
    const [loading, setLoading] = useState(false);
    const { colorMode } = useColorMode();

    // use debounce on typing to prevent hitting the server on every character change
    const debounce = useDebounce(700);

    // called when input is manually updated
    function onInputChange(e) {
        const value = e.target.value;
        setCurrentInput(value);
        setDropdownVisible(true);
        setLoading(true);

        // remove selected entity from the form when an input is updated, because the selected entity can only be set by being selected from the list
        onChange(null);

        // use debounce to prevent calling the server on every stroke
        debounce(() => search(value));
    }

    // called when an entity is selected from the list
    function onSelect(e) {
        const name = e.target.title;
        const id = e.target.value;
        setCurrentInput(name);

        // leave only the selected entity in the list of entities
        const filteredEntities = entities.filter(d => d.id === id);
        setEntities(filteredEntities);

        // pass id of the selected entity to the form where this component is used
        onChange(id);
    }

    // called when the input field is clicked
    function onFocus() {
        // open the dropdown
        setDropdownVisible(true);

        // if the input is pristine, search for all entities
        if (currentInput === "" && entities.length === 0) {
            search("");
        }
    }

    // called when clicking outside the input field to hide the dropdown
    function onBlur() {
        setDropdownVisible(false);
    }

    // search for the entities using the provided value
    function search(value) {
        setLoading(true);
        getEntities(value).then(data => {
            setLoading(false);
            setEntities(data);
        });
    }

    return (
        <Box position="relative" width="100%">
            <Input
                onChange={onInputChange}
                value={currentInput}
                onFocus={onFocus}
                onBlur={onBlur}
                placeholder={`Search ${entityName}`}
                width="100%"
            ></Input>
            {dropdownVisible && (
                <div className={colorMode === "light" ? "dropdown" : "dropdown dropdown-dark"}>
                    {loading && (
                        <Center>
                            <Spinner m={2}></Spinner>
                        </Center>
                    )}
                    {!loading && (
                        <ul>
                            {/* use onMouseDown instead of onClick as it takes priority over onBlur */}
                            {entities?.map(c => (
                                <li onMouseDown={onSelect} key={c.id} value={c.id} title={c.name ?? c.email}>
                                    id:{c.id} "{c.name ?? c.email}"
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
            )}
        </Box>
    );
}
