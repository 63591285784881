import {
    Button,
    Heading,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Switch,
    VStack,
    useDisclosure,
} from "@chakra-ui/react";
import { GenericInput, Status, StringInput, ValueStatus } from "components/inputs";
import React, { useEffect, useState } from "react";

import { DeviceHardwareState } from "./device-hardware-state";
import { ErrorCodeField } from "components/info-popup";
import date from "date-and-time";
import { usePost } from "api/api-hooks";

export function DeviceTwin({ id, twin, hardwareState }) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { call: updateTwin } = usePost(`device/${id}/twin`);

    const [updateEnabled, setUpdateEnabled] = useState(twin?.tags?.ota_status === "ready_for_update");

    function onUpdateToggle(e) {
        updateTwin({ ota_enabled: e.target.checked }, () => setUpdateEnabled(!updateEnabled));
        // TODO: also pull twin data again once it's updated
    }

    const [telemetryStatus, setTelemetryStatus] = useState(null);
    const [telemetryStatusText, setTelemetryStatusText] = useState(null);
    const [telemetryTsUtc, setTelemetryTsUtc] = useState(null);

    useEffect(() => {
        if (twin.last_activity_time) {
            let ts = new Date(twin.last_activity_time);
            let now = new Date();
            const utcTs = date.format(ts, "YYYY-MM-DD HH:mm:ss", true);
            setTelemetryTsUtc(utcTs);

            let hoursDiff = (now.getTime() - ts.getTime()) / 1000 / 60 / 60;
            if (hoursDiff < 24) {
                setTelemetryStatus(Status.GOOD);
                setTelemetryStatusText("Today");
            } else if (hoursDiff < 24 * 14) {
                setTelemetryStatus(Status.WARNING);
                setTelemetryStatusText("Last 2 weeks");
            } else {
                setTelemetryStatus(Status.BAD);
                setTelemetryStatusText("No activity in 2 weeks");
            }
        } else {
            setTelemetryStatus(Status.BAD);
            setTelemetryStatusText("No activity in 2 weeks");
        }
    }, [twin]);

    return (
        <VStack align="stretch">
            <DeviceHardwareState data={hardwareState}></DeviceHardwareState>
            <br></br>
            <Heading size="md">IoT Hub Twin</Heading>

            <ValueStatus
                label="Last activity (UTC)"
                value={telemetryStatusText}
                secondaryValue={telemetryTsUtc}
                status={telemetryStatus}
            ></ValueStatus>
            <ValueStatus
                label="Device state"
                value={twin.reported_properties?.deviceState ? twin.reported_properties?.deviceState : "N/A"}
                status={twin.reported_properties?.deviceState === "OK" ? Status.GOOD : Status.BAD}
            ></ValueStatus>
            <ValueStatus
                label="Connection state"
                value={twin.connection_state}
                status={twin.connection_state === "Connected" ? Status.GOOD : Status.BAD}
            ></ValueStatus>
            <ErrorCodeField errorCode={twin.reported_properties?.sensorProperties?.status?.errCode}></ErrorCodeField>
            <StringInput field="Hardware version" value={twin.reported_properties?.hardwareVersion}></StringInput>
            <StringInput field="Firmware version" value={twin.reported_properties?.firmwareVersion}></StringInput>
            <StringInput
                field="Firmware updated (UTC)"
                value={date.format(new Date(twin.reported_properties?.firmwareUpdatedTime), "YYYY-MM-DD HH:mm:ss", true)}
            ></StringInput>
            <GenericInput isInvalid={false} isRequired={false} label={"OTA update enabled"}>
                <Switch pl="18px" onChange={onUpdateToggle} isChecked={updateEnabled} />
            </GenericInput>

            <Button onClick={onOpen}>Raw Twin JSON</Button>

            <Modal isOpen={isOpen} onClose={onClose} size={"xl"} scrollBehavior={"inside"}>
                <ModalOverlay />
                <ModalContent overflowX={"scroll"}>
                    <ModalHeader>Twin details</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <pre>{JSON.stringify(twin, null, 2)}</pre>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </VStack>
    );
}
