import {
    NumberInput as ChakraNumberInput,
    Box,
    FormControl,
    FormErrorMessage,
    HStack,
    Image,
    Input,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInputField,
    NumberInputStepper,
    Select,
    Slider,
    SliderFilledTrack,
    SliderMark,
    SliderThumb,
    SliderTrack,
    Switch,
    Text,
    Textarea,
    Tooltip,
    useMediaQuery,
} from "@chakra-ui/react";
import { CompanySearch, DwellingSearch } from "./entity-search";
import { useState } from "react";
import { canadianProvinces, usaStates } from "utils/provinces-states";

import { Controller } from "react-hook-form";
import { QuestionIcon } from "@chakra-ui/icons";
import { StyledDateTimePicker } from "./datetimepicker";
import { fieldToLabel } from "utils/text-transform";

export const Status = {
    GOOD: 0,
    WARNING: 1,
    BAD: 2,
};

/**
 * A wrapper that helps handle various input forms across the app using react-hook-form and chakra-ui as building blocks.
 */
export function GenericInput(props) {
    const [isLargeScreen] = useMediaQuery("(min-width: 1000px)");

    return (
        <div>
            <FormControl
                isInvalid={props.isInvalid ? props.isInvalid : false}
                isRequired={props.isRequired ? props.isRequired : false}
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="flex-start"
                h="40px"
            >
                <Tooltip label={props.label} isDisabled={isLargeScreen}>
                    <Text noOfLines={1} minW={isLargeScreen ? "10rem" : "6rem"} maxW={isLargeScreen ? "10rem" : "6rem"} fontSize="14px">
                        {props.label}
                    </Text>
                </Tooltip>
                {props.children}
            </FormControl>
        </div>
    );
}

export function HavenSlider({ label, percentage }) {
    return (
        <GenericInput isInvalid={false} isRequired={false} label={label}>
            <Slider value={percentage} mr={5}>
                <SliderMark value={percentage} textAlign="center" mt="-8" ml="-5" w="12">
                    {percentage}%
                </SliderMark>
                <SliderTrack bgGradient="linear(to-r,#F96C6C,#FFC700, #39DD7B)">
                    <SliderFilledTrack bg="transparent" />
                </SliderTrack>
                <SliderThumb />
            </Slider>
        </GenericInput>
    );
}

export function ValueStatus({ label, value, status, secondaryValue = null, customComponent = null }) {
    return (
        <GenericInput isInvalid={false} isRequired={false} label={label}>
            <Text fontSize="16px" pl="18px">
                <HStack>
                    {status === Status.GOOD && (
                        <>
                            <Image src="/assets/check.png" height="1.4rem" />
                            <Text color="#39DD7B">{value}</Text>
                            {secondaryValue && (
                                <Tooltip label={secondaryValue}>
                                    <Text noOfLines={1}>({secondaryValue})</Text>
                                </Tooltip>
                            )}
                        </>
                    )}
                    {status === Status.WARNING && (
                        <>
                            <Image src="/assets/warning.png" height="1.4rem" />
                            <Text color="#FFC700">{value}</Text>
                            {secondaryValue && (
                                <Tooltip label={secondaryValue}>
                                    <Text noOfLines={1}>({secondaryValue})</Text>
                                </Tooltip>
                            )}
                        </>
                    )}
                    {status === Status.BAD && (
                        <>
                            <Image src="/assets/cross.png" height="1.4rem" />
                            <Text color="#F96C6C">{value}</Text>
                            {secondaryValue && (
                                <Tooltip label={secondaryValue}>
                                    <Text noOfLines={1}>({secondaryValue})</Text>
                                </Tooltip>
                            )}
                        </>
                    )}
                    {customComponent}
                </HStack>
            </Text>
        </GenericInput>
    );
}

export function BooleanStatus({ label, isOk }) {
    return (
        <GenericInput isInvalid={false} isRequired={false} label={label}>
            <Text fontSize="16px" pl="18px">
                {isOk ? <Image src="/assets/check.png" height="1.4rem" /> : <Image src="/assets/cross.png" height="1.4rem" />}
            </Text>
        </GenericInput>
    );
}

// generic form for stirng values
// value = null  is set by default because form is used, but when it's disabled, it's easier to pass a simple value than dealing with forms, so if value is set form is ignored. TODO it's bad design and i need to refactor
export function StringInput({ form, field, value = null, required = false, disabled = false, onChange = null }) {
    const label = fieldToLabel(field);

    if (!form) {
        return (
            <GenericInput isInvalid={false} isRequired={false} label={label} >
                <Text fontSize="16px" pl="18px" >
                    {value}
                </Text>
            </GenericInput>
        );
    }

    const {
        register,
        getValues,
        formState: { errors },
    } = form;

    return (
        <GenericInput isInvalid={errors[field]} isRequired={required} label={label}>
            {!disabled && <Input id={field} placeholder={label} {...register(field, { required })} boxShadow="none" onChange={onChange} />}
            {disabled && (
                <Text fontSize="16px" pl="18px">
                    {getValues(field)}
                </Text>
            )}
        </GenericInput>
    );
}


export function MultilineStringInput({ form, field, value = null, required = false, disabled = false }) {
    const label = fieldToLabel(field);

    if (!form) {
        return (
            <GenericInput isInvalid={false} isRequired={false} label={label}>
                <Text fontSize="16px" pl="18px">
                    {value}
                </Text>
            </GenericInput>
        );
    }

    const {
        register,
        getValues,
        formState: { errors },
    } = form;

    return (
        <Box mt={4} mb={4}>
            <GenericInput isInvalid={errors[field]} isRequired={required} label={label}>
                {!disabled && <Textarea id={field} placeholder={label} {...register(field, { required })} boxShadow="none" />}
                {disabled && (
                    <Text fontSize="16px" pl="18px">
                        {getValues(field)}
                    </Text>
                )}
            </GenericInput>
        </Box>
    );
}



// similar to the StringInput except it only accepts numbers
// TODO: rename as it conflicts with chakra-ui
export function NumberInput({ form, field, min, max, step, value = null, required = false }) {
    const label = fieldToLabel(field);

    if (!form) {
        return <></>;
    }

    const {
        register,
        setValue,
        setError,
        clearErrors,
        getFieldState,
        formState: { errors },
    } = form;

    function onChange(value) {
        if (value === "" && required) {
            setError(field, { type: "required" })
            return;
        }
        clearErrors(field)
        setValue(field, value)
    }


    return (
        <GenericInput isInvalid={errors[field]} isRequired={required} label={label}>
            <ChakraNumberInput
                id={field}
                {...register(field, { required, valueAsNumber: true })}
                onChange={onChange}
                step={step}
                defaultValue={0}
                min={min}
                max={max}
            >
                <NumberInputField />
                <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                </NumberInputStepper>
            </ChakraNumberInput>
        </GenericInput>
    );
}

export function EmailInput({ form, field = "email", required = false, onChange = null }) {
    const {
        register,
        formState: { errors },
    } = form;
    const label = fieldToLabel(field);

    return (
        <GenericInput isInvalid={errors[field]} isRequired={required} label={label}>
            <Input
                id={field}
                placeholder={label}
                {...register(field, {
                    required,
                    pattern: {
                        value: /^[\w\-\+\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                        message: "Invalid email format",
                    },
                })}
                onChange={onChange}
            />
            <FormErrorMessage>{errors?.[field]?.message}</FormErrorMessage>
        </GenericInput>
    );
}

export function PhoneInput({ form, field = "phone", required = false, onChange = null }) {
    const {
        register,
        formState: { errors },
    } = form;
    const label = fieldToLabel(field);

    return (
        <GenericInput isInvalid={errors[field]} isRequired={required} label={label}>
            <Input
                id={field}
                placeholder={label}
                {...register(field, {
                    required,
                    pattern: {
                        value: /^\d{3}-\d{3}-\d{4}$/,
                        message: "Invalid phone number",
                    },
                })}
                onChange={onChange}
            />
            <FormErrorMessage>
                {errors?.[field]?.message}
                <Tooltip label="Valid format: 123-123-1234">
                    <QuestionIcon ml={1} />
                </Tooltip>
            </FormErrorMessage>
        </GenericInput>
    );
}

export function CompanyInput({ form, required = false }) {
    const {
        control,
        formState: { errors },
    } = form;

    return (
        <GenericInput isInvalid={errors.preferred_service_company_id} isRequired={required} label="Company">
            <Controller
                control={control}
                name="preferred_service_company_id"
                defaultValue={null}
                rules={{ required }}
                render={({ field: { onChange } }) => <CompanySearch onChange={onChange}></CompanySearch>}
            />
            <FormErrorMessage>Select company from the list</FormErrorMessage>
        </GenericInput>
    );
}

export function DwellingInput({ form, required = false }) {
    const {
        control,
        formState: { errors },
    } = form;

    return (
        <GenericInput isInvalid={errors.dwelling_id} isRequired={required} label="Dwelling">
            <Controller
                control={control}
                name="dwelling_id"
                defaultValue={null}
                rules={{ required }}
                render={({ field: { onChange } }) => <DwellingSearch onChange={onChange}></DwellingSearch>}
            />
            <FormErrorMessage>Select dwelling from the list</FormErrorMessage>
        </GenericInput>
    );
}

export function DateTimeInput({ form, field, required = false, disabled = false }) {
    const {
        control,
        formState: { errors },
    } = form;
    const label = fieldToLabel(field);

    return (
        <GenericInput isInvalid={errors[field]} isRequired={required} label={label}>
            <Controller
                id={field}
                control={control}
                name={field}
                rules={{ required }}
                render={({ field: { value, onChange } }) => (
                    <StyledDateTimePicker
                        calendarIcon={null}
                        onChange={onChange}
                        value={value}
                        disableClock={true}
                        format="y-MM-dd HH:mm"
                        disabled={disabled}
                    ></StyledDateTimePicker>
                )}
            />
            <FormErrorMessage>Must specify timestamp</FormErrorMessage>
        </GenericInput>
    );
}

export function SelectInput({ form, field, options, ...rest }) {
    const { register } = form;
    const label = fieldToLabel(field);

    return (
        <GenericInput label={label}>
            <Select id={field} {...register(field)}  {...rest}>
                {options.map(option => (
                    <option key={option} value={option}>
                        {fieldToLabel(option)}
                    </option>
                ))}
            </Select>
        </GenericInput>
    );
}

export function BooleanInput({ form, field }) {
    const { register } = form;
    const label = fieldToLabel(field);

    return (
        <GenericInput label={label}>
            <Select id={field} {...register(field)}>
                <option value={true}>True</option>
                <option value={false}>False</option>
            </Select>
        </GenericInput>
    );
}
export function ToggleInput({ form, field, }) {
    const { register } = form;
    const label = fieldToLabel(field);

    return (
        <GenericInput label={label}>
            <Switch id={field} {...register(field)}>
            </Switch>
        </GenericInput>
    );
}

export function AddressInput({ form, onChange = null }) {
    const {
        register,
        formState: { errors },
        setValue,
    } = form;

    const [provinces, setProvinces] = useState(form.getValues().country === "USA" ? usaStates : canadianProvinces);

    // Whenever the country is changed update the list of selectable provinces.
    function onCountryChange(e) {
        const value = e.target.value;
        // since there are only two countries (USA & Canada) to select from the logic is simple
        if (value === "USA") {
            setProvinces(usaStates);
            setValue("province", usaStates[0].value);
        } else {
            setProvinces(canadianProvinces);
            setValue("province", canadianProvinces[0].value);
        }
        if (onChange) onChange(e)
    }

    return (
        <>
            <StringInput form={form} field="street_1" onChange={onChange}></StringInput>
            <StringInput form={form} field="street_2" onChange={onChange}></StringInput>
            <StringInput form={form} field="city" onChange={onChange}></StringInput>

            {/* Province/State */}
            <GenericInput label="Province">
                <Select id="province" {...register("province")} onChange={onChange}>
                    {provinces.map(p => (
                        <option key={p.value} value={p.value}>
                            {p.name}
                        </option>
                    ))}
                </Select>
            </GenericInput>

            {/* Country */}
            <GenericInput label="Country">
                <Select id="country" {...register("country")} onChange={onCountryChange}>
                    <option value="Canada">Canada</option>
                    <option value="USA">USA</option>
                </Select>
            </GenericInput>

            {/* Postal code */}
            <GenericInput isInvalid={errors.postal_code} label="Postal">
                <Input
                    id="postal_code"
                    placeholder="Postal code"
                    {...register("postal_code", {
                        required: true,
                        pattern: {
                            value: /(^[A-Za-z]\d[A-Za-z][ ]?\d[A-Za-z]\d$)|(^\d{5}$)|(^\d{5}-\d{4}$)/,
                            message: "Invalid postal code",
                        },
                    })}
                    onChange={onChange}
                />

                <FormErrorMessage>
                    {errors?.postal_code?.message}
                    <Tooltip label="Valid examples: V6B 1J1, V6B1J1, 12345">
                        <QuestionIcon ml={1} />
                    </Tooltip>
                </FormErrorMessage>
            </GenericInput>
        </>
    );
}
