import AssociatedEntity from "../../../components/associated-entity";
import Card from "../../../components/card";
import { EquipmentSearch } from "components/entity-search";
import { FilterDetails } from "./filter-details";
import { Grid } from "@chakra-ui/react";
import React from "react";
import { getEquipment } from "../../../api/api";

export default function Filter({ filter }) {
    const { details, equipment } = filter;

    return (
        <Grid templateColumns="repeat(2, 1fr)" gap={4} width="100%">
            <DetailsCard details={details} />
            <EquipmentCard details={details} equipment={equipment} />
        </Grid>
    );
}

function DetailsCard({ details }) {
    return (
        <Card header="Details" error={details.error} loading={details.loading}>
            {details.data && <FilterDetails details={details.data}></FilterDetails>}
        </Card>
    );
}

function EquipmentCard({ details, equipment }) {
    return (
        <Card header="Equipment" error={equipment.error} loading={equipment.loading} gotoUrl={`/fleet/equipments/${equipment?.data?.id}`}>
            {details.data && equipment.data && (
                <AssociatedEntity
                    entity={equipment.data}
                    updateUrl={`filter/${details?.data?.id}`}
                    updateBody={details}
                    entityName={"Equipment"}
                    getEntityCall={getEquipment}
                    fieldName={"equipment_id"}
                >
                    <EquipmentSearch></EquipmentSearch>
                </AssociatedEntity>
            )}
        </Card>
    );
}
