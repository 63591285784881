import { AgGridColumn } from "ag-grid-react";
import AgGridStyled from "components/ag-grid-styled";
import React from "react";
import { fieldToLabel } from "utils/text-transform";

export function CompanyInvites(props) {
    return (
        <AgGridStyled
            gridParams={{
                rowData: props.invites.invites,
                defaultColDef: {
                    sortable: true,
                },
            }}
            autoSize={true}
        >
            <AgGridColumn field={"code"} headerName={fieldToLabel("code")} cellRenderer={"copyValueCellRenderer"}></AgGridColumn>
            <AgGridColumn
                field={"expire_timestamp"}
                headerName={fieldToLabel("expire_timestamp")}
                cellRenderer={"timestampCellRenderer"}
            ></AgGridColumn>
            <AgGridColumn field={"team"} headerName={fieldToLabel("team")}></AgGridColumn>
            <AgGridColumn field={"email"} headerName={fieldToLabel("email")}></AgGridColumn>
            <AgGridColumn field={"status"} headerName={fieldToLabel("status")}></AgGridColumn>
            <AgGridColumn field={"user_id"} headerName={fieldToLabel("user_id")}></AgGridColumn>
        </AgGridStyled>
    );
}
