import { JourneyState } from "types";

// base API url of the server
export const apiUrl = process.env.REACT_APP_SERVER_URL;

/**
 * Fetch wrapper that sets authorization and content-type headers. After receiving response it detrmined whether it's an error or a valid response and parses it accordingly. This is the basic building block for all API calls.
 */
function fetchWithAuth(url: any, options: any) {
    options.headers = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("token")}`,
        "Client-Application": "fleet_manager"
    };

    return fetch(url, options).then(async response => {
        // If the endpoint does not return success, throw the error so it can be caught by the caller function
        if (!response.ok) {
            throw await response.json();
        }

        // If the call is successful just deserialize it
        return response.json();
    });
}

/**
 * Fetch GET wrapper.
 */
export function fetchGet(url) {
    const options = {
        method: "GET",
    };
    return fetchWithAuth(url, options);
}

/**
 * Fetch POST wrapper.
 */
export function fetchPost(url, body) {
    const options = {
        method: "POST",
        body: JSON.stringify(body),
    };
    return fetchWithAuth(url, options);
}

/**
 * Fetch DELETE wrapper.
 */
export function fetchDelete(url, body) {
    const options = {
        method: "DELETE",
        body: JSON.stringify(body),
    };
    return fetchWithAuth(url, options);
}

/*** GET LIST ***/

/**
 * Generic method to get a list of entities.
 * @param {*} getUrl - determines which entities to get (for example, "filter" or "devices")
 * @param {*} filter - filtering query params such as '?name=Dave&install_timestamp>2020-01-01'
 * @param {*} limit - page limit
 * @param {*} offset - page offset
 * @param {*} orderString - column to order by
 * @returns
 */
export function getList(getUrl, filter, limit = 100, offset = 0, orderString = "id") {
    if (getUrl === "ecosense/devices") { return getESList(getUrl); }
    return fetchGet(`${apiUrl}/${getUrl}?limit=${limit}&offset=${offset}${filter}&order=${orderString}`).then(response => response[getUrl]);
}
export function getESList(getUrl) {
    return fetchGet(`${apiUrl}/${getUrl}`).then(response => response[getUrl]);
}

export function getCompanies(name = "") {
    const filter = name ? "&name=" + name : "";
    return getList("service_companies", filter);
}

export function getUsers(email = "") {
    const filter = email ? "&email=" + email : "";
    return getList("users", filter);
}

export function getEquipments(name = "") {
    const filter = name ? "&name=" + name : "";
    return getList("equipments", filter);
}

export function getDwellings(name = "") {
    const filter = name ? "&name=" + name : "";
    return getList("dwellings", filter);
}

/*** Other GET ***/

/**
 * Get the user that logged in with auth0.
 */
export function getUserSelf() {
    return fetchGet(`${apiUrl}/user`);
}

export function getUser(userId) {
    return fetchGet(`${apiUrl}/user/${userId}`);
}

export function getDevice(deviceId) {
    return fetchGet(`${apiUrl}/device/${deviceId}`);
}

export function getDeviceTwin(deviceId) {
    return fetchGet(`${apiUrl}/device/${deviceId}/twin`);
}

export function getDeviceOffsets(deviceId) {
    return fetchGet(`${apiUrl}/device/${deviceId}/cam_offsets`).then(r => r["offsets"]);
}

export function getDeviceCalibrationReportUrl(deviceId) {
    return fetchGet(`${apiUrl}/device/${deviceId}/calibration_report`);
}

export function getEquipment(equipmentId) {
    return fetchGet(`${apiUrl}/equipment/${equipmentId}`);
}

export function getCompany(companyId) {
    return fetchGet(`${apiUrl}/service_company/${companyId}`);
}

export function getDwelling(dwellingId) {
    return fetchGet(`${apiUrl}/dwelling/${dwellingId}`);
}

export function getDwellingProfile(dwellingId, zone) {
    return fetchGet(`${apiUrl}/dwelling/${dwellingId}/profile?zone=${zone}`);
}

export function getDeviceAutomations(deviceId) {
    return fetchGet(`${apiUrl}/automation/ventilating_dehumidifiers?device_id=${deviceId}`).then(r => r["automations"]);
}

export function getAutomationDetails(automationId) {
    return fetchGet(`${apiUrl}/automation/ventilating_dehumidifier/${automationId}`);
}

export function getUserRoles(userId) {
    return fetchGet(`${apiUrl}/user/${userId}/service_company_roles`);
}

export function getDwellingPermissions(dwellingId) {
    return fetchGet(`${apiUrl}/dwelling_permissions?dwelling_id=${dwellingId}`);
}

// CHANGE TO dwelling_permissions after Lorne change server.
export function getUserPermissions(userId) {
    return fetchGet(`${apiUrl}/dwellings?user_id=${userId}`).then(r => r["dwellings"]);
}

export function getDwellingContents(dwellingId) {
    return fetchGet(`${apiUrl}/dwelling/${dwellingId}/contents`);
}

export function getUserProfile(userId) {
    return fetchGet(`${apiUrl}/user/${userId}/profile`);
}

export function getDeviceChannels(deviceId) {
    return fetchGet(`${apiUrl}/device/${deviceId}/cac_channels`);
}

export function getDeviceHardwareState(deviceId) {
    return fetchGet(`${apiUrl}/device/${deviceId}/hardware_state`);
}

export function getDeviceCurrentTelemetry(deviceId) {
    return fetchGet(`${apiUrl}/device/${deviceId}/telemetry_current`);
}

export function getDeviceHavenScore(deviceId) {
    return fetchGet(`${apiUrl}/device/${deviceId}/haven_score`);
}

export function getFilter(filterId) {
    return fetchGet(`${apiUrl}/filter/${filterId}`);
}

export function getCompanyProfile(companyId) {
    return fetchGet(`${apiUrl}/service_company/${companyId}/profile`);
}

export function getCompanyDwellings(companyId) {
    return fetchGet(`${apiUrl}/dwellings?preferred_service_company_id=${companyId}`).then(r => r["dwellings"]);
}

export function getCompanyRoles(companyId) {
    return fetchGet(`${apiUrl}/service_company/${companyId}/employees`);
}

export function getCompanyInvites(companyId) {
    return fetchGet(`${apiUrl}/service_company/${companyId}/invites`);
}

export function getCompanyJourney(companyId) {
    return fetchGet(`${apiUrl}/service_company/${companyId}/journey`);
}

export function updateCompanyJourney(companyId: string, state: JourneyState) {
    return fetchPost(`${apiUrl}/service_company/${companyId}/journey`, { body: state });
}

export function getCompanyJourneyLog(companyId) {
    return fetchGet(`${apiUrl}/service_company/${companyId}/journey/log`);
}

export function getCompanyQuestionnaire(companyId) {
    return fetchGet(`${apiUrl}/service_company/${companyId}/sales_questionnaire`);
}
export function getEcosenseDevices(userId) {
    return fetchGet(`${apiUrl}/ecosense/devices?user_id=${userId}`);
}

/*** CREATE ***/
export async function createDwelling(body) {
    return fetchPost(`${apiUrl}/dwelling`, body);
}

export async function createCompany(body) {
    return fetchPost(`${apiUrl}/service_company`, body);
}

export async function createEquipment(body) {
    return fetchPost(`${apiUrl}/equipment`, body);
}

export async function createCompanyProfile(services, companyId) {
    const body = {
        employee_count: "1-10",
        have_sales_team: false,
        want_demo: false,
        want_beta: false,
        comment: "default profile",
        services: services,
    };
    return fetchPost(`${apiUrl}/service_company/${companyId}/profile`, body);
}

export function updateCompanyQuestionnaire(companyId, body) {
    return fetchPost(`${apiUrl}/service_company/${companyId}/sales_questionnaire`, body);
}
/*** TAGGING ***/

async function getTags(entityId, entityName) {
    return fetchGet(`${apiUrl}/${entityName}/${entityId}/tags`).then(result => result["tags"]);
}

async function addTag(tag, entityId, entityName) {
    const body = {
        tags: [tag],
    };
    return fetchPost(`${apiUrl}/${entityName}/${entityId}/tags`, body);
}

async function removeTag(tag, entityId, entityName) {
    const body = {
        tags: [tag],
    };
    return fetchDelete(`${apiUrl}/${entityName}/${entityId}/tags`, body);
}

/*** Get Tags ***/

export async function getDeviceTags(id) {
    return getTags(id, "device");
}

export async function getUserTags(id) {
    return getTags(id, "user");
}

export async function getDwellingTags(id) {
    return getTags(id, "dwelling");
}

export async function getEquipmentTags(id) {
    return getTags(id, "equipment");
}

export async function getCompanyTags(id) {
    return getTags(id, "service_company");
}

export async function getFilterTags(id) {
    return getTags(id, "filter");
}

/*** Add Tags ***/

export async function addDeviceTag(tag, id) {
    return addTag(tag, id, "device");
}

export async function addUserTag(tag, id) {
    return addTag(tag, id, "user");
}

export async function addDwellingTag(tag, id) {
    return addTag(tag, id, "dwelling");
}

export async function addEquipmentTag(tag, id) {
    return addTag(tag, id, "equipment");
}

export async function addCompanyTag(tag, id) {
    return addTag(tag, id, "service_company");
}

export async function addFilterTag(tag, id) {
    return addTag(tag, id, "filter");
}

/*** Remove Tags ***/

export async function removeDeviceTag(tag, id) {
    return removeTag(tag, id, "device");
}

export async function removeUserTag(tag, id) {
    return removeTag(tag, id, "user");
}

export async function removeDwellingTag(tag, id) {
    return removeTag(tag, id, "dwelling");
}

export async function removeEquipmentTag(tag, id) {
    return removeTag(tag, id, "equipment");
}

export async function removeCompanyTag(tag, id) {
    return removeTag(tag, id, "service_company");
}

export async function removeFilterTag(tag, id) {
    return removeTag(tag, id, "filter");
}
