import { Box, Button, HStack, Spinner, Text, VStack } from "@chakra-ui/react";
import { CompanySearch, UserSearch } from "./entity-search";
import React, { useEffect, useState } from "react";
import { getCompanyRoles, getUserRoles } from "api/api";
import { useDelete, usePost } from "api/api-hooks";

import AgGridStyled from "./ag-grid-styled";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { HavenGreenButton } from "components/buttons";

/**
 * UI for vieweing and editing roles
 */
export default function Roles(props) {
    const { id, parentEntity, onChange } = props;
    const [roles, setRoles] = useState(props.roles);

    useEffect(() => {
        setRoles(props.roles);
    }, []);

    async function refresh() {
        if (parentEntity === "company") {
            setRoles(await getCompanyRoles(id));
        } else if (parentEntity === "user") {
            setRoles(await getUserRoles(id));
        }
    }

    function onRoleChange() {
        refresh();
        onChange();
    }

    return (
        <VStack alignItems="flex-start" h={"100%"}>
            {(parentEntity === "company" || (parentEntity === "user" && roles.length < 1)) && (
                <>
                    {parentEntity === "user" && <Text>User doesn't belong to a company</Text>}
                    <AddNewRole
                        companyId={parentEntity === "company" ? id : null}
                        userId={parentEntity === "user" ? id : null}
                        onAdd={onRoleChange}
                    ></AddNewRole>
                </>
            )}
            {parentEntity === "user" && roles.length > 0 && (
                <Text>User can belong to one company at a time. Remove all roles to be able to assign user to another company</Text>
            )}
            {(parentEntity === "company" || (parentEntity === "user" && roles.length > 0)) && (
                <RolesTable
                    roles={roles}
                    companyId={parentEntity === "company" ? id : null}
                    userId={parentEntity === "user" ? id : null}
                    onChange={onRoleChange}
                ></RolesTable>
            )}
        </VStack>
    );
}

function RolesTable({ roles, userId, companyId, onChange }) {
    const [rows, setRows] = useState([]);

    useEffect(() => {
        if (userId) {
            setRows(userRolesToTableRows(roles));
        } else if (companyId) {
            setRows(companyRolesToTableRows(roles));
        }
    }, []);

    useEffect(() => {
        if (userId) {
            setRows(userRolesToTableRows(roles));
        } else if (companyId) {
            setRows(companyRolesToTableRows(roles));
        }
    }, [roles]);

    function handleCellClicked(event) {
        // clicking only works when user clicks explicitly on the name, otherwise when checkbox is missed the user is unexpectedly redirected, so to avoid that, return immediately
        if (event.column.colId !== "name" && event.column.colId !== "email") {
            return;
        }

        const id = event.data.id;
        if (userId) {
            window.open(`/fleet/companies/${id}`, "_blank").focus();
        } else if (companyId) {
            window.open(`/fleet/users/${id}`, "_blank").focus();
        }
    }

    function CheckboxRenderer(props) {
        const [value, setValue] = useState(props.value === true);
        const { call: addRole, loading: adding } = usePost("service_company_role");
        const { call: removeRole, loading: removing } = useDelete("service_company_role");

        const handleClick = event => {
            const column = props.column.colId;
            setValue(event.target.checked);

            let roleNumber;
            if (column === "manager") {
                roleNumber = 0;
            } else if (column === "technician") {
                roleNumber = 1;
            } else if (column === "analyst") {
                roleNumber = 2;
            }

            let role = {};
            if (userId) {
                role = {
                    user_id: userId,
                    service_company_id: props.data.id,
                    role: roleNumber,
                };
            }
            if (companyId) {
                role = {
                    user_id: props.data.id,
                    service_company_id: companyId,
                    role: roleNumber,
                };
            }

            // Add or remove role depending on whether the checkbox is checked or not. If an error happens during the call revert the checkbox value back.
            if (event.target.checked) {
                addRole(
                    role,
                    () => {
                        // Update value in case of success
                        setValue(true);
                        // When added, call the parent function to refresh the data, this is needed to refresh permissions as roles affect permissions
                        onChange();
                    },
                    () => setValue(false) // Reverse the value in case of failure
                );
            } else {
                removeRole(
                    role,
                    () => {
                        // Update value in case of success
                        setValue(false);
                        // When removed, call the parent function to refresh the data, this is needed to remove the empty rows with no roles and refresh permissions as roles affect permissions
                        // TODO: figure out a more efficient way to do this not to call the server every time.
                        onChange();
                    },
                    () => setValue(true) // Reverse the value in case of failure
                );
            }
        };

        return (
            <>
                {(adding || removing) && <Spinner size="sm" mt={3}></Spinner>}
                {!(adding || removing) && <input type="checkbox" onChange={handleClick} checked={value === true} />}
            </>
        );
    }

    var columnDefs = [
        {
            headerName: userId ? "Company name" : "User email",
            field: userId ? "name" : "email",
            suppressMenu: true,
            cellRenderer: "externalLinkRenderer",
        },
        {
            headerName: "Manager",
            headerTooltip: "Manager",
            field: "manager",
            cellRenderer: "checkboxRenderer",
            maxWidth: 85,
            suppressMenu: true,
        },
        {
            headerName: "Technician",
            headerTooltip: "Technician",
            field: "technician",
            cellRenderer: "checkboxRenderer",
            maxWidth: 85,
            suppressMenu: true,
        },
        {
            headerName: "Analyst",
            headerTooltip: "Analyst",
            field: "analyst",
            cellRenderer: "checkboxRenderer",
            maxWidth: 85,
            suppressMenu: true,
        },
    ];

    // Add the external link icon and a pointer cursor to indicate that clicking this cell opens a new tab
    function ExternalLinkRenderer(props) {
        return (
            <Box cursor={"pointer"}>
                {props.value}
                <ExternalLinkIcon mb={0.9} ml={1}></ExternalLinkIcon>
            </Box>
        );
    }

    return (
        <AgGridStyled
            gridParams={{
                frameworkComponents: { checkboxRenderer: CheckboxRenderer, externalLinkRenderer: ExternalLinkRenderer },
                rowData: rows,
                columnDefs: columnDefs,
                onCellClicked: handleCellClicked,
            }}
            className="ag-grid-roles"
        ></AgGridStyled>
    );
}

/**
 * Convert the list of roles from the server to the format consumed by ag grid
 */
function userRolesToTableRows(roles) {
    let rows = [];
    for (const { id, name, role } of roles) {
        if (!rows.some(row => row.id === id)) {
            // add row if it doesn't exist yet
            rows.push({ id, name, manager: false, technician: false, analyst: false });
        }

        rows = rows.map(row => {
            if (row.id === id) {
                if (role === 0) return { ...row, manager: true };
                if (role === 1) return { ...row, technician: true };
                if (role === 2) return { ...row, analyst: true };
            }
            return row;
        });
    }

    return rows;
}

/**
 * Convert the list of roles from the server to the format consumed by ag grid
 */
function companyRolesToTableRows(companyRoles) {
    let rows = [];
    for (const { id, email, roles } of companyRoles) {
        if (!rows.some(row => row.id === id)) {
            // add row if it doesn't exist yet and set roles accordingly. 0 - manager, 1 - technician, 2 - analyst
            rows.push({ id, email, manager: roles.includes(0), technician: roles.includes(1), analyst: roles.includes(2) });
        }
    }

    return rows;
}

function AddNewRole(props) {
    const { companyId, userId, onAdd } = props;
    return (
        <>
            {userId && <AddCompany onAdd={onAdd} userId={userId}></AddCompany>}
            {companyId && <AddUser onAdd={onAdd} companyId={companyId}></AddUser>}
        </>
    );
}

function AddCompany(props) {
    const { userId, onAdd } = props;
    const [selectedCompanyId, setSelectedCompanyId] = useState(null);
    const { call } = usePost("service_company_role");

    function onSelectCompany(id) {
        setSelectedCompanyId(id);
    }

    async function onAddCompany() {
        const body = {
            user_id: userId,
            service_company_id: selectedCompanyId,
            role: 2, // default role analyst
        };
        call(body, onAdd);
    }

    return (
        <HStack>
            <CompanySearch onChange={onSelectCompany}></CompanySearch>
            <HavenGreenButton label="Add" onClick={onAddCompany} disabled={selectedCompanyId == null}></HavenGreenButton>
        </HStack>
    );
}

function AddUser(props) {
    const { companyId, onAdd } = props;
    const [selectedUserId, setSelectedUserId] = useState(null);
    const { call } = usePost("service_company_role");

    function onSelectUser(id) {
        setSelectedUserId(id);
    }

    async function onAddUser() {
        const body = {
            user_id: selectedUserId,
            service_company_id: companyId,
            role: 2, // default role analyst
        };
        call(body, onAdd);
    }

    return (
        <HStack>
            <UserSearch onChange={onSelectUser}></UserSearch>
            <HavenGreenButton label="Add" onClick={onAddUser} disabled={selectedUserId == null}></HavenGreenButton>
        </HStack>
    );
}
