import "../index.css";

import {
    Avatar,
    Box,
    Button,
    DarkMode,
    HStack,
    IconButton,
    Image,
    Link,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
    Select,
    Spacer,
    useColorMode,
} from "@chakra-ui/react";
import { HamburgerIcon, MoonIcon, NotAllowedIcon, SunIcon } from "@chakra-ui/icons";
import React, { useEffect, useState } from "react";

import { NavLink } from "react-router-dom";
import { detectEnvironment } from "utils/env-detect";
import { useAuth0 } from "@auth0/auth0-react";
import { useLogout } from "auth/logout";

/**
 * Navigation bar at the top of the screen.
 */
export default function Navbar() {
    return (
        <HStack spacing={3} bg="havenGrey" direction="row" h="4rem" alignItems="center" pr={5} pl={5}>
            <Image src="/assets/haven-logo.png" alt="Haven Logo" height="75%" />

            {/* this box is used to balance out the right side of the navbar and center the navigtation links. TODO: find a better way to arrange navbar components more dynamically */}
            <Box w="9rem"></Box>

            <Spacer />
            <NavigationLink label="Dashboard" to="/dashboard"></NavigationLink>
            <NavigationLink label="Fleet" to="/fleet"></NavigationLink>
            <NavigationLink label="Telemetry" to="/telemetry"></NavigationLink>
            <Spacer />

            <EnvironmentSwitch />

            <ColorModeToggle />

            <ProfileMenu />
        </HStack>
    );
}

/**
 * Navigation link button used inside the navigation bar.
 */
function NavigationLink({ label, to }) {
    return (
        <Link
            pr={3}
            pl={3}
            boxShadow={"none"}
            alignSelf={"flex-end"}
            color={"white"}
            fontWeight={"bold"}
            opacity={0.6}
            as={NavLink}
            _activeLink={{ opacity: 1 }}
            activeclassname="active"
            // @ts-ignore
            className={({ isActive }) => "" + (isActive ? "active" : "")}
            to={to}
        >
            {label}
            {/* opacity changes to 1 when parent class is 'active' */}
            <Box opacity={0} borderTopRadius={"5px"} w={"100%"} h={"4px"} bg={"#00C4C1"} mt={4}></Box>
        </Link>
    );
}

/**
 * Environment switch for navigating between development, staging, production and daikin instances of fleet manager.
 */
function EnvironmentSwitch() {
    const [env, setEnv] = useState("");

    useEffect(() => {
        setEnv(detectEnvironment());
    }, []);

    function onEnvChange(e) {
        const value = e.target.value;

        // If current environment selected, don't do anything
        if (value === env) {
            return;
        }

        // Open new enviornment in a new tab
        if (value === "dev") {
            window.location.href = "https://development.fleet.tzoa.io/";
        } else if (value === "stage") {
            window.location.href = "https://staging.fleet.tzoa.io/";
        } else if (value === "daikin") {
            window.location.href = "https://haven-daikin-fleet-spa.azurewebsites.net/";
        } else if (value === "prod") {
            window.location.href = "https://fleet.tzoa.io/";
        }
    }

    return (
        <DarkMode>
            <Select value={env} onChange={onEnvChange} w={"6rem"} color="white" borderColor={"white"} size="sm">
                <option value={"dev"}>dev</option>
                <option value={"stage"}>stage</option>
                <option value={"prod"}>prod</option>
                <option value={"daikin"}>daikin</option>
            </Select>
        </DarkMode>
    );
}

/**
 * A button to change between dark and light color modes.
 */
function ColorModeToggle() {
    const { colorMode, toggleColorMode } = useColorMode();

    return (
        <DarkMode>
            <Button color="white" onClick={toggleColorMode}>
                {colorMode === "dark" && <MoonIcon></MoonIcon>}
                {colorMode === "light" && <SunIcon></SunIcon>}
            </Button>
        </DarkMode>
    );
}

/**
 * A hamburger menu that allows to either open a profile page or log out.
 */
function ProfileMenu() {
    const { user } = useAuth0();
    const { fullLogout } = useLogout();

    return (
        <Menu>
            <DarkMode>
                <MenuButton color="white" as={IconButton} aria-label="Options" icon={<HamburgerIcon />} variant="outline" />
            </DarkMode>
            <MenuList>
                <MenuItem as={NavLink} to="/profile" icon={<Avatar size="sm" name="Dan Abrahmov" src={user.picture} />}>
                    {user.name}
                </MenuItem>
                <MenuDivider />
                <MenuItem icon={<NotAllowedIcon boxSize={5} />} onClick={fullLogout}>
                    Log Out
                </MenuItem>
            </MenuList>
        </Menu>
    );
}
