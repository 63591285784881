import { Automation } from "types";
import { create } from "zustand";

export interface AutomationsStore {
    [key: number]: Automation
}

const useAutomationsStore = create<AutomationsStore>(set => ({
}))

const setAutomations = x => useAutomationsStore.setState(x)
const selectAutomation = (id: number) => (state: AutomationsStore) => state[id]

export {
    selectAutomation,
    setAutomations,
}
export default useAutomationsStore
