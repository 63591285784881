import DateTimePicker from "react-datetime-picker";
import React from "react";
import { useColorMode } from "@chakra-ui/react";

/**
 * Styled DateTimePicker
 */
export function StyledDateTimePicker(props) {
    const { colorMode } = useColorMode();

    return <DateTimePicker className={colorMode === "light" ? "datetime-picker-light" : "datetime-picker-dark"} {...props} />;
}
