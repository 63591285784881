import {
    Alert,
    AlertIcon,
    AlertTitle,
    HStack,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Spinner,
    Text,
    VStack,
    chakra,
    useDisclosure,
} from "@chakra-ui/react";
import { DateTimeInput, GenericInput, NumberInput, SelectInput } from "components/inputs";
import { useEffect, useState } from "react";
import { useHavenScoreSimulation, useTelemetrySimulation } from "api/api-hooks";
import { FormGroup } from "components/form";
import { HavenGreenButton } from "components/buttons";
import InfoPopup from "components/info-popup";
import { useForm } from "react-hook-form";

export function SimulateTelemetry(props) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    // const { call } = usePost(`device/${props.deviceId}/simulation`);
    const { scoreProgress, simulateHavenScore, errorHS } = useHavenScoreSimulation();
    const { telemetryProgress, simulateTelemetry } = useTelemetrySimulation();

    const [dewPoint, setDewPoint] = useState(-20);

    let form = useForm({
        defaultValues: {
            start_time: new Date(),
            stop_time: new Date(),
            voc: "good",
            pm: "good",
            air_movement: "on",
            temperature: 0,
            humidity: 0,
        },
    });
    let { handleSubmit, watch } = form;

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            setDewPoint((value["temperature"] - (100 - value["humidity"]) / 5.0).toFixed(2));
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    function onSubmit(data) {
        const config = {
            start_time: data.start_time,
            stop_time: data.stop_time,
            airflow: data.air_movement === "on" ? -3 : 0,
            airflow_duration: data.air_movement === "on" ? 25 : 0,
            humidity: +data.humidity,
            temperature: +data.temperature,
            voc: convertVoc(data.voc),
            pm25: convertPm(data.pm),
        };
        simulateTelemetry(props.deviceId, data.start_time, data.stop_time, config).then(res => {
            console.log("FINISH telemetry sim!");
        });
    }

    function onHavenScoreSubmit(data) {
        simulateHavenScore(props.deviceId, data.start_time, data.stop_time).then(res => {
            console.log("FINISH haven score!");
        });
    }

    function convertVoc(voc) {
        if (voc === "good") return 100;
        if (voc === "fair") return 400;
        if (voc === "poor") return 900;
    }

    function convertPm(pm) {
        if (pm === "good") return 10;
        if (pm === "fair") return 20;
        if (pm === "poor") return 40;
    }

    return (
        <div>
            <HavenGreenButton label={"Simulate Telemetry"} onClick={onOpen}></HavenGreenButton>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Simulate Telemetry</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormGroup>
                            <DateTimeInput form={form} field="start_time"></DateTimeInput>
                            <DateTimeInput form={form} field="stop_time"></DateTimeInput>
                            <SelectInput form={form} field="air_movement" options={["on", "off"]}></SelectInput>
                            <SelectInput form={form} field="pm" options={["good", "fair", "poor"]}></SelectInput>
                            <SelectInput form={form} field="voc" options={["good", "fair", "poor"]}></SelectInput>
                            <HStack>
                                <NumberInput form={form} field="humidity" min={0} max={100} step={5}></NumberInput>
                                <HumidityThresholdsPopup />
                            </HStack>
                            <NumberInput form={form} field="temperature" min={-40} max={80} step={5}></NumberInput>
                            <HStack>
                                <GenericInput label={"Dew point"}>
                                    <Text>{dewPoint ? dewPoint : "N/A"}</Text>
                                </GenericInput>
                                <DewPointPopup />
                            </HStack>
                        </FormGroup>

                        <Alert status="error" mt={3}>
                            <AlertIcon />
                            <AlertTitle>Existing telemetry will be overwritten!</AlertTitle>
                        </Alert>
                    </ModalBody>

                    <ModalFooter display="flex" flexDirection="column" alignItems="start">
                        <HStack>
                            <HavenGreenButton label="Generate Telemetry" onClick={handleSubmit(onSubmit)}></HavenGreenButton>
                            {telemetryProgress !== null && <div>{Math.round(telemetryProgress * 100)}%</div>}
                            {telemetryProgress !== null && telemetryProgress !== 1 && <Spinner />}
                            {telemetryProgress === 1 && <Text color="#39DD7B">DONE</Text>}
                        </HStack>
                        <HStack mt={2}>
                            <HavenGreenButton label="Calculate Haven Score" onClick={handleSubmit(onHavenScoreSubmit)}></HavenGreenButton>
                            {errorHS !== null && (
                                <Text maxW={"10rem"} color="rgb(249, 108, 108)">
                                    {errorHS}
                                </Text>
                            )}
                            {errorHS === null && scoreProgress !== null && scoreProgress !== 1 && <Spinner />}
                            {errorHS === null && scoreProgress === 1 && <Text color="#39DD7B">DONE</Text>}
                        </HStack>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </div>
    );
}

function HumidityThresholdsPopup() {
    return (
        <InfoPopup>
            <VStack>
                <Text>
                    <chakra.span fontWeight={600}>0-19%</chakra.span> - Poor
                </Text>
                <Text>
                    <chakra.span fontWeight={600}>20-29%</chakra.span> - Fair
                </Text>
                <Text>
                    <chakra.span fontWeight={600}>30-59%</chakra.span> - Good
                </Text>
                <Text>
                    <chakra.span fontWeight={600}>60-69%</chakra.span> - Fair
                </Text>
                <Text>
                    <chakra.span fontWeight={600}>70-100%</chakra.span> - Poor
                </Text>
            </VStack>
        </InfoPopup>
    );
}

function DewPointPopup() {
    return (
        <InfoPopup>
            <VStack>
                <Text>
                    <chakra.span fontWeight={600}>Dew Point Formula:</chakra.span>
                </Text>
                <Text>T - (100 - RH)/5</Text>
            </VStack>
        </InfoPopup>
    );
}
