/**
 * Returns the environment short name based on the current environment.
 */
export function detectEnvironment() {
    const url = window.location.href;
    let env;

    if (url.includes("development.") || url.includes("localhost")) {
        env = "dev";
    } else if (url.includes("staging.")) {
        env = "stage";
    } else if (url.includes("haven-daikin-fleet-spa")) {
        env = "daikin";
    } else {
        env = "prod";
    }

    return env;
}

/**
 * Returns the environment name string used in grafana based on the current environment.
 */
export function getGrafanaEnvironmentName() {
    const url = window.location.href;
    let env;

    if (url.includes("development.") || url.includes("localhost")) {
        env = "Development";
    } else if (url.includes("staging.")) {
        env = "Staging";
    } else if (url.includes("haven-daikin-fleet-spa")) {
        env = "Daikin";
    } else {
        env = "Production";
    }

    return env;
}
