import {
    Select,
    VStack,
    HStack,
    Accordion,
    Text,
    AccordionItem,
    AccordionButton,
    Box,
    AccordionIcon,
    AccordionPanel,
    Checkbox,
} from "@chakra-ui/react";
import { FormInput } from "components/form";
import React, { useState } from "react";

export function DwellingProfiles(props) {
    const { profiles } = props;
    const [selectedProfile, setSelectedProfile] = useState(profiles[0] ?? null);

    const debris = ["supply_duct", "return_duct", "blower", "heat_exchangers", "ac_coils"];
    const outdoorAir = ["hvac_duct", "hrv_erv", "standalone_makeup_air", "combustion_air_intake"];

    function handleChange(e) {
        setSelectedProfile(profiles[e.target.selectedIndex]);
    }

    return (
        <>
            {profiles.length === 0 && <Text>No profiles found</Text>}
            {profiles.length > 0 && (
                <>
                    <FormInput label="Zones">
                        <Select onChange={handleChange}>
                            {profiles.map(profile => (
                                <option key={profile.id} value={profile.zone}>{profile.zone}</option>
                            ))}
                        </Select>
                    </FormInput>
                    {/* <Heading size={"sm"} mt={3} mb={1}>
                        Zone profile
                    </Heading> */}
                    <VStack spacing={1} alignItems="flex-start" mt={5}>
                        <HStack>
                            <Text>Comment: </Text>
                            <Text>"{selectedProfile.comment}"</Text>
                        </HStack>
                        <HStack>
                            <Text>Number of zones</Text>
                            <Select value={selectedProfile.num_zones} size="sm" w="12rem">
                                <option value="one">One</option>
                                <option value="two">Two</option>
                                <option value="three">Three</option>
                                <option value="four">Four</option>
                                <option value="unknown">Unknown</option>
                            </Select>
                        </HStack>
                        <HStack>
                            <Text>ECM or PSC</Text>
                            <Select value={selectedProfile.ecm_or_psc} size="sm" w="12rem">
                                <option value="ecm">Ecm</option>
                                <option value="psc">Psc</option>
                                <option value="unknown">Unknown</option>
                            </Select>
                        </HStack>
                        <HStack>
                            <Text>System</Text>
                            <Select value={selectedProfile.system} size="sm" w="12rem">
                                <option value="communicating">Communicating</option>
                                <option value="24_volts">24 volts</option>
                                <option value="unknown">Unknown</option>
                            </Select>
                        </HStack>
                        <HStack>
                            <Text>Other IAQ devices: </Text>
                            {selectedProfile.other_iaq_devices.length > 0 && (
                                <Text>{JSON.stringify(selectedProfile.other_iaq_devices, null, 2)}</Text>
                            )}
                            {selectedProfile.other_iaq_devices.length === 0 && <Text>None</Text>}
                        </HStack>

                        <Accordion allowToggle w="100%">
                            <AccordionItem>
                                <AccordionButton w="100%">
                                    <Box flex="1" textAlign="left">
                                        <Text>Debris</Text>
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                                <AccordionPanel pb={4}>
                                    <VStack spacing={1} alignItems="flex-start">
                                        {debris.map(d => (
                                            <Checkbox pl={0} isChecked={selectedProfile.debris?.includes(d)}>
                                                {d}
                                            </Checkbox>
                                        ))}
                                    </VStack>
                                </AccordionPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <AccordionButton w="100%">
                                    <Box flex="1" textAlign="left">
                                        <Text>Outdoor air</Text>
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                                <AccordionPanel pb={4}>
                                    <VStack spacing={1} alignItems="flex-start">
                                        {outdoorAir.map(o => (
                                            <Checkbox pl={0} isChecked={selectedProfile.outdoor_air?.includes(o)}>
                                                {o}
                                            </Checkbox>
                                        ))}
                                    </VStack>
                                </AccordionPanel>
                            </AccordionItem>
                        </Accordion>
                    </VStack>
                </>
            )}
        </>
    );
}
