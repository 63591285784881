import { Box, useColorModeValue } from "@chakra-ui/react"
import { memo } from "react"

const blinkingCircleKeyframes = {
    '@keyframes blink': {
        '0%, 100%': {
            opacity: 1,
        },
        '50%': {
            opacity: 0,
        },
    },
}

function Dot({ triggered }) {
    const bg = useColorModeValue('gray.200', 'gray.600')

    const props = {
        ...(triggered ? {
            animation: 'blink 2s infinite',
            css: { blinkingCircleKeyframes },
            bg: 'green.500',
            title: 'On',
        } : {
            bg,
            title: 'Off',
        })
    }


    return (
        <Box
            w='8px'
            h='8px'
            borderRadius='50%'
            {...props}
        />
    )
}

export default memo(Dot)
