import { HStack, Input, Spinner, Tag, TagCloseButton, TagLabel, TagRightIcon, Text, useColorMode } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

export default function Tagging({ id, getEntityTags, removeEntityTag, addEntityTag }) {
    const [tags, setTags] = useState([]);
    const [removingTag, setRemovingTag] = useState(null);
    const [addingTag, setAddingTag] = useState(false);
    const [showWarning, setShowWarning] = useState(false);
    const { colorMode } = useColorMode();

    useEffect(() => {
        getEntityTags(id).then(tags => {
            setTags(tags);
        });
    }, []);

    function onRemove(tag) {
        setRemovingTag(tag);
        removeEntityTag(tag, id).then(() => {
            setTags(tags.filter(t => t !== tag));
            setRemovingTag(null);
        });
    }

    function onKeyDown(e) {
        // remove warning when input changes
        setShowWarning(false);

        // only act when the key pressed is Enter
        if (e.code !== "Enter") {
            return;
        }

        const tag = e.target.value;

        // validate tag format
        if (!new RegExp("^[a-zA-Z0-9-]+$").test(tag)) {
            setShowWarning(true);
            return;
        }

        // avoid tags duplication
        if (!tags.includes(tag)) {
            // add a tag
            setAddingTag(true);
            addEntityTag(tag, id).then(() => {
                setTags([...tags, tag]);
                setAddingTag(false);
                e.target.value = "";
            });
        } else {
            e.target.value = "";
        }
    }

    return (
        <HStack spacing={4} pr={4}>
            <HStack spacing={4}>
                {tags.map(tag => (
                    <Tag key={tag}>
                        <TagLabel>{tag}</TagLabel>
                        {removingTag !== tag && <TagCloseButton onClick={() => onRemove(tag)} />}
                        {removingTag === tag && <TagRightIcon as={Spinner} />}
                    </Tag>
                ))}
            </HStack>
            {addingTag && <Spinner h={4} w={4}></Spinner>}
            <Input
                w="12rem"
                isDisabled={addingTag}
                variant='flushed'
                placeholder="Add tag..."
                onKeyDown={onKeyDown}
            />
            {showWarning && <Text color="#F96C6C">Invalid tag format. Allowed characters: a-z, A-Z, 0-9 and - (hyphen)</Text>}
        </HStack>
    );
}
