import { DateTimeInput, StringInput } from "components/inputs";
import { FormGroup, UpdateAction } from "components/form";
import React, { useState } from "react";

import { useForm } from "react-hook-form";
import { usePost } from "api/api-hooks";

export function DeviceDetails(props) {
    const [details, setDetails] = useState(props.details);
    const { call } = usePost(`device/${details.id}`);

    const form = useForm({
        defaultValues: { ...details, install_timestamp: details.install_timestamp ? new Date(details.install_timestamp) : null },
    });
    const { handleSubmit, reset } = form;

    function handleReset() {
        reset({ ...details });
    }

    function onSubmit(data) {
        delete data.equipment_id;
        delete data.dwelling_id;
        if (data.install_timestamp === null) {
            delete data.install_timestamp;
        }
        data.zone_size = +data.zone_size;
        call(data, () => {
            setDetails(data);
        });
    }

    return (
        <>
            <FormGroup>
                <StringInput form={form} field="id" disabled={true}></StringInput>
                <DateTimeInput form={form} field={"install_timestamp"} required={false}></DateTimeInput>
                <StringInput form={form} field="name"></StringInput>
                <StringInput form={form} field="hub_id" disabled={true}></StringInput>
                <StringInput form={form} field="hub_device_id" disabled={true}></StringInput>
                <StringInput form={form} field="plastic_serial_number" disabled={true}></StringInput>
                <StringInput form={form} field="pcb_serial_number" disabled={true}></StringInput>
                <StringInput form={form} field="zone" disabled={false}></StringInput>
                <StringInput form={form} field="zone_size" disabled={true}></StringInput>
                {/* TODO change to select */}
                <StringInput form={form} field="install_type" disabled={true}></StringInput>
            </FormGroup>

            <UpdateAction onReset={handleReset} onUpdate={handleSubmit(onSubmit)}></UpdateAction>
        </>
    );
}
