import { Container, Grid, HStack, VStack, useMediaQuery } from "@chakra-ui/react";
import { DwellingSearch, EquipmentSearch } from "components/entity-search";
import { getDwelling, getEquipment } from "api/api";

import AssociatedEntity from "components/associated-entity";
import Card from "components/card";
import { DeviceCalibrationReport } from "./device-calibration-report";
import { DeviceDetails } from "./device-details";
import { DeviceEmail } from "./device-email";
import { DeviceTwin } from "./device-twin";
import EntitiesTable from "components/entities-list";
import { GrafanaLinkMonitor } from "components/grafana-links";
import { MonitorOffsets } from "./monitor-offsets";
import { MonitorTelemetry } from "./monitor-telemetry";
import React from "react";

export default function Monitor(props) {
    const [isLargeScreen] = useMediaQuery("(min-width: 1480px)");
    const [isSmallScreen] = useMediaQuery("(max-width: 850px)");

    const { monitor } = props;
    const { details, dwelling, equipment, twin, controllers, hardwareState, currentTelemetry, havenScore, offsets } = monitor;

    if (isSmallScreen) {
        return (
            <Grid templateColumns="repeat(1, 1fr)" gap={4} width="100%">
                <DetailsCard details={details} />
                <EmailDigestCard details={details} />
                <DwellingCard dwelling={dwelling} deviceId={details?.data?.id} />
                <EquipmentCard equipment={equipment} deviceId={details?.data?.id} />
                <OffsetsCard details={details} offsets={offsets}></OffsetsCard>
                <TelemetryCard details={details} currentTelemetry={currentTelemetry} havenScore={havenScore} />
                <CalibrationReportCard details={details} />
                <TechnicalDetailsCard twin={twin} hardwareState={hardwareState} details={details} />
            </Grid>
        );
    } else if (isLargeScreen) {
        return (
            <HStack align="flex-start" spacing={10} w={"100%"}>
                <VStack flex={1} align="stretch" spacing={10}>
                    <DetailsCard details={details} />
                    <EmailDigestCard details={details} />
                </VStack>
                <VStack flex={1} align="stretch" spacing={10}>
                    <Grid width="100%" gap={10}>
                        <DwellingCard dwelling={dwelling} deviceId={details?.data?.id} />
                        <EquipmentCard equipment={equipment} deviceId={details?.data?.id} />
                        <OffsetsCard details={details} offsets={offsets}></OffsetsCard>
                    </Grid>
                </VStack>
                <VStack flex={1} align="stretch">
                    <Grid width="100%" gap={10}>
                        <TelemetryCard details={details} currentTelemetry={currentTelemetry} havenScore={havenScore} />
                        <CalibrationReportCard details={details} />
                        <TechnicalDetailsCard twin={twin} hardwareState={hardwareState} details={details} />
                    </Grid>
                </VStack>
                )
            </HStack>
        );
    } else {
        return (
            <HStack align="flex-start" spacing={10} w={"100%"}>
                <VStack flex={1} align="stretch" spacing={10}>
                    <Grid width="100%" gap={10}>
                        <DetailsCard details={details} />
                        <EmailDigestCard details={details} />
                        <DwellingCard dwelling={dwelling} deviceId={details?.data?.id} />
                        <EquipmentCard equipment={equipment} deviceId={details?.data?.id} />
                        <OffsetsCard details={details} offsets={offsets}></OffsetsCard>
                    </Grid>
                </VStack>
                <VStack flex={1} align="stretch" spacing={10}>
                    <Grid width="100%" gap={10}>
                        <TelemetryCard details={details} currentTelemetry={currentTelemetry} havenScore={havenScore} />
                        <CalibrationReportCard details={details} />
                        <TechnicalDetailsCard twin={twin} hardwareState={hardwareState} details={details} />
                    </Grid>
                </VStack>
            </HStack>
        );
    }
}

function DetailsCard({ details }) {
    return (
        <Card flex={1} header="Details" error={details.error} loading={details.loading}>
            {details.data && <DeviceDetails details={details.data}></DeviceDetails>}
        </Card>
    );
}

function EmailDigestCard({ details }) {
    return (
        <Card flex={1} header="Email Digest" error={details.error} loading={details.loading}>
            {details.data && <DeviceEmail details={details.data}></DeviceEmail>}
        </Card>
    );
}

function DwellingCard({ dwelling, deviceId }) {
    return (
        <Card
            header="Dwelling"
            error={dwelling.error}
            loading={dwelling.loading}
            gotoUrl={`/fleet/dwellings/${dwelling?.data?.id}`}
            iconUrl="/assets/dwelling.png"
        >
            <AssociatedEntity
                entity={dwelling.data}
                updateUrl={`device/${deviceId}`}
                entityName={"Dwelling"}
                getEntityCall={getDwelling}
                fieldName={"dwelling_id"}
                removeEnabled={true}
            >
                <DwellingSearch></DwellingSearch>
            </AssociatedEntity>
        </Card>
    );
}

function EquipmentCard({ equipment, deviceId }) {
    return (
        <Card
            header="Equipment"
            error={equipment.error}
            loading={equipment.loading}
            gotoUrl={`/fleet/equipments/${equipment?.data?.id}`}
            iconUrl="/assets/equipment.png"
        >
            <AssociatedEntity
                entity={equipment.data}
                updateUrl={`device/${deviceId}`}
                entityName={"Equipment"}
                getEntityCall={getEquipment}
                fieldName={"equipment_id"}
                disableChange={true}
            >
                <EquipmentSearch></EquipmentSearch>
            </AssociatedEntity>
        </Card>
    );
}

function ControllersCard({ controllers }) {
    return (
        <Card header="Controllers" error={controllers.error} loading={controllers.loading}>
            <EntitiesTable
                list={controllers.data}
                openUrl="devices"
                columns={["pcb_serial_number", "plastic_serial_number"]}
            ></EntitiesTable>
        </Card>
    );
}

function TelemetryCard({ details, currentTelemetry, havenScore }) {
    return (
        <Card
            flex={1}
            header="Telemetry"
            error={details.error}
            loading={details.loading || currentTelemetry.loading || havenScore.loading}
            customLink={<GrafanaLinkMonitor pcb={details?.data?.pcb_serial_number} />}
            iconUrl="/assets/wifi.png"
        >
            {details.data && currentTelemetry && havenScore && (
                <MonitorTelemetry currentTelemetry={currentTelemetry?.data} havenScore={havenScore?.data}></MonitorTelemetry>
            )}
        </Card>
    );
}

function TechnicalDetailsCard({ twin, hardwareState, details }) {
    return (
        <Card
            flex={1}
            header="Technical details"
            error={twin.error}
            loading={twin.loading || hardwareState.loading}
            iconUrl="/assets/wifi.png"
        >
            {twin.data && <DeviceTwin id={details.data.id} twin={twin.data} hardwareState={hardwareState?.data}></DeviceTwin>}
        </Card>
    );
}

function CalibrationReportCard({ details }) {
    return (
        <Card flex={1} header="Calibration Report" error={details.error} loading={details.loading}>
            {details.data && <DeviceCalibrationReport id={details.data.id}></DeviceCalibrationReport>}
        </Card>
    );
}

function OffsetsCard({ details, offsets }) {
    return (
        <Card flex={1} header="Offsets" error={details.error} loading={offsets.loading}>
            {details.data && <MonitorOffsets id={details.data.id} offsets={offsets}></MonitorOffsets>}
        </Card>
    );
}
